import { Injectable, OnDestroy } from "@angular/core";
import { SwUpdate, VersionReadyEvent } from "@angular/service-worker";
import { Subject } from "rxjs";
import { takeUntil, filter } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class PwaUpdateService implements OnDestroy {

    constructor(private swUpdate: SwUpdate) {
        if ('serviceWorker' in navigator && this.swUpdate.isEnabled) {
            // If Service Worker Update service is in a unrecoverable state
            // Force App reload to always keep updated version
            swUpdate.unrecoverable.pipe(takeUntil(this.$destroy))
                .subscribe(() => {
                    location.reload();
                });
                this.checkForUpdates();
        }
    }

    checkForUpdates(): void {
        this.swUpdate.checkForUpdate();

        // Listen to Service Worker available update status and update SW if necessary
        this.swUpdate.versionUpdates
            .pipe(
                takeUntil(this.$destroy),
                filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')
            )
            .subscribe(() => {
                this.swUpdate.activateUpdate();
            });
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}
