export const CONSTANTS = {
    SIGNUP_MINIMUM_AGE: 15,
    EMAIL_WITH_SPACES_VALIDATION_REGEX: "^\\s*[a-zA-Z0-9._\\-]+@((?!-)[A-Za-z0-9\\-]{0,62}[A-Za-z0-9]\\.)+[A-Za-z]{2,6}\\s*$",
    PASSWORD_VALIDATION_REGEX: "^[\\S].{6,}[\\S]$",
    DEFAULT_THEME: "primary",
    MAX_CONCURRENT_API_CALLS: 1,
    THEMES: ["primary", "red", "pink", "purple", "deep-purple", "indigo", "blue", "light-blue", "cyan", "teal", "green", "light-green",
    "lime", "amber", "orange", "deep-orange", "brown", "grey", "blue-grey", "black"],
    MAX_MEALS: 6,
    MAX_MEALS_OPTIONS: 10,
    MIN_PASSWORD_STRENTGH: 3,
    MIN_PASSWORD_LENGTH: 8,
    ACCOUNT_CLIENT_TAG_KEYS: ["utm_source","utm_medium","utm_campaign","utm_content","utm_term","gclid","apiengagement_id"],
    DAILY_WORK_TIME_DURATION: 10,
    WEEKLY_WORK_TIME_DURATION: 48,
    DAILY_REST_TIME_DURATION: 11,
    WEEKLY_REST_TIME_DURATION: 35,
    EVENT_ARCHIVAL_PROCESS_OFFSET: 30,
    EVENT_ARCHIVAL_NOTIFICATION_OFFSET: 7,
    MAX_FORMS: 10,
    MAX_APPLY_FORM_SECTIONS: 10,
    MAX_NB_TEAMMATE_FIELD_DEFINITIONS_PER_EVENT: 100,
    MAX_IDLE_USER_DURATION: 1800, // in seconds
    MAX_ATTACHMENT_SIZE: 10,
    MAX_MESSAGE_LENGTH: 8192,
    MAX_BULK_UPDATE_SIZE: 1024
};