import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { EventAdminResponseDTO } from '../models/event/responses/EventAdminResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class PreRegistrationAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Pre-registers the current user to the specified event.
     * @param id The event ID.
     */
    public create(id: string): Observable<EventAdminResponseDTO> {
        return this.httpPost<EventAdminResponseDTO>(`/events/${id}/preregistrations/mine`, undefined);
    }

    /**
     * Remove the current user's pre-registration to the specified event.
     * @param id The event ID.
     */
    public delete(id: string): Observable<void> {
        return this.httpDelete(`/events/${id}/preregistrations/mine`);
    }
}
