<div class="flex items-center place-content-center h-full px-6">
        <div class="text-center max-w-prose">
                <p><mat-icon class="block material-symbols-outlined text-qo-light/60 text-[9rem] w-auto h-auto">update</mat-icon></p>
                <h1 class="qo-tiny-h1" i18n>Your subscription has expired@if (subscriptionEndDate) {
<span> on {{subscriptionEndDate|moment:'LL'}}</span>
}</h1>
                <p class="mb-6" i18n>To reactivate your subscription, please contact our sales department: <a class="text-qo-primary underline font-bold whitespace-nowrap" href="mailto:{{salesEmail}}">{{salesEmail}}</a></p>
                <mat-divider class="mb-6"></mat-divider>
                <p><a class="whitespace-normal" [routerLink]="NavigationService.AccountRoutes.Profile()" mat-button i18n>Return to your personal space</a></p>
        </div>
</div>