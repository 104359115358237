import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export enum MessageTypes {
    CONFIRM = "CONFIRM",
    STRONG_CONFIRM = "STRONG_CONFIRM",
    INFO = "INFO"
}

export enum IconTypes {
    SUCCESS,
    WAIT,
    INFO,
    WARNING,
    ERROR,
    CUSTOM
}

export interface RedirectButton {
    url: string;
    text: string;
}

export interface CustomButton {
    title: string;
    type: "FLAT"|"STROKED"|"DEFAULT",
    color?: string;
    onClick: () => void;
}
export interface MessageDialogData {
    body?: string;
    type: MessageTypes;
    icon?: IconTypes;
    icon_url?: string,
    title?: string;
    strongConfirmLabel?: string;
    customButtons?: CustomButton[];
}

@Component({
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent {

    IconTypes = IconTypes;
    MessageTypes = MessageTypes;
    strongConfirm = false;

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MessageDialogData) {
        if(!data) this.data = {
            icon: undefined,
            icon_url: undefined,
            title: undefined,
            body: undefined,
            type: MessageTypes.INFO
        };
    }
}