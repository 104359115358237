import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpContextToken } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { Injectable } from "@angular/core";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
    
    constructor(private authService: AuthService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        let headers = request.headers;
        if (request.context.get(ByPassAuthorizationContextToken) === true) {
            const requestClone = request.clone({ headers });
            return next.handle(requestClone);
        } else {
            return from(this.authService.getAccessToken())
                .pipe(
                    switchMap(token => {
                        headers = request.headers.set('Authorization', `Bearer ${token}`);
                        const requestClone = request.clone({ headers });
                        return next.handle(requestClone);
                    })
                );
        }
    }
}

export const ByPassAuthorizationContextToken = new HttpContextToken<boolean>(() => false);