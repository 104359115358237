import { HttpContext } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ByPassAuthorizationContextToken } from '../auth/bearer.interceptor';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class HealthAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Probes the application to check it is running.
     */
    public healthGet(): Observable<void> {
        return this.httpGet<void>(`/health`, { context: new HttpContext().set(ByPassAuthorizationContextToken, true) });
    }
}
