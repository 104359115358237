import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { OrganizationMembershipResponseDTO } from '../models/organization-membership/responses/OrganizationMembershipResponseDTO';
import { OrganizationMembershipInvitationConfirmCommandDTO } from '../models/organization-membership/commands/OrganizationMembershipInvitationConfirmCommandDTO';
import { OrganizationMembershipInvitationCreateCommandDTO } from '../models/organization-membership/commands/OrganizationMembershipInvitationCreateCommandDTO';
import { OrganizationResponseDTO } from '../models/organization/responses/OrganizationResponseDTO';


@Injectable({
    providedIn: 'root'
})
export class OrganizationMembershipAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    private getByMembershipSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<OrganizationResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<OrganizationResponseDTO>>(`/organizations/by-membership`, { params });
    }

    /**
     * Lists all organizations the current user is a member of.
     * 
     * @param organization_id id of the organization
     */
    public getByMembership(): Observable<OrganizationResponseDTO[]> {
        return this.getAllSegments(this.getByMembershipSegment.bind(this));
    }

    /**
     * List all members of the specified organization.
     * 
     * @param organization_id id of the organization
     */
    public listAllMemberships(organizationId: string): Observable<OrganizationMembershipResponseDTO[]> {
        return this.getAllSegments(this.listAllMembershipsSegment.bind(this), organizationId);
    }

    private listAllMembershipsSegment(segment_index: number | undefined, query_token: string | undefined, organizationId: string,): Observable<ResultSegmentDTO<OrganizationMembershipResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<OrganizationMembershipResponseDTO>>(`/organizations/${organizationId}/memberships`, { params });
    }

    /**
    * Sends an invitation to a user by email allowing him to become a staff member of the organization.
    * @param body The command used to initialize the new event membership invitation.
    */
    public createInvitation(organizationId: string, body: OrganizationMembershipInvitationCreateCommandDTO): Observable<void> {
        return this.httpPost<void>(`/organizations/${organizationId}/memberships/invitation`, body);
    }

    /**
    * Creating or updating the organization membership for the current user, using the parameters contained in the invitation.
    * @param body Parameters used to consume the invitation (one-time use only).
    */
    public confirmInvitation(organizationId: string, body: OrganizationMembershipInvitationConfirmCommandDTO): Observable<void> {
        return this.httpPost<void>(`/organizations/${organizationId}/memberships/invitation/confirmation`, body);
    }

    /**
    * Deletes the specified organization membership.
    * @param organizationId id of the organization
    * @param id id of the membership
    */
    public deleteMembership(organizationId: string, id: string): Observable<void> {
        return this.httpDelete(`/organizations/${organizationId}/memberships/${id}`);
    }
}