import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiClientConfiguration } from '../configuration';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { ItemCommandDTO } from '../models/item/commands/ItemCommandDTO';
import { ItemResponseDTO } from '../models/item/responses/ItemResponseDTO';
import { ItemPlannedDistributionResponseDTO } from '../models/item/responses/ItemPlannedDistributionResponseDTO';
import { ItemPlannedDistributionsTeammateAggregateResponseDTO } from '../models/item/responses/ItemPlannedDistributionsTeammateAggregateResponseDTO';
import { AuthService } from '../auth/auth.service';
import { ItemEffectiveDistributionResponseDTO } from '../models/item/responses/ItemEffectiveDistributionResponseDTO';
import { UpdateItemEffectiveDistrubutionCommandDTO } from '../models/item/commands/UpdateItemEffectiveDistrubutionCommandDTO';
import { CreateItemEffectiveDistrubutionCommandDTO } from '../models/item/commands/CreateItemEffectiveDistrubutionCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class ItemAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the specified item.
     * 
     * @param id id of the item
     * @param event_id id of the event
     */
    public get(id: string, event_id: string): Observable<ItemResponseDTO> {
        return this.httpGet<ItemResponseDTO>(`/events/${event_id}/items/${id}`);
    }

    /**
     * Creates a new material item.
     * @param event_id id of the event
     * @param body The command used to initialize the new material item.
     */
    public create(event_id: string, body: ItemCommandDTO): Observable<ItemResponseDTO> {
        return this.httpPost<ItemResponseDTO>(`/events/${event_id}/items`, body);
    }

    /**
     * Lists planned item distributions in the specified event, aggregating teammate infos.
     * 
     * @param event_id id of the event
     */
    public listPlannedItemDistributionsAggregatedByTeammate(event_id: string, item_id?: string): Observable<ItemPlannedDistributionsTeammateAggregateResponseDTO[]> {
        return this.getAllSegments(this.listPlannedItemDistributionsAggregatedByTeammateSegment.bind(this), event_id, item_id);
    }

    private listPlannedItemDistributionsAggregatedByTeammateSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string, item_id?: string): Observable<ResultSegmentDTO<ItemPlannedDistributionsTeammateAggregateResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        if(item_id) params = params.append('item_id', item_id);
        return this.httpGet<ResultSegmentDTO<ItemPlannedDistributionsTeammateAggregateResponseDTO>>(`/events/${event_id}/items/distributions/planned/teammate-aggregates`, { params });
    }
    
    /**
     * Lists planned item distributions in the specified event.
     * 
     * @param event_id id of the event
     */
      public listPlannedItemDistributions(event_id: string, item_id?: string, teammate_id?: string): Observable<ItemPlannedDistributionResponseDTO[]> {
        return this.getAllSegments(this.listPlannedItemDistributionsSegment.bind(this), event_id, item_id, teammate_id);
    }

    private listPlannedItemDistributionsSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string, item_id?: string, teammate_id?: string): Observable<ResultSegmentDTO<ItemPlannedDistributionResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        if(item_id) params = params.append('item_id', item_id);      
        if(teammate_id) params = params.append('teammate_id', teammate_id);
        return this.httpGet<ResultSegmentDTO<ItemPlannedDistributionResponseDTO>>(`/events/${event_id}/items/distributions/planned`, { params });
    }
    
    /**
     * Lists all material items in the specified event.
     * 
     * @param event_id id of the event
     */
    public listItem(event_id: string): Observable<ItemResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), event_id);
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<ItemResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<ItemResponseDTO>>(`/events/${event_id}/items`, { params });
    }
    
    /**
     * Updates an existing material item.
     * @param id id of the material item
     * @param event_id id of the event
     * @param body The command used to initialize the new item.
     */
    public update(id: string, event_id: string, body: ItemCommandDTO): Observable<ItemResponseDTO> {
        return this.httpPut<ItemResponseDTO>(`/events/${event_id}/items/${id}`, body);
    }

    /**
     * Deletes the specified material item.
     * @param id id of the material iteam
     * @param event_id id of the event
     */
    public delete(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/items/${id}`);
    }

    /**
     * Lists effective item distributions in the specified event.
     * 
     */
    public listEffectiveItemDistribution(event_id: string, item_id?: string, teammate_id?: string): Observable<ItemEffectiveDistributionResponseDTO[]> {
        return this.getAllSegments(this.listEffectiveItemDistributionSegment.bind(this), event_id, item_id, teammate_id);
    }

    private listEffectiveItemDistributionSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string, item_id?:string, teammate_id?: string): Observable<ResultSegmentDTO<ItemEffectiveDistributionResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        if(item_id) params = params.append('item_id', item_id);      
        if(teammate_id) params = params.append('teammate_id', teammate_id);
        return this.httpGet<ResultSegmentDTO<ItemEffectiveDistributionResponseDTO>>(`/events/${event_id}/items/distributions/effective`, { params });
    }

    /**
     * Creates a new item effective distribution.
     */
    public createEffectiveItemDistribution(event_id: string, body: CreateItemEffectiveDistrubutionCommandDTO): Observable<ItemEffectiveDistributionResponseDTO> {
        return this.httpPost<ItemEffectiveDistributionResponseDTO>(`/events/${event_id}/items/distributions/effective`, body);
    }

    /**
     * Updates an existing item effective distribution.
     */
     public updateEffectiveItemDistribution(id: string, event_id: string, body: UpdateItemEffectiveDistrubutionCommandDTO): Observable<ItemEffectiveDistributionResponseDTO> {
        return this.httpPut<ItemEffectiveDistributionResponseDTO>(`/events/${event_id}/items/distributions/effective/${id}`, body);
    }

    /**
     * Deletes an existing item effective distribution.
     */
    public deleteEffectiveItemDistribution(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/items/distributions/effective/${id}`);
    }
}
