import { HttpBackend, HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { Network } from '@ngx-pwa/offline';
import { Observable } from 'rxjs';

export const API_CLIENT_HTTP_INTERCEPTORS = new InjectionToken<HttpInterceptor[]>('API_CLIENT_HTTP_INTERCEPTORS');

@Injectable()
export class ApiClientHttpClient extends HttpClient {
    constructor(backend: HttpBackend, injector: Injector, network: Network) {
        super(new CustomHandlerService(backend, injector, API_CLIENT_HTTP_INTERCEPTORS, network));
    }
}

export class CustomHttpInterceptorHandler implements HttpHandler {
    constructor(private next: HttpHandler, private interceptor: HttpInterceptor) { }

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        return this.interceptor.intercept(req, this.next);
    }
}

export class CustomHandlerService implements HttpHandler {

    private chain: HttpHandler | null = null;

    constructor(private backend: HttpBackend, private injector: Injector, private interceptors: InjectionToken<HttpInterceptor[]>, readonly network: Network) { }

    handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
        if (this.chain === null) {
            const interceptors = this.injector.get(this.interceptors, []);
            this.chain = interceptors.reduceRight(
                (next, interceptor) => new CustomHttpInterceptorHandler(next, interceptor), this.backend);
        }
        return this.chain.handle(req);
    }
}