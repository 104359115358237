import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, Subject, Subscription, timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CONSTANTS } from 'src/environments/constants';

@Injectable({
    providedIn: 'root'
})
export class IdleMonitoringService {
    protected idleStatusBS = new BehaviorSubject<boolean>(false);
    public idleStatusChanged = this.idleStatusBS.asObservable();

    timeOutMilliSeconds: number = 0;
    $idle!: Observable<Event>;
    $timer!: Subscription;
    $idleSubscription!: Subscription;
    $expired: Subject<boolean> = new Subject<boolean>();

    constructor() { }

    init() {
        this.startWatching(CONSTANTS.MAX_IDLE_USER_DURATION).pipe(takeUntil(this.$destroy))
            .subscribe((isTimedOut: boolean) => {
                if (isTimedOut) {
                    this.idleStatusBS.next(true);
                }
            });
    };

    startWatching(timeOutSeconds: number) {
        this.$idle = merge(fromEvent(document, 'mousemove'),
            fromEvent(document, 'click'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'keypress'),
            fromEvent(document, 'DOMMouseScroll'),
            fromEvent(document, 'mousewheel'),
            fromEvent(document, 'touchmove'),
            fromEvent(document, 'MSPointerMove'),
            fromEvent(window, 'mousemove'),
            fromEvent(window, 'resize'));


        this.timeOutMilliSeconds = timeOutSeconds * 1000;
        this.$idleSubscription = this.$idle
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => {
                this.idleStatusBS.next(false);
                this.resetTimer();
            });
        this.startTimer();
        return this.$expired;
    }
    
    startTimer() {
        this.$timer = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds)
            .pipe(takeUntil(this.$destroy))
            .subscribe(() => {
                this.$expired.next(true);
            });
    }
    
    resetTimer() {
        this.$timer.unsubscribe();
        this.startTimer();
    }
    
    stopTimer() {
        this.$timer.unsubscribe();
        this.$idleSubscription.unsubscribe();
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}


