import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivityCommandDTO } from '../models/activity/commands/ActivityCommandDTO';
import { ActivityResponseDTO } from '../models/activity/responses/ActivityResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { StringDTO } from '../models/common/StringDTO';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ActivityAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Creates a new activity.
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public create(event_id: string, body: ActivityCommandDTO): Observable<ActivityResponseDTO> {
        return this.httpPost<ActivityResponseDTO>(`/events/${event_id}/activities`, body);
    }

    private listSegment(segment_index: number | undefined, query_token: string | undefined, event_id: string): Observable<ResultSegmentDTO<ActivityResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);

        return this.httpGet<ResultSegmentDTO<ActivityResponseDTO>>(`/events/${event_id}/activities`,
            {
                params: params
            }
        )
    }

    /**
     * Lists all activities in the specified event.
     * 
     * @param event_id id of the event
     */
    public list(event_id: string): Observable<ActivityResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), event_id);
    }

    /**
     * Gets the specified activity.
     * 
     * @param id id of the activity
     * @param event_id id of the event     
     */
    public get(id: string, event_id: string): Observable<ActivityResponseDTO> {
        return this.httpGet<ActivityResponseDTO>(`/events/${event_id}/activities/${id}`);
    }

    /**
     * Updates an existing activity.
     * @param id id of the activity
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public update(id: string, event_id: string, body: ActivityCommandDTO): Observable<ActivityResponseDTO> {
        return this.httpPut<ActivityResponseDTO>(`/events/${event_id}/activities/${id}`, body);
    }

    /**
     * Deletes the specified activity.
     * @param id id of the teammate
     * @param event_id id of the event
     */
    public delete(id: string, event_id: string): Observable<any> {
        return this.httpDelete(`/events/${event_id}/activities/${id}`);
    }

    private listTagsSegment(segment_index: number | undefined, query_token: string | undefined, event_id: string): Observable<ResultSegmentDTO<StringDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<StringDTO>>(`/events/${event_id}/activities/tags`, { params });
    }

    /**
     * Lists all activity tags.
     * @param event_id id of the event
     */
    public listTags(event_id: string): Observable<string[]> {
        return this.getAllSegments(this.listTagsSegment.bind(this), event_id);
    }

    private listAccessGroupsSegment(segment_index: number | undefined, query_token: string | undefined, event_id: string): Observable<ResultSegmentDTO<StringDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<StringDTO>>(`/events/${event_id}/activities/access-groups`, { params });
    }

    /**
     * Lists all activity access groups.
     * @param event_id id of the event
     */
    public listAccessGroups(event_id: string): Observable<string[]> {
        return this.getAllSegments(this.listAccessGroupsSegment.bind(this), event_id);
    }
}
