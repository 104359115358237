import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UiCustomizationService {

    private static STORAGE_ID = "UICUSTOMIZATION";

    constructor() { }

    get<T>(key: string, context?: UiCustomizationContext): T | undefined {
        const rawSettings = localStorage.getItem(UiCustomizationService.STORAGE_ID);
        const settings: UiCustomizationEntry<any>[] = rawSettings ? JSON.parse(rawSettings) : [];
        const entry = settings.find(x => x.key === key && x.context?.type === context?.type && x.context?.key === context?.key) as UiCustomizationEntry<T> | undefined;
        if (entry != undefined) return entry.value;
        else return undefined;
    }

    set<T>(key: string, value: T, context?: UiCustomizationContext) {
        const rawSettings = localStorage.getItem(UiCustomizationService.STORAGE_ID);
        let settings: UiCustomizationEntry<any>[] = rawSettings ? JSON.parse(rawSettings) : [];
        settings = settings.filter(x => !(x.key === key && x.context?.type === context?.type && x.context?.key === context?.key));
        if (value !== undefined && value !== null) settings.push({ key, context, value });
        localStorage.setItem(UiCustomizationService.STORAGE_ID, JSON.stringify(settings));
    }

    remove(key: string, context?: UiCustomizationContext) {
        this.set(key, undefined, context);
    }

    clear() {
        localStorage.removeItem(UiCustomizationService.STORAGE_ID);
    }
}

export interface UiCustomizationContext {
    type: "EVENT" | "ORGANIZATION";
    key: string;
}

interface UiCustomizationEntry<T> {
    key: string;
    context?: UiCustomizationContext;
    value: T;
}