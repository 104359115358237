import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { MealCommandDTO } from '../models/meal/commands/MealCommandDTO';
import { MealPlannedDistributionResponseDTO } from '../models/meal/responses/MealPlannedDistributionResponseDTO';
import { MealPlannedDistributionsTeammateAggregateResponseDTO } from '../models/meal/responses/MealPlannedDistributionsTeammateAggregateResponseDTO';
import { MealResponseDTO } from '../models/meal/responses/MealResponseDTO';
import { TeammateMealDistributionResponseDTO } from '../models/meal/responses/TeammateMealDistributionResponseDTO';
import { MealEffectiveDistributionResponseDTO } from '../models/meal/responses/MealEffectiveDistributionResponseDTO';
import { CreateMealEffectiveDistributionCommandDTO } from '../models/meal/commands/CreateMealEffectiveDistributionCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class MealAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the specified meal.
     * 
     * @param id id of the meal 
     * @param event_id id of the event
     */
    public get(id: string, event_id: string): Observable<MealResponseDTO> {
        return this.httpGet<MealResponseDTO>(`/events/${event_id}/meals/${id}`);
    }

    /**
     * Creates a new meal.
     * @param event_id id of the event
     * @param body The command used to initialize the new meal.
     */
    public createMeal(event_id: string, body: MealCommandDTO): Observable<MealResponseDTO> {
        return this.httpPost<MealResponseDTO>(`/events/${event_id}/meals`, body);
    }

    /**
     * Lists all meals in the specified event.
     * 
     * @param event_id id of the event
     */
    public listMeals(event_id: string): Observable<MealResponseDTO[]> {
        return this.getAllSegments(this.listMealSegment.bind(this), event_id);
    }

    private listMealSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<MealResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<MealResponseDTO>>(`/events/${event_id}/meals`, { params });
    }

    /**
     * Updates an existing meal.
     * @param id id of the meal
     * @param event_id id of the event
     * @param body The command used to initialize the new meal.
     */
    public updateMeal(id: string, event_id: string, body: MealCommandDTO): Observable<MealResponseDTO> {
        return this.httpPut<MealResponseDTO>(`/events/${event_id}/meals/${id}`, body);
    }

    /**
     * Deletes the specified meal .
     * @param id id of the meal
     * @param event_id id of the event
     */
    public deleteMeal(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/meals/${id}`);
    }

    /**
     * Lists planned meal distributions in the specified event, aggregating teammate infos.
     * 
     * @param event_id id of the event
     */
    public listPlannedMealDistributionsAggregatedByTeammate(event_id: string, meal_id?: string, date?: Date): Observable<MealPlannedDistributionsTeammateAggregateResponseDTO[]> {
        return this.getAllSegments(this.listPlannedMealDistributionsAggregatedByTeammateSegment.bind(this), event_id, meal_id, date);
    }

    private listPlannedMealDistributionsAggregatedByTeammateSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string, meal_id?: string, date?: Date): Observable<ResultSegmentDTO<MealPlannedDistributionsTeammateAggregateResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        if (meal_id) params = params.append('meal_id', meal_id);
        if (date) params = params.append('date', date.toJSON().substring(0, 10));
        return this.httpGet<ResultSegmentDTO<MealPlannedDistributionsTeammateAggregateResponseDTO>>(`/events/${event_id}/meals/distributions/planned/teammate-aggregates`, { params });
    }

    /**
     * Lists planned distributions in the specified event.
     * 
     * @param event_id id of the event
     */
    public listPlannedMealDistributions(event_id: string, teammate_id?: string, date?: Date, meal_id?: string): Observable<MealPlannedDistributionResponseDTO[]> {
        return this.getAllSegments(this.listPlannedMealDistributionsSegment.bind(this), event_id, teammate_id, date, meal_id);
    }

    private listPlannedMealDistributionsSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string, teammate_id?: string, date?: Date, meal_id?: string): Observable<ResultSegmentDTO<MealPlannedDistributionResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        if (teammate_id) params = params.append('teammate_id', teammate_id);
        if (meal_id) params = params.append('meal_id', meal_id);
        if (date) params = params.append('date', date.toJSON().substring(0, 10));
        return this.httpGet<ResultSegmentDTO<MealPlannedDistributionResponseDTO>>(`/events/${event_id}/meals/distributions/planned`, { params });
    }

    /**
    * Lists planned meal distributions for the current user.
    * 
    * @param event_id id of the event
    */
    public listPlannedMealDistributionsForCurrentUser(event_id: string): Observable<TeammateMealDistributionResponseDTO[]> {
        return this.getAllSegments(this.listPlannedMealDistributionsForCurrentUserSegment.bind(this), event_id);
    }

    private listPlannedMealDistributionsForCurrentUserSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<TeammateMealDistributionResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<TeammateMealDistributionResponseDTO>>(`/events/${event_id}/meals/distributions/planned/mine`);
    }

    /**
     * Lists effective meal distributions in the specified event.
     * 
     */
    public listEffectiveMealDistribution(event_id: string, teammate_id?: string, date?: Date, meal_id?: string): Observable<MealEffectiveDistributionResponseDTO[]> {
        return this.getAllSegments(this.listEffectiveMealDistributionSegment.bind(this), event_id, teammate_id, date, meal_id);
    }

    private listEffectiveMealDistributionSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string, teammate_id?: string, date?: Date, meal_id?: string): Observable<ResultSegmentDTO<MealEffectiveDistributionResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        if (teammate_id) params = params.append('teammate_id', teammate_id);
        if (meal_id) params = params.append('meal_id', meal_id);
        if (date) params = params.append('date', date.toJSON().substring(0, 10));
        return this.httpGet<ResultSegmentDTO<MealEffectiveDistributionResponseDTO>>(`/events/${event_id}/meals/distributions/effective`, { params });
    }

    /**
     * Creates a new meal effective distribution.
     */
    public createEffectiveMealDistribution(event_id: string, body: CreateMealEffectiveDistributionCommandDTO): Observable<MealEffectiveDistributionResponseDTO> {
        return this.httpPost<MealEffectiveDistributionResponseDTO>(`/events/${event_id}/meals/distributions/effective`, body);
    }

    /**
     * Deletes an existing meal effective distribution.
     */
    public deleteEffectiveMealDistribution(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/meals/distributions/effective/${id}`);
    }
}
