import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventCategoryResponseDTO } from '../models/event-category/responses/EventCategoryResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';

@Injectable({
    providedIn: 'root'
})
export class EventCategoryAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<EventCategoryResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventCategoryResponseDTO>>(`/event-categories`, { params });
    }

    /**
     * List all event categories.
     */
    public list(): Observable<EventCategoryResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this));
    }
}
