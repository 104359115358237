enum PeriodStringBrand { };
export type PeriodString = string & PeriodStringBrand;

export class Period {

    private static PERIOD_REGEX = /^P(?<years>-?[0-9]+(?:[,\.][0-9]+)?Y)?(?<months>-?[0-9]+(?:[,\.][0-9]+)?M)?(?<days>-?[0-9]+(?:[,\.][0-9]+)?D)?(?:T(?<hours>-?[0-9]+(?:[,\.][0-9]+)?H)?(?<minutes>-?[0-9]+(?:[,\.][0-9]+)?M)?(?<seconds>-?[0-9]+(?:[,\.][0-9]+)?S)?)?$/i;
    
    private _years: number;
    public get years(): number { return this._years; }
    
    private _months: number;
    public get months(): number { return this._months; }
    
    private _days: number;
    public get days(): number { return this._days; }
    
    private _hours: number;
    public get hours(): number { return this._hours; }

    private _minutes: number;
    public get minutes(): number { return this._minutes; }

    private _seconds: number;
    public get seconds(): number { return this._seconds; }

    private _isValid: boolean;
    public get isValid(): boolean { return this._isValid; }

    public set(years: number = 0, months: number = 0, days: number = 0, hours: number = 0, minutes: number = 0, seconds: number = 0) {    
        this._years = years;
        this._months = months;
        this._days = days;
        this._hours = hours;
        this._minutes = minutes;
        this._seconds = seconds;
        this._isValid = true;
    }

    constructor(period?: string|PeriodString, normalize: boolean = false) {
        if (period) {
            this.parse(period)
        } else  {
            this.set(0, 0, 0, 0, 0, 0);
        }
        if(normalize) {
            this.normalize();
        }
    }

    static from(years: number = 0, months: number = 0, days: number = 0, hours: number = 0, minutes: number = 0, seconds: number = 0, normalize: boolean = false): Period {
        let result = new Period();
        result.set(years, months, days, hours, minutes, seconds);
        if(normalize) {
            result.normalize();
        }        
        return result;
    }

    toJSON(): string {
        if (this._isValid) {
            return `P${this._years.toString().padStart(1, "0")}Y${this._months.toString().padStart(1, "0")}M${this._days.toString().padStart(1, "0")}DT${this._hours.toString().padStart(1, "0")}H${this._minutes.toString().padStart(1, "0")}M${this._seconds.toString().padStart(1, "0")}S`;
        } else {
            return "Invalid period";
        }
    }

    private setInvalid() {
        this._years = 0;
        this._months = 0;
        this._days = 0;
        this._hours = 0;
        this._minutes = 0;
        this._seconds = 0;
        this._isValid = false;
    }

    private parse(period: string) {

        const matches = period.match(Period.PERIOD_REGEX);

        if (matches?.groups) {
            const years = matches.groups.years ? Number.parseInt(matches.groups.years) : 0;
            const months = matches.groups.months ? Number.parseInt(matches.groups.months) : 0;
            const days = matches.groups.days ? Number.parseInt(matches.groups.days) : 0;
            const hours = matches.groups.hours ? Number.parseInt(matches.groups.hours) : 0;
            const minutes = matches.groups.minutes ? Number.parseInt(matches.groups.minutes) : 0;
            const seconds = matches.groups.seconds ? Number.parseInt(matches.groups.seconds) : 0;
            this.set(years, months, days, hours, minutes, seconds);
        } else {
            this.setInvalid();
        }
    }

    toHours() {
        return Math.round((
            this._days * 24 + 
            this._hours + 
            this._minutes / 60 + 
            this._seconds / 3600) 
            * 100) / 100;
    }

    normalize() {
        // Normalize
        this._hours += (this._days % 1) * 24;
        this._days = Math.floor(this._days);
        this._minutes += (this._hours % 1) * 60;
        this._hours = Math.floor(this._hours);
        this._seconds += (this._minutes % 1) * 60;
        this._minutes = Math.floor(this._minutes);
        this._seconds = Math.floor(this._seconds);

        this._minutes += Math.floor(this._seconds / 60);
        this._seconds = this._seconds % 60;
        this._hours += Math.floor(this._minutes / 60);
        this._minutes = this._minutes % 60;
        this._days += Math.floor(this._hours / 24);
        this._hours = this._hours % 24;
    }
}