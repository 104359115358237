import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminEventCommandDTO } from '../models/admin-event/commands/AdminEventCommandDTO';
import { AdminEventResponseDTO } from '../models/admin-event/responses/AdminEventResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';

@Injectable({
    providedIn: 'root'
})
export class AdminEventAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }
    
    private listEventsSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<AdminEventResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AdminEventResponseDTO>>(`/admin/events`, { params });
    }

    /**
     * Lists all events on the platform.
     */
    public listEvents(): Observable<AdminEventResponseDTO[]> {
        return this.getAllSegments(this.listEventsSegment.bind(this));
    }

    /**
     * Updates the specified event.
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public updateEvent(event_id: string, body: AdminEventCommandDTO): Observable<AdminEventResponseDTO> {
        return this.httpPut<AdminEventResponseDTO>(`/admin/events/${event_id}`, body);
    }

    /**
     * Deletes the specified event.
     * @param event_id id of the event
     */
    public deleteEvent(event_id: string): Observable<void> {
        return this.httpDelete(`/admin/events/${event_id}`);
    }
}
