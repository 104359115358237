<button mat-icon-button matDialogClose i18n-matTooltip matTooltip="Close" class="float-right shrink-0 mt-2 mr-2 z-10" aria-label="Close" i18n-aria-label>
        <mat-icon>close</mat-icon>
</button>
<div mat-dialog-title>
        <ng-container i18n>Add SMS</ng-container>
</div>

<div mat-dialog-content>
        <p class="mb-4" i18n>Do you need more SMS? No worries! Contact our sales department: <a class="text-qo-primary underline font-bold whitespace-nowrap" href="mailto:{{salesEmail}}">{{salesEmail}}</a></p>
</div>

