export enum ApplicationPermission {
    ADMIN_ACCESS = "ADMIN_ACCESS",
    EVENT_MANAGE = "EVENT_MANAGE",
    LOCALIZATION_MANAGE = "LOCALIZATION_MANAGE",
    ORGANIZATION_MANAGE = "ORGANIZATION_MANAGE",
    PAYMENT_MANAGE = "PAYMENT_MANAGE",
    PUBLISHER_MANAGE = "PUBLISHER_MANAGE",
    SERVICE_MANAGE = "SERVICE_MANAGE",
    SETTINGS_MANAGE = "SETTINGS_MANAGE",
    USER_MANAGE = "USER_MANAGE",
    ORGANIZATION_REGISTER = "ORGANIZATION_REGISTER"
};