import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { OrganizationTeammateFieldDefinitionCreateCommandDTO } from '../models/organization-teammate-field-definition/commands/OrganizationTeammateFieldDefinitionCreateCommandDTO';
import { OrganizationTeammateFieldDefinitionUpdateCommandDTO } from '../models/organization-teammate-field-definition/commands/OrganizationTeammateFieldDefinitionUpdateCommandDTO';
import { OrganizationTeammateFieldDefinitionResponseDTO } from '../models/organization-teammate-field-definition/responses/OrganizationTeammateFieldDefinitionResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class OrganizationTeammateFieldDefinitionAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }
    
    /**
     * Gets the specified organization teammate field definition.
     * 
     * @param organizatinId The ID of the organization associated with the organization teammate field definition.
     * @param id The organization teammate field definition ID.
     */
     public get(organizationId: string, id: string): Observable<OrganizationTeammateFieldDefinitionResponseDTO> {
        return this.httpGet<OrganizationTeammateFieldDefinitionResponseDTO>(`/organizations/${organizationId}/teammate-field-definitions/${id}`);
    }

    /**
     * List all users having at least one teammate teammate in an event of the organization.
     * 
     * @param organization_id id of the organization
     */
     public list(organizationId: string): Observable<OrganizationTeammateFieldDefinitionResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), organizationId);
    }
    
    private listSegment(segment_index: number|undefined, query_token: string|undefined, organizationId: string,): Observable<ResultSegmentDTO<OrganizationTeammateFieldDefinitionResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<OrganizationTeammateFieldDefinitionResponseDTO>>(`/organizations/${organizationId}/teammate-field-definitions`, { params });
    }

    /**
     * Creates a new organization teammate field definition.
     * @param body The command used to initialize the new organization teammate field definition.
     */
     public create(organizationId: string, body: OrganizationTeammateFieldDefinitionCreateCommandDTO): Observable<OrganizationTeammateFieldDefinitionResponseDTO> {
        return this.httpPost<OrganizationTeammateFieldDefinitionResponseDTO>(`/organizations/${organizationId}/teammate-field-definitions`, body);
    }

    /**
     * Deletes the specified organization teammate field definition.
     * @param organizatinId The ID of the organization associated with the organization teammate field definition.
     * @param id The organization teammate field definition ID.
     */
         public delete(organizationId: string, id: string): Observable<void> {
            return this.httpDelete(`/organizations/${organizationId}/teammate-field-definitions/${id}`);
        }
        
    /**
     * Updates the specified organization teammate field definition.
     * @param organizatinId The ID of the organization associated with the organization teammate field definition.
     * @param id The organization teammate ID.
     * @param body Informations needed to update the organization teammate field definition.
     */
        public update(organizationId: string, id: string, body: OrganizationTeammateFieldDefinitionUpdateCommandDTO): Observable<OrganizationTeammateFieldDefinitionResponseDTO> {
        return this.httpPut<OrganizationTeammateFieldDefinitionResponseDTO>(`/organizations/${organizationId}/teammate-field-definitions/${id}`, body);
    }
}