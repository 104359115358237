import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { EventMapSettingsCommandDTO } from '../models/event-settings-map/commands/EventMapSettingsCommandDTO';
import { EventMapSettingsResponseDTO } from '../models/event-settings-map/responses/EventMapSettingsResponseDTO';
import { EventTeammateMapSettingsResponseDTO } from '../models/event-settings-map/responses/EventTeammateMapSettingsResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventSettingsMapAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the event's map settings.
     * 
     * @param event_id The event ID.
     */
    public get(event_id: string): Observable<EventMapSettingsResponseDTO> {
        return this.httpGet<EventMapSettingsResponseDTO>(`/events/${event_id}/settings/map`);
    }

    /**
     * Update the event's map-specific settings.
     * 
     * @param event_id The event ID.
     * @param body Command used to update the settings.
     */
    public update(event_id: string, body: EventMapSettingsCommandDTO): Observable<EventMapSettingsResponseDTO> {
        return this.httpPost<EventMapSettingsResponseDTO>(`/events/${event_id}/settings/map`, body);
    }

    /**
     * Gets the event's teammate map settings.
     * 
     * @param event_id The event ID.
     */
    public getTeammateSettings(event_id: string): Observable<EventTeammateMapSettingsResponseDTO> {
        return this.httpGet<EventTeammateMapSettingsResponseDTO>(`/events/${event_id}/settings/map/teammate`);
    }
}
