<ng-template>
        <div class="mde-popover-panel" role="dialog" [class.mde-popover-overlap]="overlapTrigger"
                [ngClass]="_classList" [ngStyle]="popoverPanelStyles" (keydown)="_handleKeydown($event)"
                (click)="onClick()" (mouseover)="onMouseOver()" (mouseleave)="onMouseLeave()" [@.disabled]="disableAnimation"
                [@transformPopover]="'enter'">
                @if (!overlapTrigger) {
                        <div class="mde-popover-direction-arrow" [ngStyle]="popoverArrowStyles"></div>
                }
                <div class="mde-popover-content" [ngStyle]="popoverContentStyles" [cdkTrapFocus]="focusTrapEnabled" [cdkTrapFocusAutoCapture]="focusTrapAutoCaptureEnabled">
                        <ng-content></ng-content>
                </div>
        </div>
</ng-template>
