<div class="relative h-screen max-h-screen overflow-hidden" [ngClass]="{
                'grid-rows-[40px_1fr]': trialService.displayTrialBanner,
                'grid-rows-[1fr]': !trialService.displayTrialBanner
}" responsive #responsive="responsive">

        @if (trialService.displayTrialBanner) {
                <div class="w-full bg-blue-600 text-white text-center py-2">
                        @if (trialService.getTrialTimeLeft() === 'today') {
                                <p class="mb-0" i18n>Your trial period will end today.</p>
                        }
                        @if (trialService.getTrialTimeLeft() === 'tomorrow') {
                                <p class="mb-0" i18n>Your trial period will end tomorrow.</p>
                        }
                        @if (trialService.getTrialTimeLeft() === 'later') {
                                <p class="mb-0" i18n>Your trial period will end in {{ trialService.getTrialTimeLeftInDays() }} days.</p>
                        }
                </div>
        }

                <div class="relative" [ngClass]="{
                                'h-[calc(100vh-40px)]': trialService.displayTrialBanner,
                                'h-[calc(100vh)]': !trialService.displayTrialBanner
                }">
                @if ([MenuMode.ADMIN, MenuMode.EVENT, MenuMode.ORGANIZATION].includes(currentMenuMode)) {
                        <!-- Desktop nav -->
                        @if (!responsive.XSmall && !responsive.Small) {
                                <div [hidden]="!showLayout"
                                        class="qo-NavArea absolute inset-0 left-0 max-h-full group transition-all ease-in-out"
                                        [ngClass]="(navigationOpened ? 'qo-open w-72 pr-4 z-2' : 'w-14 hover:w-72 hover:pr-4 z-[102]')">
                                        <button matRipple class="flex absolute right-0 top-10 w-8 h-8 rounded-full border border-black/10 bg-white dark:bg-qo-dark-600 hover:bg-gray-100 text-black dark:text-white shadow-md transition-all opacity-0 group-hover:opacity-100 z-20"
                                                (click)="toggleNavigationOpened()" [title]="getNavTooltip()">
                                                @if (navigationOpened) {
                                                        <mat-icon class="material-icon-outlined m-auto scale-90">chevron_left</mat-icon>
                                                }
                                                @if (!navigationOpened) {
                                                        <mat-icon class="material-icon-outlined m-auto scale-90">chevron_right</mat-icon>
                                                }
                                        </button>
                                        <div class="qo-MainNav qo-scrollbar qo-light h-full bg-gradient-to-b from-[#292929] to-[#0D0D0D] text-white max-h-screen shrink-0 overflow-y-auto">
                                                <div class="group/org">
                                                        <a matRipple [routerLink]="NavigationService.AccountRoutes.Profile()"
                                                                class="bg-black rounded-br-2xl flex items-center gap-2 p-2 group-hover/org:bg-white/10 no-underline transition-colors">
                                                                <div class="rounded rounded-br-lg w-10 h-10 shrink-0 overflow-hidden flex bg-qo-neutral/20">
                                                                        <span class="material-symbols-outlined qo-wght200 m-auto" aria-hidden="true" role="img">location_away</span>
                                                                </div>
                                                                <span class="text-sm shrink-0 line-clamp-2 w-48" i18n>My personal space</span>
                                                        </a>
                                                        <svg class="fill-black group-hover/org:fill-white/10 transition-colors" xmlns="http://www.w3.org/2000/svg" width="15.996" height="15.745" viewBox="0 0 15.996 15.745"><path d="M0,15.745H0V0H16A16.068,16.068,0,0,0,0,15.745Z"/></svg>
                                                </div>
                                                @if ([MenuMode.EVENT, MenuMode.ORGANIZATION].includes(currentMenuMode)) {
                                                        <user-space-selector class="flex items-center gap-2 p-2 -mb-1 -mt-4"></user-space-selector>
                                                }
                                                <!-- EVENT MENU -->
                                                @if (currentMenuMode === MenuMode.EVENT) {
                                                        <nav>
                                                                <!-- EVENT ADMIN -->
                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_ACCESS)) {
                                                                        <ul class="flex flex-col mt-2">
                                                                                <li class="px-2 pb-1">
                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.Dashboard(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">dashboard</span>
                                                                                                <span class="qo-NavButton-text" i18n>Dashboard</span>
                                                                                        </a>
                                                                                </li>
                                                                                <li class="px-2 pb-1">
                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.Teammates(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">group</span>
                                                                                                <span class="qo-NavButton-text" i18n>Contacts</span>
                                                                                        </a>
                                                                                </li>
                                                                                <li class="px-2 pb-1">
                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.Activities(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">date_range</span>
                                                                                                <span class="qo-NavButton-text" i18n>Activities</span>
                                                                                        </a>
                                                                                </li>
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MESSAGES_READ)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.TeammateMessaging(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">forum</span>
                                                                                                        <span class="qo-NavButton-text" i18n>Messaging</span>
                                                                                                </a>
                                                                                        </li>
                                                                                }
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MATERIAL_READ)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.MaterialItems(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">shopping_bag</span>
                                                                                                        <span class="qo-NavButton-text" i18n>Material</span>
                                                                                                </a>
                                                                                        </li>
                                                                                }
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MEALS_READ)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.Meals(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">restaurant</span>
                                                                                                        <span class="qo-NavButton-text" i18n>Catering</span>
                                                                                                </a>
                                                                                        </li>
                                                                                }
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_MAP_READ)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.MapEditor(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">pin_drop</span>
                                                                                                        <span class="qo-NavButton-text" i18n>Information map</span>
                                                                                                </a>
                                                                                        </li>
                                                                                }
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_READ)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.ApplicationDetails(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">list_alt</span>
                                                                                                        <span class="qo-NavButton-text" i18n>Registration form</span>
                                                                                                </a>
                                                                                        </li>
                                                                                }
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_READ)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.Forms(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">quiz</span>
                                                                                                        <span class="qo-NavButton-text" i18n>Forms</span>
                                                                                                </a>
                                                                                        </li>
                                                                                }
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_READ) || currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_LOCALIZATION_WRITE)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_READ)) {
                                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.Settings(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">settings</span>
                                                                                                                <span class="qo-NavButton-text" i18n>Settings</span>
                                                                                                        </a>
                                                                                                }
                                                                                                @if (!currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_EVENT_READ)) {
                                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Admin.Localization(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">settings</span>
                                                                                                                <span class="qo-NavButton-text" i18n>Settings</span>
                                                                                                        </a>
                                                                                                }
                                                                                        </li>
                                                                                }
                                                                        </ul>
                                                                }
                                                                <!-- EVENT TEAMMATE -->
                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.TEAMMATE_ACCESS)) {
                                                                        @if (currentEvent!.user_event_permissions.includes(EventPermission.ADMIN_ACCESS)) {
                                                                                <mat-divider class="mt-1 mx-2 border-white/30 dark:border-white/20"></mat-divider>
                                                                                                                                <p class="text-sm flex items-end mb-0 ml-4 w-52 transition-all overflow-hidden" [ngClass]="{
                                                                                                                                                'opacity-0 h-0 group-hover:opacity-60 group-hover:h-6': !navigationOpened,
                                                                                                                                                'opacity-60 h-6': navigationOpened
                                                                                                                                }" i18n>Teammate space</p>
                                                                        }
                                                                        <ul class="flex flex-col mt-2">
                                                                                <li class="px-2 pb-1">
                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Teammate.Home(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">home</span>
                                                                                                <span class="qo-NavButton-text" i18n>Home</span>
                                                                                        </a>
                                                                                </li>
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.TEAMMATE_PLANNING_READ)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Teammate.Activities(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">calendar_month</span>
                                                                                                        <span class="qo-NavButton-text" i18n>My schedule</span>
                                                                                                </a>
                                                                                        </li>
                                                                                }
                                                                                @if (currentEvent!.user_event_permissions.includes(EventPermission.TEAMMATE_MAP_READ)) {
                                                                                        <li class="px-2 pb-1">
                                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Teammate.Map(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">map</span>
                                                                                                        <span class="qo-NavButton-text" i18n>Map</span>
                                                                                                </a>
                                                                                        </li>
                                                                                }
                                                                                <li class="px-2 pb-1">
                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Teammate.Attachments(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">file_present</span>
                                                                                                <span class="qo-NavButton-text" i18n>Documents</span>
                                                                                        </a>
                                                                                </li>
                                                                                <li class="px-2 pb-1">
                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.EventRoutes.Teammate.Profile(currentEvent!.id)" routerLinkActive="qo-current">
                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">account_circle</span>
                                                                                                <span class="qo-NavButton-text" i18n>My profile</span>
                                                                                        </a>
                                                                                </li>
                                                                        </ul>
                                                                }
                                                        </nav>
                                                }
                                                <!-- ORGANIZATION MENU -->
                                                @if (currentMenuMode === MenuMode.ORGANIZATION) {
                                                        <nav>
                                                                <ul class="flex flex-col mt-2">
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.OrganizationRoutes.Home(currentOrganization!.id)" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">dashboard</span>
                                                                                        <span class="qo-NavButton-text" i18n>Dashboard</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.OrganizationRoutes.Events(currentOrganization!.id)" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">event_available</span>
                                                                                        <span class="qo-NavButton-text" i18n>Events</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.OrganizationRoutes.OrganizationTeammates(currentOrganization!.id)" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">group</span>
                                                                                        <span class="qo-NavButton-text" i18n>Community</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.OrganizationRoutes.TeammateMessaging(currentOrganization!.id)" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">forum</span>
                                                                                        <span class="qo-NavButton-text" i18n>Messaging</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.OrganizationRoutes.Stats(currentOrganization!.id)" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">bar_chart</span>
                                                                                        <span class="qo-NavButton-text" i18n>Stats</span>
                                                                                </a>
                                                                        </li>
                                                                        @if (currentOrganization!.user_organization_permissions.includes(OrganizationPermission.ADMIN_ORGANIZATION_MANAGE)) {
                                                                                <li class="px-2 pb-1">
                                                                                        <a class="qo-NavButton" matRipple [routerLink]="NavigationService.OrganizationRoutes.Settings(currentOrganization!.id)" routerLinkActive="qo-current">
                                                                                                <span class="material-symbols-outlined qo-wght200 shrink-0">settings</span>
                                                                                                <span class="qo-NavButton-text" i18n>Settings</span>
                                                                                        </a>
                                                                                </li>
                                                                        }
                                                                </ul>
                                                        </nav>
                                                }
                                                <!-- ADMIN AREA -->
                                                @if (currentMenuMode === MenuMode.ADMIN) {
                                                        <nav>
                                                                <ul class="flex flex-col mt-2">
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.AdminRoutes.Dashboard()" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">dashboard</span>
                                                                                        <span class="qo-NavButton-text" i18n>Dashboard</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.AdminRoutes.EventList()" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">event_note</span>
                                                                                        <span class="qo-NavButton-text" i18n>Events</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.AdminRoutes.OrganizationList()" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">business</span>
                                                                                        <span class="qo-NavButton-text" i18n>Organizations</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.AdminRoutes.UserList()" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">group</span>
                                                                                        <span class="qo-NavButton-text" i18n>Users</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.AdminRoutes.Localizations()" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">g_translate</span>
                                                                                        <span class="qo-NavButton-text" i18n>Localization</span>
                                                                                </a>
                                                                        </li>
                                                                        <li class="px-2 pb-1">
                                                                                <a class="qo-NavButton" matRipple [routerLink]="NavigationService.AdminRoutes.Settings()" routerLinkActive="qo-current">
                                                                                        <span class="material-symbols-outlined qo-wght200 shrink-0">settings</span>
                                                                                        <span class="qo-NavButton-text" i18n>Settings</span>
                                                                                </a>
                                                                        </li>
                                                                </ul>
                                                        </nav>
                                                }
                                        </div>
                                </div>
                        }
                        <!-- Mobile nav -->
                        @if (responsive.XSmall || responsive.Small) {
                                @if (mobileLinks.groups.length > 0 && showLayout) {
                                        <nav [hidden]="!showMobileMenu" class="qo-MainMobileNav bg-[#0D0D0D] text-white fixed left-0 bottom-0 w-full z-990 h-14 md:hidden ">
                                                <ul class="list-none flex items-center h-full m-0 p-0" >
                                                        @for (link of mobileLinks.groups[0].links; track link) {
                                                                <li class="h-full flex-1 overflow-hidden">
                                                                        @if (!link.isExternalUrl) {
                                                                                <a class="flex flex-col text-center justify-center border-none relative outline-none w-full h-full text-xs font-normal"
                                                                                        matRipple [routerLink]="link.url" routerLinkActive #rla="routerLinkActive" [ngClass]="{'opacity-80': !rla.isActive || mobileMoreMenuDialogRef}">
                                                                                        <span class="mx-auto rounded-full flex w-12 mb-0.5 py-0.5" [ngClass]="{'bg-qo-primary/60': rla.isActive && !mobileMoreMenuDialogRef}"><mat-icon aria-hidden="true" role="img" class="m-auto scale-95 origin-center" [ngClass]="{'material-icons-outlined': !rla.isActive || mobileMoreMenuDialogRef}">{{link.icon}}</mat-icon></span>
                                                                                        <span class="line-clamp-1">{{link.title}}</span>
                                                                                </a>
                                                                        }
                                                                        @if (link.isExternalUrl) {
                                                                                <a class="flex flex-col text-center justify-center border-none relative outline-none w-full h-full text-xs font-normal"
                                                                                        matRipple [href]="link.url" target="_blank" routerLinkActive #rla="routerLinkActive" [ngClass]="{'opacity-80': !rla.isActive || mobileMoreMenuDialogRef}">
                                                                                        <span class="mx-auto rounded-full flex w-12 mb-0.5 py-0.55" [ngClass]="{'bg-qo-primary/60': rla.isActive && !mobileMoreMenuDialogRef }"><mat-icon aria-hidden="true" role="img" class="m-auto scale-95 origin-center" [ngClass]="{'material-icons-outlined': !rla.isActive || mobileMoreMenuDialogRef}">{{link.icon}}</mat-icon></span>
                                                                                        <span class="line-clamp-1">{{link.title}}</span>
                                                                                </a>
                                                                        }
                                                                </li>
                                                        }
                                                        @if (mobileLinks.groups.length > 1) {
                                                                <li class="h-full flex-1 overflow-hidden">
                                                                        <button matRipple class="flex flex-col text-center justify-center border-none relative outline-none w-full h-full text-xs font-normal qo-more" (click)="toggleMobileMoreMenu()" [ngClass]="{'opacity-80': !mobileMoreMenuDialogRef}">
                                                                                <span class="mx-auto rounded-full flex w-12 mb-0.5 py-0.5" [ngClass]="{ 'bg-qo-primary/60': mobileMoreMenuDialogRef }" ><mat-icon aria-hidden="true" role="img" class="m-auto scale-95 origin-center" [ngClass]="{'material-icons-outlined': !mobileMoreMenuDialogRef}">more_horiz</mat-icon></span>
                                                                                <span class="line-clamp-1 mx-auto" i18n>More</span>
                                                                        </button>
                                                                </li>
                                                        }
                                                </ul>
                                        </nav>
                                }
                        }
                }
                                <div class="flex flex-col h-full gap-y-6 overflow-y-scroll" [ngClass]="{
                                                'ml-68 w-[calc(100%-17rem)]' : !responsive.XSmall && !responsive.Small && navigationOpened && currentMenuMode != MenuMode.EMPTY,
                                                'ml-14 w-[calc(100%-3.5rem)]' : !responsive.XSmall && !responsive.Small && !navigationOpened && currentMenuMode != MenuMode.EMPTY,
                                                'qo-has-main-nav': (responsive.XSmall || responsive.Small) && currentMenuMode != MenuMode.EMPTY && showLayout
                                }">
                                                <div class="qo-Content relative flex flex-col flex-1 px-4 md:px-6 transition-all ease-in-out print:ml-0 print:w-full" [ngClass]="{
                                                                'qo-has-main-nav': (responsive.XSmall || responsive.Small) && currentMenuMode != MenuMode.EMPTY && showLayout
                                                }">
                                @if (showLayout) {
<div class="flex items-center justify-between p-2" [ngClass]="{
                                                                                'absolute right-0 top-0 z-10':!responsive.XSmall && !responsive.Small,
                                                                                'w-screen gap-1 -mx-4 self-end':responsive.XSmall || responsive.Small
                                                                                }">
                                                @if ([MenuMode.EVENT, MenuMode.ORGANIZATION].includes(currentMenuMode) && (responsive.XSmall || responsive.Small)) {
                                                        <div class="flex items-center gap-1">
                                                                <a matRipple [routerLink]="NavigationService.AccountRoutes.Profile()"
                                                                        class="flex items-center p-1 no-underline shrink-0 bg-qo-neutral/30 dark:bg-qo-dark-800 rounded-lg w-12 h-12">
                                                                        <span class="material-symbols-outlined qo-wght200 m-auto" aria-hidden="true" role="img">location_away</span>
                                                                </a>
                                                                <user-space-selector class="flex items-center gap-2 p-1 pr-3 bg-qo-neutral/30 dark:bg-qo-dark-800 rounded-lg overflow-hidden"></user-space-selector>
                                                        </div>
                                                }
                                                @if (VM) {
                                                        <div class="flex ml-auto" (mouseover)="desktopMoreMenuVisibility = DesktopMoreMenuVisibility.CLOSED">
                                                                <messaging-panel class="flex items-center"></messaging-panel>
                                                                <notification-panel class="flex items-center"></notification-panel>
                                                                <my-account-menu class="inline-flex ml-1"></my-account-menu>
                                                        </div>
                                                }
                                        </div>
                                }
                                <main class="flex-1 relative" role="main">
                                        <router-outlet></router-outlet>
                                </main>
                        </div>
                        @if (!hideFooter && showLayout) {
                                <app-footer></app-footer>
                        }
                </div>
        </div>
</div>