import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { EventLicenseSettingsCommandDTO } from '../models/event-settings-license/commands/EventLicenseSettingsCommandDTO';
import { EventLicenseSettingsResponseDTO } from '../models/event-settings-license/responses/EventLicenseSettingsResponseDTO';
import { EventLicenseSettingsUpdatedResponseDTO } from '../models/event-settings-license/responses/EventLicenseSettingsUpdatedResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventSettingsLicenseAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the event's license settings.
     * 
     * @param event_id The event ID.
     */
    public get(event_id: string): Observable<EventLicenseSettingsResponseDTO> {
        return this.httpGet<EventLicenseSettingsResponseDTO>(`/events/${event_id}/settings/license`);
    }

    /**
     * Update the event's license (code, quota...).
     * 
     * @param event_id The event ID.
     * @param body Command used to update the license.
     */
    public update(event_id: string, body: EventLicenseSettingsCommandDTO): Observable<EventLicenseSettingsUpdatedResponseDTO> {
        return this.httpPost<EventLicenseSettingsUpdatedResponseDTO>(`/events/${event_id}/settings/license`, body);
    }
}
