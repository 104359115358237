import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { HttpParams } from '@angular/common/http';
import { EventBadgeSettingsCommandDTO } from '../models/event-badge-settings/commands/EventBadgeSettingsCommandDTO';
import { EventBadgeSettingsResponseDTO } from '../models/event-badge-settings/responses/EventBadgeSettingsResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class EventBadgeSettingsAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
    * Updates current event badge settings.
    * 
    * @param event_id id of the event
    * @param body The command used to update settings.
    */
    public update(event_id: string, body: EventBadgeSettingsCommandDTO): Observable<EventBadgeSettingsResponseDTO> {
        return this.httpPost<EventBadgeSettingsResponseDTO>(`/events/${event_id}/settings/badge`, body);
    }
    
     /**
     * Gets the event's badge settings.
     * 
     * @param event_id The event ID.
     */
     public get(event_id: string): Observable<EventBadgeSettingsResponseDTO> {
        return this.httpGet<EventBadgeSettingsResponseDTO>(`/events/${event_id}/settings/badge`);
    }
}
