import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation-service/navigation.service';
import { ColorScheme, ColorSchemeService } from 'src/app/shared/services/color-scheme/color-scheme.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    templateUrl: './profile-deleted.component.html',
    styleUrls: ['./profile-deleted.component.scss']
})
export class ProfileDeletedComponent implements OnInit {
    
    NavigationService = NavigationService;
    ColorScheme = ColorScheme;
    currentColorScheme!: ColorScheme;
    
    constructor(colorSchemeService: ColorSchemeService) {
        colorSchemeService.currentColorSchemeChanged.pipe(takeUntil(this.$destroy))
            .subscribe(colorScheme => {
                this.currentColorScheme = colorScheme;
            });
    }

    ngOnInit(): void {
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}
