import { AddressDTO } from 'projects/api-client/src/models/common/AddressDTO';
import { Country } from '../../services/vocabulary-service/vocabulary.service';

export class Address {
    line1?: string;
    line2?: string;
    postal_code?: string;
    city?: string;
    state?: string;
    country?: string;
    latitude?: number;
    longitude?: number;

    constructor(addressDTO?: AddressDTO) {
        if (addressDTO) {
            this.line1 = addressDTO.line1;
            this.line2 = addressDTO.line2;
            this.postal_code = addressDTO.postal_code;
            this.city = addressDTO.city;
            this.state = addressDTO.state;
            this.country = addressDTO.country;
            this.latitude = addressDTO.latitude;
            this.longitude = addressDTO.longitude;
        }
    }

    static getAddressAsString(address: Address, countries: Country[], format: "short" | "cityAndPostalCode" | "medium" | "full" = "short"): string {
        let segments: (string|undefined)[] = [];
        switch (format) {
            case "short":
                segments = [address.city];
                break;
            case "medium":
                segments = [address.city,
                    address.country && countries ? countries.find(country => country.code === address.country)?.title : undefined];
                break;
            case "cityAndPostalCode":
                segments = [address.city, address.postal_code];
                break;
            case "full":
                segments = [address.line1, address.line2, 
                    `${address.postal_code != undefined ? address.postal_code :""} ${address.city ?? ""}`, address.state, 
                    address.country && countries ? countries.find(country => country.code === address.country)?.title : undefined]
                break;
        }
        return segments.filter(x => x && x.trim().length > 0).join(", ");        
    }
}
