import { Address } from '../common/address';
import { OrganizationRole } from 'projects/api-client/src/models/common/OrganizationRole';
import { OrganizationPermission } from 'projects/api-client/src/models/common/OrganizationPermission';
import { OrganizationCommandDTO } from 'projects/api-client/src/models/organization/commands/OrganizationCommandDTO';
import { OrganizationAdminResponseDTO } from 'projects/api-client/src/models/organization/responses/OrganizationAdminResponseDTO';
import { TeammateFieldDefinitionTemplate } from '../organization-teammate-field-definition-template/teammate-field-definition-template';
import { OrganizationTeammateFieldDefinition } from '../organization-teammate-field-definition/OrganizationProfileFieldDefinition';
import { SubscriptionType } from 'projects/api-client/src/models/common/SubscriptionType';

export class OrganizationAdmin {
    id!: string;
    name!: string;
    logo_asset_key?: string;
    summary?: string;
    description?: string;
    address?: Address;
    contact_phone!: string;
    subscription_type!: SubscriptionType;
    trial_end_date?: Date;
    subscription_start_date?: Date;
    subscription_end_date?: Date;
    subscription_commitment_end_date?: Date;
    subscription_events_per_year?: number;
    subscription_teammates_per_year?: number;
    licenses_quota!: number;
    sms_quota!: number;
    website_url?: string;
    facebook_page_url?: string;
    linkedin_page_url?: string;
    instagram_page_url?: string;

    user_organization_role!: OrganizationRole;
    user_organization_permissions: OrganizationPermission[] = [];
    require_2fa_for_members!: boolean;

    events_count!: number;
    community_members_count!: number;
    unread_messages_count!: number;
    
    profile_field_definitions: OrganizationTeammateFieldDefinition[] = [];
    teammate_field_definition_templates: TeammateFieldDefinitionTemplate[] = [];

    created_at!: Date;
    updated_at!: Date;

    affiliation_code?: string;
    
    static from(dto: OrganizationAdminResponseDTO) {
        const organization = new OrganizationAdmin();
        organization.id = dto.id;
        organization.name = dto.name;
        organization.logo_asset_key = dto.logo_asset_key;
        organization.summary = dto.summary;
        organization.description = dto.description;
        organization.address = dto.address ? new Address(dto.address) : undefined;
        organization.contact_phone = dto.contact_phone;
        organization.subscription_type = dto.subscription_type;
        organization.trial_end_date = dto.trial_end_date;
        organization.subscription_start_date = dto.subscription_start_date;
        organization.subscription_end_date = dto.subscription_end_date;
        organization.subscription_commitment_end_date = dto.subscription_commitment_end_date;
        organization.subscription_events_per_year = dto.subscription_events_per_year;
        organization.subscription_teammates_per_year = dto.subscription_teammates_per_year;
        organization.licenses_quota = dto.licenses_quota;
        organization.sms_quota = dto.sms_quota;
        organization.website_url = dto.website_url;
        organization.facebook_page_url = dto.facebook_page_url;
        organization.linkedin_page_url = dto.linkedin_page_url;
        organization.instagram_page_url = dto.instagram_page_url;
        organization.user_organization_role = dto.user_organization_role;
        organization.user_organization_permissions = dto.user_organization_permissions;
        organization.require_2fa_for_members = dto.require_2fa_for_members;
        organization.events_count = dto.events_count;
        organization.community_members_count = dto.community_members_count;
        organization.unread_messages_count = dto.unread_messages_count;
        organization.profile_field_definitions = dto.teammate_field_definitions.map(x => OrganizationTeammateFieldDefinition.from(x));
        organization.teammate_field_definition_templates = dto.teammate_field_definition_templates.map(x => TeammateFieldDefinitionTemplate.from(x));
        organization.created_at = new Date(dto.created_at);
        organization.updated_at = new Date(dto.updated_at);
        organization.affiliation_code = dto.affiliation_code;

        return organization;
    }

    asOrganizationCommand(): OrganizationCommandDTO {
        return {
            name: this.name,
            contact_phone: this.contact_phone,
            address: this.address,
            description: this.description,
            logo_asset_key: this.logo_asset_key,
            summary: this.summary,
            website_url: this.website_url
        }
    }
}