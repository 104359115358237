import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { EventSettingsMaterialCommandDTO } from '../models/event-settings-material/commands/EventSettingsMaterialCommandDTO';
import { EventSettingsMaterialResponseDTO } from '../models/event-settings-material/responses/EventSettingsMaterialResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventSettingsMaterialAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the event's material-specific settings.
     * 
     * @param event_id The event ID.
     */
    public get(event_id: string): Observable<EventSettingsMaterialResponseDTO> {
        return this.httpGet<EventSettingsMaterialResponseDTO>(`/events/${event_id}/settings/material`);
    }

    /**
     * Update the event's material-specific settings.
     * 
     * @param event_id The event ID.
     * @param body Command used to update the settings.
     */
    public update(event_id: string, body: EventSettingsMaterialCommandDTO): Observable<EventSettingsMaterialResponseDTO> {
        return this.httpPost<EventSettingsMaterialResponseDTO>(`/events/${event_id}/settings/material`, body);
    }
}
