import { Pipe, PipeTransform } from "@angular/core";
import { LocalizedKeyString } from 'projects/api-client/src/models/common/LocalizedKeyString';

@Pipe({ name: 'localize' })
export class LocalizePipe implements PipeTransform {
    constructor() { }
    
    transform(value?:LocalizedKeyString, use: "source"|"target" = "target") : string {
        return (use === "source" ? value?.key : value?.text) || "";
    }
}