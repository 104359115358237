import * as moment from 'moment';
import { Period } from 'projects/api-client/src/types/Period';
import { SortService } from '../sort-service/sort.service';
import { LocalTime } from 'projects/api-client/src/types/LocalTime';

export class DateService {

    constructor() { }

    public static toUtcDate(date: Date): Date | null {
        return date ? moment(date).utc(true).toDate() : null;
    }

    public static timeframesIntersectsDates(t1: { start: Date, end: Date }, t2: { start: Date, end: Date }) {
        if (t1 === null || t2 === null) throw "timeframes cannot be null";
        return t1.start < t2.end && t1.end > t2.start;
    }

    public static timeframesIntersectsMoment(t1: { start: moment.Moment, end: moment.Moment }, t2: { start: moment.Moment, end: moment.Moment }, includeExtremities = false) {
        if (t1 === null || t2 === null) throw "timeframes cannot be null";
        if (includeExtremities) return t1.start.isSameOrBefore(t2.end) && t1.end.isSameOrAfter(t2.start);
        else return t1.start.isBefore(t2.end) && t1.end.isAfter(t2.start);
    }

    public static getDatePeriodAsString(start: moment.Moment, end: moment.Moment, displayTime = false): string {
        const sameDay = start.isSame(end, "day");
        const sameMonth = start.isSame(end, "month");

        if (sameDay) {
            return displayTime
                ? $localize`${start.format("ddd ll")} from ${start.format("LT")} to ${end.format("LT")}`
                : start.format("ddd ll");
        } else if (sameMonth) {
            return displayTime ?
            $localize`From ${start.format("D MMMM YYYY LT")} to ${end.format("D MMMM YYYY LT")}` :
            $localize`From ${start.format("D")} to ${end.format("D MMMM YYYY")}`;
        } else {
            return displayTime
                ? $localize`From ${start.format("lll")} to ${end.format("lll")}`
                : $localize`From ${start.format("ll")} to ${end.format("ll")}`;
        }
    }

    public static getDateFromPeriod(date: moment.Moment, period: Period): moment.Moment {
        return date
            .add(period.years, "years")
            .add(period.months, "months")
            .add(period.days, "days")
            .add(period.hours, "hours")
            .add(period.minutes, "minutes")
            .add(period.seconds, "seconds");
    }

    public static localTimeToOffset(localTime: LocalTime, baseDate = moment('2020-01-01', 'YYYY-MM-DD')): Period {
        const baseDateAtLocalTime = baseDate.clone().set({
            hour: localTime.hour,
            minute: localTime.minute,
            second: localTime.second
        });
        const diff = moment.duration(baseDateAtLocalTime.diff(baseDate));
        return Period.from(0, 0, 0, diff.hours(), diff.minutes(), diff.seconds());
    }

    public static offsetToLocalTime(offset: Period, baseDate = moment('2020-01-01', 'YYYY-MM-DD')): LocalTime {
        const dummyDate = this.getDateFromPeriod(baseDate, offset);
        return LocalTime.from(dummyDate.hour(), dummyDate.minutes(), dummyDate.seconds());
    }

    public static timeRangesUnion(timeRanges: { start: Date; end: Date; }[]): { start: Date; end: Date; }[] {
        if (timeRanges.length <= 1) return timeRanges;
        else {
            return timeRanges
                .slice()
                .sort(SortService.getDateComparator("start"))
                .map(x => {
                    return {
                        start: moment(x.start),
                        end: moment(x.end)
                    }
                })
                .reduce((result, timeRange) => {
                    const intersectingRanges = result.filter((x, index) => DateService.timeframesIntersectsMoment(timeRange, x, true));
                    if (intersectingRanges.length === 0) {
                        result.push(timeRange);
                        return result;
                    } else {
                        let minStart = timeRange.start;
                        let maxEnd = timeRange.end;
                        for (let range of intersectingRanges) {
                            if (minStart.isAfter(range.start)) minStart = range.start.clone();
                            if (maxEnd.isBefore(range.end)) maxEnd = range.end.clone();
                        }
                        const mergedResult = result.filter(x => !DateService.timeframesIntersectsMoment(timeRange, x, true));
                        mergedResult.push({ start: minStart, end: maxEnd });
                        return mergedResult;
                    }
                }, [] as { start: moment.Moment; end: moment.Moment; }[])
                .map(x => ({ start: x.start.toDate(), end: x.end.toDate() }))
        }
    }

    public static substractTimeRange(t1: { start: Date; end: Date; }, t2: { start: Date; end: Date; }): { start: Date; end: Date; }[] {
        if (DateService.timeframesIntersectsDates(t1, t2)) {
            let result: { start: Date; end: Date; }[] = [];
            if (t1.start < t2.start) result.push({ start: t1.start, end: t2.start });
            if (t1.end > t2.end) result.push({ start: t2.end, end: t1.end });
            return result;
        } else {
            return [t1];
        }
    }
}
