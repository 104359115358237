import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { PublicApplicationSettingsResponseDTO } from 'projects/api-client/src/models/application/responses/PublicApplicationSettingsResponseDTO';
import { ApplicationSettingsAPIService } from 'projects/api-client/src/public-api';

@Injectable({ providedIn: 'root' })
export class ApplicationSettingsService {

    private _settings!: PublicApplicationSettingsResponseDTO;
    public get settings(): Readonly<PublicApplicationSettingsResponseDTO> { return this._settings; }

    constructor(
        private applicationSettingsAPIService: ApplicationSettingsAPIService,
        @Inject(DOCUMENT) private document: Document
    ) { }


    public injectThemeColors() {
        const materialColors = [
            "PRIMARY_50", "PRIMARY_50_CONTRAST", "PRIMARY_200", "PRIMARY_200_CONTRAST", "PRIMARY_300", "PRIMARY_300_CONTRAST", "PRIMARY_500", "PRIMARY_500_CONTRAST", "PRIMARY_900", "PRIMARY_900_CONTRAST",
            "ACCENT_50", "ACCENT_50_CONTRAST", "ACCENT_200", "ACCENT_200_CONTRAST", "ACCENT_300", "ACCENT_300_CONTRAST", "ACCENT_500", "ACCENT_500_CONTRAST", "ACCENT_900", "ACCENT_900_CONTRAST"
        ]; // Color keys to inject in material version too

        for (let color of this.settings.application_identity.theme_colors) {
            const rgb = this.hexToRgb(color.value);
            if (rgb) {
                this.document.body.style.setProperty(`--${color.key}`, `${rgb.r} ${rgb.g} ${rgb.b}`);
                if (materialColors.includes(color.key)) {
                    this.document.body.style.setProperty(`--MAT_${color.key}`, `#${color.value}`);
                }
            }
        }
    }

    private hexToRgb(hex: string) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    }

    public async refresh() {
        this._settings = await this.applicationSettingsAPIService.getPublicSettings().toPromise();
    }
}