import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { OrganizationTeammateFieldDefinitionTemplateResponseDTO } from '../models/organization-teammate-field-definition-template/responses/OrganizationTeammateFieldDefinitionTemplateResponseDTO';
import { OrganizationTeammateFieldDefinitionTemplateUpdateCommandDTO } from '../models/organization-teammate-field-definition-template/commands/OrganizationTeammateFieldDefinitionTemplateUpdateCommandDTO';
import { OrganizationTeammateFieldDefinitionTemplateCreateCommandDTO } from '../models/organization-teammate-field-definition-template/commands/OrganizationTeammateFieldDefinitionTemplateCreateCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class OrganizationTeammateFieldDefinitionTemplateAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined, organization_id: string): Observable<ResultSegmentDTO<OrganizationTeammateFieldDefinitionTemplateResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<OrganizationTeammateFieldDefinitionTemplateResponseDTO>>(`/organizations/${organization_id}/teammate-field-definition-templates`, { params });
    }

    /**
     * Lists all teammate field templates in the specified event.
     */
    public list(organization_id: string): Observable<OrganizationTeammateFieldDefinitionTemplateResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), organization_id);
    }       
    
    /**
     * Gets the event teammate field template's details
     * 
     * @param organization_id id of the organization
     * @param id id of the teammate field template definition
     */
    public get(organization_id: string, id: string): Observable<OrganizationTeammateFieldDefinitionTemplateResponseDTO> {
        return this.httpGet<OrganizationTeammateFieldDefinitionTemplateResponseDTO>(`/organizations/${organization_id}/teammate-field-definition-templates/${id}`);
    }

    /**
     * Update the event's teammate field template definition.
     * @param organization_id id of the organization
     * @param id id of the teammate field template definition
     * @param body The command used to update the teammate field template definition.
     */
    public update(organization_id: string, id: string, body: OrganizationTeammateFieldDefinitionTemplateUpdateCommandDTO): Observable<OrganizationTeammateFieldDefinitionTemplateResponseDTO> {
        return this.httpPut<OrganizationTeammateFieldDefinitionTemplateResponseDTO>(`/organizations/${organization_id}/teammate-field-definition-templates/${id}`, body);
    }

    /**
     * Creates a new teammate field template definition.
     */
    public create(organization_id: string, body: OrganizationTeammateFieldDefinitionTemplateCreateCommandDTO): Observable<OrganizationTeammateFieldDefinitionTemplateResponseDTO> {
        return this.httpPost<OrganizationTeammateFieldDefinitionTemplateResponseDTO>(`/organizations/${organization_id}/teammate-field-definition-templates`, body);
    }

    /**
     * Deletes the specified event teammate field template definition.
     * @param organization_id id of the organization
     * @param id id of the teammate field template definition
     */
    public delete(organization_id: string, id: string): Observable<void> {
        return this.httpDelete(`/organizations/${organization_id}/teammate-field-definition-templates/${id}`);
    }

}