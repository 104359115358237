import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { EventPublisherResponseDTO } from '../models/event-publisher/responses/EventPublisherResponseDTO';
import { EventPublisherUpdateCommandDTO } from '../models/event-publisher/commands/EventPublisherUpdateCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class EventPublisherAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }
    
    public list(event_id: string): Observable<EventPublisherResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), event_id);
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<EventPublisherResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventPublisherResponseDTO>>(`/events/${event_id}/publishers/available`, { params });
    }
    
    public update(event_id: string, publisher_id: string, body: EventPublisherUpdateCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/publishers/${publisher_id}`, body);
    }
}
