import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { EventSettingsTeammateProfileCommandDTO } from '../models/event-settings-teammate-profile/commands/EventSettingsTeammateProfileCommandDTO';
import { EventSettingsTeammateProfileResponseDTO } from '../models/event-settings-teammate-profile/responses/EventSettingsTeammateProfileResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventSettingsTeammateProfileAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the event's teammate profile settings.
     * 
     * @param event_id The event ID.
     */
    public get(event_id: string): Observable<EventSettingsTeammateProfileResponseDTO> {
        return this.httpGet<EventSettingsTeammateProfileResponseDTO>(`/events/${event_id}/settings/teammate-profile`);
    }

    /**
     * Update the event's teammate profile settings.
     * 
     * @param event_id The event ID.
     * @param body Command used to update the settings.
     */
    public update(event_id: string, body: EventSettingsTeammateProfileCommandDTO): Observable<EventSettingsTeammateProfileResponseDTO> {
        return this.httpPost<EventSettingsTeammateProfileResponseDTO>(`/events/${event_id}/settings/teammate-profile`, body);
    }
}
