import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { BaseAPIService } from './base.service';
import { ContactResponseDTO } from '../models/messaging/responses/ContactResponseDTO';
import { NotificationResponseDTO } from '../models/messaging/responses/NotificationResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class MessagingAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    private listContactsSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<ContactResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<ContactResponseDTO>>(`/messaging/contacts`, { params });
    }

    /**
    * Lists all of the current user's messaging contacts.
    * 
    */
    public listContacts(): Observable<ContactResponseDTO[]> {
        return this.getAllSegments(this.listContactsSegment.bind(this));
    }
  
    /**
    * Gets the current user's messaging notifications.
    * 
    */
    public getNotifications(): Observable<NotificationResponseDTO> {
        return this.httpGet<NotificationResponseDTO>(`/messaging/notifications`, { ignoreConnectivityCheck: true });
    }
}