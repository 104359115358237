import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiClientConfiguration } from '../configuration';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { AuthService } from '../auth/auth.service';
import { UnreadStaffOrganizationMessagesCommandDTO } from '../models/organization-message/commands/UnreadStaffOrganizationMessagesCommandDTO';
import { TeammateOrganizationMessageResponseDTO } from '../models/organization-message/responses/TeammateOrganizationMessageResponseDTO';
import { CreateStaffOrganizationMessageCommandDTO } from '../models/organization-message/commands/CreateStaffOrganizationMessageCommandDTO';
import { CreateTeammateOrganizationMessageCommandDTO } from '../models/organization-message/commands/CreateTeammateOrganizatioMessageCommandDTO';
import { ReadStaffOrganizationMessagesCommandDTO } from '../models/organization-message/commands/ReadStaffOrganizationMessagesCommandDTO';
import { StaffOrganizationMessageResponseDTO } from '../models/organization-message/responses/StaffOrganizationMessageResponseDTO';
import { StaffMessagingStatusResponseDTO } from '../models/common/StaffMessagingStatusResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class OrganizationMessageAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Sends an organization message to one or more teammates, as an organization staff member.
     * @param organization_id id of the organization
     * @param body The command used to initialize the new teammate.
     */
    public sendMessageToTeammates(organization_id: string, body: CreateStaffOrganizationMessageCommandDTO): Observable<void> {
        return this.httpPost<void>(`/organizations/${organization_id}/messages/staff`, body);
    }

    /**
     * Marks all organization messages sent by the specified teammate as read.
     * @param body The command used to initialize the new teammate.
     */
    public markTeammateMessagesAsRead(organization_id: string, body: ReadStaffOrganizationMessagesCommandDTO): Observable<void> {
        return this.httpPost<void>(`/organizations/${organization_id}/messages/staff/read`, body);
    }
    
    /**
     * Deletes the specified organization message.
     * @param id id of the message
     * @param organization_id id of the organization
     */
    public deleteStaffMessage(id: string, organization_id: string): Observable<void> {
        return this.httpDelete(`/organizations/${organization_id}/messages/staff/${id}`);
    }

    private listMessagesByTeammateSegment(segment_index: number|undefined, query_token: string|undefined, organization_id: string, teammate_id: string): Observable<ResultSegmentDTO<StaffOrganizationMessageResponseDTO>> {
        let params = new HttpParams();
        params = params.append("teammate_id", teammate_id);
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<StaffOrganizationMessageResponseDTO>>(`/organizations/${organization_id}/messages/staff/by-teammate`, { params });
    }

    /**
     * Lists all organization messages for the specified teammate.
     * 
     * @param organization_id id of the organization
     * @param teammate_id id of the teammate
     */
    public listMessagesByTeammate(organization_id: string, teammate_id: string): Observable<StaffOrganizationMessageResponseDTO[]> {
        return this.getAllSegments(this.listMessagesByTeammateSegment.bind(this), organization_id, teammate_id);
    }

    /**
     * Sends an organization message to the organization staff members, as a teammate.
     * @param organization_id id of the organization
     * @param body The command used to initialize the new teammate.
     */
    public sendMessageToStaff(organization_id: string, body: CreateTeammateOrganizationMessageCommandDTO): Observable<void> {
        return this.httpPost<void>(`/organizations/${organization_id}/messages/teammate`, body);
    }

    /**
     * Marks all organization messages sent to the current teammate user by the organization staff members as read.
     * @param body The command used to initialize the new teammate.
     */
    public markOrganizationMessagesAsRead(organization_id: string): Observable<void> {
        return this.httpPost<void>(`/organizations/${organization_id}/messages/teammate/read`, null);
    }

    private listMyMessagesSegment(segment_index: number|undefined, query_token: string|undefined, organization_id: string): Observable<ResultSegmentDTO<TeammateOrganizationMessageResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<TeammateOrganizationMessageResponseDTO>>(`/organizations/${organization_id}/messages/teammate/mine`, { params });
    }

    /**
     * Lists all organization messages for the current teammate.
     * 
     * @param organization_id id of the organization
     */
    public listMyMessages(organization_id: string): Observable<TeammateOrganizationMessageResponseDTO[]> {
        return this.getAllSegments(this.listMyMessagesSegment.bind(this), organization_id);
    }
    
    /**
     * Marks the specified organization message sent by the specified teammate as unread, as well as all messages sent by him after that.
     * @param organization_id id of the organization
     * @param body The command used to update the organization messages.
     */
    public markOrganizationMessagesAsUnread(organization_id: string, body: UnreadStaffOrganizationMessagesCommandDTO): Observable<void> {
        return this.httpPost<void>(`/organizations/${organization_id}/messages/staff/unread`, body);
    }
    
     /**
    * Gets the organization messaging status.
    * 
    * @param organization_id id of the event
    */
     public getMesagingStatus(organization_id: string): Observable<StaffMessagingStatusResponseDTO> {
        return this.httpGet<StaffMessagingStatusResponseDTO>(`/organizations/${organization_id}/messages/staff/status`);
    }
}
