import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import { SortService } from '../sort-service/sort.service';

@Injectable({
    providedIn: 'root',
})
export class VocabularyService {
    default_locale = "en-US";
    constructor(@Inject(LOCALE_ID) public locale: string) { }

    public getCountries(): Country[] {
        const i18nCountries = <any>Vocabularies.countries;
        const countries = <Country[]>(i18nCountries[this.locale] ? i18nCountries[this.locale] : i18nCountries[this.default_locale]);

        return countries.sort(SortService.getStringComparator("title"));
    }

    public getTimezones(): TimeZone[] {
        return moment.tz.names().map(code => {
            const offset = moment.tz(code).utcOffset() / 60;
            const hours = Math.floor(offset);
            const minutes = (offset - hours)*60;
            return {
                code,
                title: offset < 0 ? `(UTC-${(-hours).toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}) ${code}`
                :`(UTC+${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}) ${code}`
            }
        }).sort((a,b) => a.title.localeCompare(b.title));
    }
}

export interface Country {
    code: string;
    title: string;
}

export interface TimeZone {
    code: string;
    title: string;
}

const Vocabularies = {
    // Source at https://www.iso.org/obp/ui/ (JSON exported and formated from XHR request)
    countries: {
        "en-US": [
            { "code": "AD", "title": "Andorra" },
            { "code": "AE", "title": "United Arab Emirates" },
            { "code": "AF", "title": "Afghanistan" },
            { "code": "AG", "title": "Antigua and Barbuda" },
            { "code": "AI", "title": "Anguilla" },
            { "code": "AL", "title": "Albania" },
            { "code": "AM", "title": "Armenia" },
            { "code": "AO", "title": "Angola" },
            { "code": "AQ", "title": "Antarctica" },
            { "code": "AR", "title": "Argentina" },
            { "code": "AS", "title": "American Samoa" },
            { "code": "AT", "title": "Austria" },
            { "code": "AU", "title": "Australia" },
            { "code": "AW", "title": "Aruba" },
            { "code": "AX", "title": "Åland Islands" },
            { "code": "AZ", "title": "Azerbaijan" },
            { "code": "BA", "title": "Bosnia and Herzegovina" },
            { "code": "BB", "title": "Barbados" },
            { "code": "BD", "title": "Bangladesh" },
            { "code": "BE", "title": "Belgium" },
            { "code": "BF", "title": "Burkina Faso" },
            { "code": "BG", "title": "Bulgaria" },
            { "code": "BH", "title": "Bahrain" },
            { "code": "BI", "title": "Burundi" },
            { "code": "BJ", "title": "Benin" },
            { "code": "BL", "title": "Saint Barthélemy" },
            { "code": "BM", "title": "Bermuda" },
            { "code": "BN", "title": "Brunei Darussalam" },
            { "code": "BO", "title": "Bolivia (Plurinational State of)" },
            { "code": "BQ", "title": "Bonaire, Sint Eustatius and Saba" },
            { "code": "BR", "title": "Brazil" },
            { "code": "BS", "title": "Bahamas" },
            { "code": "BT", "title": "Bhutan" },
            { "code": "BV", "title": "Bouvet Island" },
            { "code": "BW", "title": "Botswana" },
            { "code": "BY", "title": "Belarus" },
            { "code": "BZ", "title": "Belize" },
            { "code": "CA", "title": "Canada" },
            { "code": "CC", "title": "Cocos (Keeling) Islands" },
            { "code": "CD", "title": "Congo (Democratic Republic of the)" },
            { "code": "CF", "title": "Central African Republic" },
            { "code": "CG", "title": "Congo" },
            { "code": "CH", "title": "Switzerland" },
            { "code": "CI", "title": "Côte d'Ivoire" },
            { "code": "CK", "title": "Cook Islands" },
            { "code": "CL", "title": "Chile" },
            { "code": "CM", "title": "Cameroon" },
            { "code": "CN", "title": "China" },
            { "code": "CO", "title": "Colombia" },
            { "code": "CR", "title": "Costa Rica" },
            { "code": "CU", "title": "Cuba" },
            { "code": "CV", "title": "Cabo Verde" },
            { "code": "CW", "title": "Curaçao" },
            { "code": "CX", "title": "Christmas Island" },
            { "code": "CY", "title": "Cyprus" },
            { "code": "CZ", "title": "Czechia" },
            { "code": "DE", "title": "Germany" },
            { "code": "DJ", "title": "Djibouti" },
            { "code": "DK", "title": "Denmark" },
            { "code": "DM", "title": "Dominica" },
            { "code": "DO", "title": "Dominican Republic" },
            { "code": "DZ", "title": "Algeria" },
            { "code": "EC", "title": "Ecuador" },
            { "code": "EE", "title": "Estonia" },
            { "code": "EG", "title": "Egypt" },
            { "code": "EH", "title": "Western Sahara" },
            { "code": "ER", "title": "Eritrea" },
            { "code": "ES", "title": "Spain" },
            { "code": "ET", "title": "Ethiopia" },
            { "code": "FI", "title": "Finland" },
            { "code": "FJ", "title": "Fiji" },
            { "code": "FK", "title": "Falkland Islands [Malvinas]" },
            { "code": "FM", "title": "Micronesia (Federated States of)" },
            { "code": "FO", "title": "Faroe Islands" },
            { "code": "FR", "title": "France" },
            { "code": "GA", "title": "Gabon" },
            { "code": "GB", "title": "United Kingdom of Great Britain and Northern Ireland" },
            { "code": "GD", "title": "Grenada" },
            { "code": "GE", "title": "Georgia" },
            { "code": "GF", "title": "French Guiana" },
            { "code": "GG", "title": "Guernsey" },
            { "code": "GH", "title": "Ghana" },
            { "code": "GI", "title": "Gibraltar" },
            { "code": "GL", "title": "Greenland" },
            { "code": "GM", "title": "Gambia" },
            { "code": "GN", "title": "Guinea" },
            { "code": "GP", "title": "Guadeloupe" },
            { "code": "GQ", "title": "Equatorial Guinea" },
            { "code": "GR", "title": "Greece" },
            { "code": "GS", "title": "South Georgia and the South Sandwich Islands" },
            { "code": "GT", "title": "Guatemala" },
            { "code": "GU", "title": "Guam" },
            { "code": "GW", "title": "Guinea-Bissau" },
            { "code": "GY", "title": "Guyana" },
            { "code": "HK", "title": "Hong Kong" },
            { "code": "HM", "title": "Heard Island and McDonald Islands" },
            { "code": "HN", "title": "Honduras" },
            { "code": "HR", "title": "Croatia" },
            { "code": "HT", "title": "Haiti" },
            { "code": "HU", "title": "Hungary" },
            { "code": "ID", "title": "Indonesia" },
            { "code": "IE", "title": "Ireland" },
            { "code": "IL", "title": "Israel" },
            { "code": "IM", "title": "Isle of Man" },
            { "code": "IN", "title": "India" },
            { "code": "IO", "title": "British Indian Ocean Territory" },
            { "code": "IQ", "title": "Iraq" },
            { "code": "IR", "title": "Iran (Islamic Republic of)" },
            { "code": "IS", "title": "Iceland" },
            { "code": "IT", "title": "Italy" },
            { "code": "JE", "title": "Jersey" },
            { "code": "JM", "title": "Jamaica" },
            { "code": "JO", "title": "Jordan" },
            { "code": "JP", "title": "Japan" },
            { "code": "KE", "title": "Kenya" },
            { "code": "KG", "title": "Kyrgyzstan" },
            { "code": "KH", "title": "Cambodia" },
            { "code": "KI", "title": "Kiribati" },
            { "code": "KM", "title": "Comoros" },
            { "code": "KN", "title": "Saint Kitts and Nevis" },
            { "code": "KP", "title": "Korea (Democratic People's Republic of)" },
            { "code": "KR", "title": "Korea (Republic of)" },
            { "code": "KW", "title": "Kuwait" },
            { "code": "KY", "title": "Cayman Islands" },
            { "code": "KZ", "title": "Kazakhstan" },
            { "code": "LA", "title": "Lao People's Democratic Republic" },
            { "code": "LB", "title": "Lebanon" },
            { "code": "LC", "title": "Saint Lucia" },
            { "code": "LI", "title": "Liechtenstein" },
            { "code": "LK", "title": "Sri Lanka" },
            { "code": "LR", "title": "Liberia" },
            { "code": "LS", "title": "Lesotho" },
            { "code": "LT", "title": "Lithuania" },
            { "code": "LU", "title": "Luxembourg" },
            { "code": "LV", "title": "Latvia" },
            { "code": "LY", "title": "Libya" },
            { "code": "MA", "title": "Morocco" },
            { "code": "MC", "title": "Monaco" },
            { "code": "MD", "title": "Moldova (Republic of)" },
            { "code": "ME", "title": "Montenegro" },
            { "code": "MF", "title": "Saint Martin (French part)" },
            { "code": "MG", "title": "Madagascar" },
            { "code": "MH", "title": "Marshall Islands" },
            { "code": "MK", "title": "North Macedonia" },
            { "code": "ML", "title": "Mali" },
            { "code": "MM", "title": "Myanmar" },
            { "code": "MN", "title": "Mongolia" },
            { "code": "MO", "title": "Macao" },
            { "code": "MP", "title": "Northern Mariana Islands" },
            { "code": "MQ", "title": "Martinique" },
            { "code": "MR", "title": "Mauritania" },
            { "code": "MS", "title": "Montserrat" },
            { "code": "MT", "title": "Malta" },
            { "code": "MU", "title": "Mauritius" },
            { "code": "MV", "title": "Maldives" },
            { "code": "MW", "title": "Malawi" },
            { "code": "MX", "title": "Mexico" },
            { "code": "MY", "title": "Malaysia" },
            { "code": "MZ", "title": "Mozambique" },
            { "code": "NA", "title": "Namibia" },
            { "code": "NC", "title": "New Caledonia" },
            { "code": "NE", "title": "Niger" },
            { "code": "NF", "title": "Norfolk Island" },
            { "code": "NG", "title": "Nigeria" },
            { "code": "NI", "title": "Nicaragua" },
            { "code": "NL", "title": "Netherlands" },
            { "code": "NO", "title": "Norway" },
            { "code": "NP", "title": "Nepal" },
            { "code": "NR", "title": "Nauru" },
            { "code": "NU", "title": "Niue" },
            { "code": "NZ", "title": "New Zealand" },
            { "code": "OM", "title": "Oman" },
            { "code": "PA", "title": "Panama" },
            { "code": "PE", "title": "Peru" },
            { "code": "PF", "title": "French Polynesia" },
            { "code": "PG", "title": "Papua New Guinea" },
            { "code": "PH", "title": "Philippines" },
            { "code": "PK", "title": "Pakistan" },
            { "code": "PL", "title": "Poland" },
            { "code": "PM", "title": "Saint Pierre and Miquelon" },
            { "code": "PN", "title": "Pitcairn" },
            { "code": "PR", "title": "Puerto Rico" },
            { "code": "PS", "title": "Palestine, State of" },
            { "code": "PT", "title": "Portugal" },
            { "code": "PW", "title": "Palau" },
            { "code": "PY", "title": "Paraguay" },
            { "code": "QA", "title": "Qatar" },
            { "code": "RE", "title": "Réunion" },
            { "code": "RO", "title": "Romania" },
            { "code": "RS", "title": "Serbia" },
            { "code": "RU", "title": "Russian Federation" },
            { "code": "RW", "title": "Rwanda" },
            { "code": "SA", "title": "Saudi Arabia" },
            { "code": "SB", "title": "Solomon Islands" },
            { "code": "SC", "title": "Seychelles" },
            { "code": "SD", "title": "Sudan" },
            { "code": "SE", "title": "Sweden" },
            { "code": "SG", "title": "Singapore" },
            { "code": "SH", "title": "Saint Helena, Ascension and Tristan da Cunha" },
            { "code": "SI", "title": "Slovenia" },
            { "code": "SJ", "title": "Svalbard and Jan Mayen" },
            { "code": "SK", "title": "Slovakia" },
            { "code": "SL", "title": "Sierra Leone" },
            { "code": "SM", "title": "San Marino" },
            { "code": "SN", "title": "Senegal" },
            { "code": "SO", "title": "Somalia" },
            { "code": "SR", "title": "Suriname" },
            { "code": "SS", "title": "South Sudan" },
            { "code": "ST", "title": "Sao Tome and Principe" },
            { "code": "SV", "title": "El Salvador" },
            { "code": "SX", "title": "Sint Maarten (Dutch part)" },
            { "code": "SY", "title": "Syrian Arab Republic" },
            { "code": "SZ", "title": "Eswatini" },
            { "code": "TC", "title": "Turks and Caicos Islands" },
            { "code": "TD", "title": "Chad" },
            { "code": "TF", "title": "French Southern Territories" },
            { "code": "TG", "title": "Togo" },
            { "code": "TH", "title": "Thailand" },
            { "code": "TJ", "title": "Tajikistan" },
            { "code": "TK", "title": "Tokelau" },
            { "code": "TL", "title": "Timor-Leste" },
            { "code": "TM", "title": "Turkmenistan" },
            { "code": "TN", "title": "Tunisia" },
            { "code": "TO", "title": "Tonga" },
            { "code": "TR", "title": "Turkey" },
            { "code": "TT", "title": "Trinidad and Tobago" },
            { "code": "TV", "title": "Tuvalu" },
            { "code": "TW", "title": "Taiwan (Province of China)" },
            { "code": "TZ", "title": "Tanzania, United Republic of" },
            { "code": "UA", "title": "Ukraine" },
            { "code": "UG", "title": "Uganda" },
            { "code": "UM", "title": "United States Minor Outlying Islands" },
            { "code": "US", "title": "United States of America" },
            { "code": "UY", "title": "Uruguay" },
            { "code": "UZ", "title": "Uzbekistan" },
            { "code": "VA", "title": "Holy See" },
            { "code": "VC", "title": "Saint Vincent and the Grenadines" },
            { "code": "VE", "title": "Venezuela (Bolivarian Republic of)" },
            { "code": "VG", "title": "Virgin Islands (British)" },
            { "code": "VI", "title": "Virgin Islands (U.S.)" },
            { "code": "VN", "title": "Viet Nam" },
            { "code": "VU", "title": "Vanuatu" },
            { "code": "WF", "title": "Wallis and Futuna" },
            { "code": "WS", "title": "Samoa" },
            { "code": "YE", "title": "Yemen" },
            { "code": "YT", "title": "Mayotte" },
            { "code": "ZA", "title": "South Africa" },
            { "code": "ZM", "title": "Zambia" },
            { "code": "ZW", "title": "Zimbabwe" }
        ],
        "fr-FR": [
            { "code": "AD", "title": "Andorre" },
            { "code": "AE", "title": "Émirats arabes unis" },
            { "code": "AF", "title": "Afghanistan" },
            { "code": "AG", "title": "Antigua-et-Barbuda" },
            { "code": "AI", "title": "Anguilla" },
            { "code": "AL", "title": "Albanie" },
            { "code": "AM", "title": "Arménie" },
            { "code": "AO", "title": "Angola" },
            { "code": "AQ", "title": "Antarctique" },
            { "code": "AR", "title": "Argentine" },
            { "code": "AS", "title": "Samoa américaines" },
            { "code": "AT", "title": "Autriche" },
            { "code": "AU", "title": "Australie" },
            { "code": "AW", "title": "Aruba" },
            { "code": "AX", "title": "Åland (Îles)" },
            { "code": "AZ", "title": "Azerbaïdjan" },
            { "code": "BA", "title": "Bosnie-Herzégovine" },
            { "code": "BB", "title": "Barbade" },
            { "code": "BD", "title": "Bangladesh" },
            { "code": "BE", "title": "Belgique" },
            { "code": "BF", "title": "Burkina Faso" },
            { "code": "BG", "title": "Bulgarie" },
            { "code": "BH", "title": "Bahreïn" },
            { "code": "BI", "title": "Burundi" },
            { "code": "BJ", "title": "Bénin" },
            { "code": "BL", "title": "Saint-Barthélemy" },
            { "code": "BM", "title": "Bermudes" },
            { "code": "BN", "title": "Brunéi Darussalam" },
            { "code": "BO", "title": "Bolivie (État plurinational de)" },
            { "code": "BQ", "title": "Bonaire, Saint-Eustache et Saba" },
            { "code": "BR", "title": "Brésil" },
            { "code": "BS", "title": "Bahamas" },
            { "code": "BT", "title": "Bhoutan" },
            { "code": "BV", "title": "Bouvet (Île)" },
            { "code": "BW", "title": "Botswana" },
            { "code": "BY", "title": "Bélarus" },
            { "code": "BZ", "title": "Belize" },
            { "code": "CA", "title": "Canada" },
            { "code": "CC", "title": "Cocos (Îles) / Keeling (Îles)" },
            { "code": "CD", "title": "Congo (République démocratique du)" },
            { "code": "CF", "title": "République centrafricaine" },
            { "code": "CG", "title": "Congo" },
            { "code": "CH", "title": "Suisse" },
            { "code": "CI", "title": "Côte d'Ivoire" },
            { "code": "CK", "title": "Cook (Îles)" },
            { "code": "CL", "title": "Chili" },
            { "code": "CM", "title": "Cameroun" },
            { "code": "CN", "title": "Chine" },
            { "code": "CO", "title": "Colombie" },
            { "code": "CR", "title": "Costa Rica" },
            { "code": "CU", "title": "Cuba" },
            { "code": "CV", "title": "Cabo Verde" },
            { "code": "CW", "title": "Curaçao" },
            { "code": "CX", "title": "Christmas (Île)" },
            { "code": "CY", "title": "Chypre" },
            { "code": "CZ", "title": "Tchéquie" },
            { "code": "DE", "title": "Allemagne" },
            { "code": "DJ", "title": "Djibouti" },
            { "code": "DK", "title": "Danemark" },
            { "code": "DM", "title": "Dominique" },
            { "code": "DO", "title": "dominicaine (République)" },
            { "code": "DZ", "title": "Algérie" },
            { "code": "EC", "title": "Équateur" },
            { "code": "EE", "title": "Estonie" },
            { "code": "EG", "title": "Égypte" },
            { "code": "EH", "title": "Sahara occidental" },
            { "code": "ER", "title": "Érythrée" },
            { "code": "ES", "title": "Espagne" },
            { "code": "ET", "title": "Éthiopie" },
            { "code": "FI", "title": "Finlande" },
            { "code": "FJ", "title": "Fidji" },
            { "code": "FK", "title": "Falkland (Îles) / Malouines (Îles)" },
            { "code": "FM", "title": "Micronésie (États fédérés de)" },
            { "code": "FO", "title": "Féroé (Îles)" },
            { "code": "FR", "title": "France" },
            { "code": "GA", "title": "Gabon" },
            { "code": "GB", "title": "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord" },
            { "code": "GD", "title": "Grenade" },
            { "code": "GE", "title": "Géorgie" },
            { "code": "GF", "title": "Guyane française" },
            { "code": "GG", "title": "Guernesey" },
            { "code": "GH", "title": "Ghana" },
            { "code": "GI", "title": "Gibraltar" },
            { "code": "GL", "title": "Groenland" },
            { "code": "GM", "title": "Gambie" },
            { "code": "GN", "title": "Guinée" },
            { "code": "GP", "title": "Guadeloupe" },
            { "code": "GQ", "title": "Guinée équatoriale" },
            { "code": "GR", "title": "Grèce" },
            { "code": "GS", "title": "Géorgie du Sud-et-les Îles Sandwich du Sud" },
            { "code": "GT", "title": "Guatemala" },
            { "code": "GU", "title": "Guam" },
            { "code": "GW", "title": "Guinée-Bissau" },
            { "code": "GY", "title": "Guyana" },
            { "code": "HK", "title": "Hong Kong" },
            { "code": "HM", "title": "Heard-et-Îles MacDonald (Île)" },
            { "code": "HN", "title": "Honduras" },
            { "code": "HR", "title": "Croatie" },
            { "code": "HT", "title": "Haïti" },
            { "code": "HU", "title": "Hongrie" },
            { "code": "ID", "title": "Indonésie" },
            { "code": "IE", "title": "Irlande" },
            { "code": "IL", "title": "Israël" },
            { "code": "IM", "title": "Île de Man" },
            { "code": "IN", "title": "Inde" },
            { "code": "IO", "title": "Indien (Territoire britannique de l'océan)" },
            { "code": "IQ", "title": "Iraq" },
            { "code": "IR", "title": "Iran (République Islamique d')" },
            { "code": "IS", "title": "Islande" },
            { "code": "IT", "title": "Italie" },
            { "code": "JE", "title": "Jersey" },
            { "code": "JM", "title": "Jamaïque" },
            { "code": "JO", "title": "Jordanie" },
            { "code": "JP", "title": "Japon" },
            { "code": "KE", "title": "Kenya" },
            { "code": "KG", "title": "Kirghizistan" },
            { "code": "KH", "title": "Cambodge" },
            { "code": "KI", "title": "Kiribati" },
            { "code": "KM", "title": "Comores" },
            { "code": "KN", "title": "Saint-Kitts-et-Nevis" },
            { "code": "KP", "title": "Corée (République populaire démocratique de)" },
            { "code": "KR", "title": "Corée (République de)" },
            { "code": "KW", "title": "Koweït" },
            { "code": "KY", "title": "Caïmans (Îles)" },
            { "code": "KZ", "title": "Kazakhstan" },
            { "code": "LA", "title": "Lao (République démocratique populaire)" },
            { "code": "LB", "title": "Liban" },
            { "code": "LC", "title": "Sainte-Lucie" },
            { "code": "LI", "title": "Liechtenstein" },
            { "code": "LK", "title": "Sri Lanka" },
            { "code": "LR", "title": "Libéria" },
            { "code": "LS", "title": "Lesotho" },
            { "code": "LT", "title": "Lituanie" },
            { "code": "LU", "title": "Luxembourg" },
            { "code": "LV", "title": "Lettonie" },
            { "code": "LY", "title": "Libye" },
            { "code": "MA", "title": "Maroc" },
            { "code": "MC", "title": "Monaco" },
            { "code": "MD", "title": "Moldova (République de)" },
            { "code": "ME", "title": "Monténégro" },
            { "code": "MF", "title": "Saint-Martin (partie française)" },
            { "code": "MG", "title": "Madagascar" },
            { "code": "MH", "title": "Marshall (Îles)" },
            { "code": "MK", "title": "Macédoine du Nord" },
            { "code": "ML", "title": "Mali" },
            { "code": "MM", "title": "Myanmar" },
            { "code": "MN", "title": "Mongolie" },
            { "code": "MO", "title": "Macao" },
            { "code": "MP", "title": "Mariannes du Nord (Îles)" },
            { "code": "MQ", "title": "Martinique" },
            { "code": "MR", "title": "Mauritanie" },
            { "code": "MS", "title": "Montserrat" },
            { "code": "MT", "title": "Malte" },
            { "code": "MU", "title": "Maurice" },
            { "code": "MV", "title": "Maldives" },
            { "code": "MW", "title": "Malawi" },
            { "code": "MX", "title": "Mexique" },
            { "code": "MY", "title": "Malaisie" },
            { "code": "MZ", "title": "Mozambique" },
            { "code": "NA", "title": "Namibie" },
            { "code": "NC", "title": "Nouvelle-Calédonie" },
            { "code": "NE", "title": "Niger" },
            { "code": "NF", "title": "Norfolk (Île)" },
            { "code": "NG", "title": "Nigéria" },
            { "code": "NI", "title": "Nicaragua" },
            { "code": "NL", "title": "Pays-Bas" },
            { "code": "NO", "title": "Norvège" },
            { "code": "NP", "title": "Népal" },
            { "code": "NR", "title": "Nauru" },
            { "code": "NU", "title": "Niue" },
            { "code": "NZ", "title": "Nouvelle-Zélande" },
            { "code": "OM", "title": "Oman" },
            { "code": "PA", "title": "Panama" },
            { "code": "PE", "title": "Pérou" },
            { "code": "PF", "title": "Polynésie française" },
            { "code": "PG", "title": "Papouasie-Nouvelle-Guinée" },
            { "code": "PH", "title": "Philippines" },
            { "code": "PK", "title": "Pakistan" },
            { "code": "PL", "title": "Pologne" },
            { "code": "PM", "title": "Saint-Pierre-et-Miquelon" },
            { "code": "PN", "title": "Pitcairn" },
            { "code": "PR", "title": "Porto Rico" },
            { "code": "PS", "title": "Palestine, État de" },
            { "code": "PT", "title": "Portugal" },
            { "code": "PW", "title": "Palaos" },
            { "code": "PY", "title": "Paraguay" },
            { "code": "QA", "title": "Qatar" },
            { "code": "RE", "title": "Réunion" },
            { "code": "RO", "title": "Roumanie" },
            { "code": "RS", "title": "Serbie" },
            { "code": "RU", "title": "Russie (Fédération de)" },
            { "code": "RW", "title": "Rwanda" },
            { "code": "SA", "title": "Arabie saoudite" },
            { "code": "SB", "title": "Salomon (Îles)" },
            { "code": "SC", "title": "Seychelles" },
            { "code": "SD", "title": "Soudan" },
            { "code": "SE", "title": "Suède" },
            { "code": "SG", "title": "Singapour" },
            { "code": "SH", "title": "Sainte-Hélène, Ascension et Tristan da Cunha" },
            { "code": "SI", "title": "Slovénie" },
            { "code": "SJ", "title": "Svalbard et l'Île Jan Mayen" },
            { "code": "SK", "title": "Slovaquie" },
            { "code": "SL", "title": "Sierra Leone" },
            { "code": "SM", "title": "Saint-Marin" },
            { "code": "SN", "title": "Sénégal" },
            { "code": "SO", "title": "Somalie" },
            { "code": "SR", "title": "Suriname" },
            { "code": "SS", "title": "Soudan du Sud" },
            { "code": "ST", "title": "Sao Tomé-et-Principe" },
            { "code": "SV", "title": "El Salvador" },
            { "code": "SX", "title": "Saint-Martin (partie néerlandaise)" },
            { "code": "SY", "title": "République arabe syrienne" },
            { "code": "SZ", "title": "Eswatini" },
            { "code": "TC", "title": "Turks-et-Caïcos (Îles)" },
            { "code": "TD", "title": "Tchad" },
            { "code": "TF", "title": "Terres australes françaises" },
            { "code": "TG", "title": "Togo" },
            { "code": "TH", "title": "Thaïlande" },
            { "code": "TJ", "title": "Tadjikistan" },
            { "code": "TK", "title": "Tokelau" },
            { "code": "TL", "title": "Timor-Leste" },
            { "code": "TM", "title": "Turkménistan" },
            { "code": "TN", "title": "Tunisie" },
            { "code": "TO", "title": "Tonga" },
            { "code": "TR", "title": "Turquie" },
            { "code": "TT", "title": "Trinité-et-Tobago" },
            { "code": "TV", "title": "Tuvalu" },
            { "code": "TW", "title": "Taïwan (Province de Chine)" },
            { "code": "TZ", "title": "Tanzanie (République-Unie de)" },
            { "code": "UA", "title": "Ukraine" },
            { "code": "UG", "title": "Ouganda" },
            { "code": "UM", "title": "Îles mineures éloignées des États-Unis" },
            { "code": "US", "title": "États-Unis d'Amérique" },
            { "code": "UY", "title": "Uruguay" },
            { "code": "UZ", "title": "Ouzbékistan" },
            { "code": "VA", "title": "Saint-Siège" },
            { "code": "VC", "title": "Saint-Vincent-et-les Grenadines" },
            { "code": "VE", "title": "Venezuela (République bolivarienne du)" },
            { "code": "VG", "title": "Vierges britanniques (Îles)" },
            { "code": "VI", "title": "Vierges des États-Unis (Îles)" },
            { "code": "VN", "title": "Viet Nam" },
            { "code": "VU", "title": "Vanuatu" },
            { "code": "WF", "title": "Wallis-et-Futuna " },
            { "code": "WS", "title": "Samoa" },
            { "code": "YE", "title": "Yémen" },
            { "code": "YT", "title": "Mayotte" },
            { "code": "ZA", "title": "Afrique du Sud" },
            { "code": "ZM", "title": "Zambie" },
            { "code": "ZW", "title": "Zimbabwe" }
        ]
    }
}