import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { AdminPublisherCreateCommandDTO } from '../models/admin-publisher/commands/AdminPublisherCreateCommandDTO';
import { AdminPublisherResponseDTO } from '../models/admin-publisher/responses/AdminPublisherResponseDTO';
import { AdminPublisherUpdateCommandDTO } from '../models/admin-publisher/commands/AdminPublisherUpdateCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class AdminPublisherAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    public create(body: AdminPublisherCreateCommandDTO): Observable<AdminPublisherResponseDTO> {
        return this.httpPost<AdminPublisherResponseDTO>(`/admin/publishers`, body);
    }

    public list(): Observable<AdminPublisherResponseDTO[]> {
        return this.getAllSegments(this.listSegments.bind(this));
    }

    private listSegments(segment_index: number | undefined, query_token: string | undefined): Observable<ResultSegmentDTO<AdminPublisherResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AdminPublisherResponseDTO>>(`/admin/publishers`, { params });
    }

    public get(id: string): Observable<AdminPublisherResponseDTO> {
        return this.httpGet<AdminPublisherResponseDTO>(`/admin/publishers/${id}`);
    }

    public update(id: string, body: AdminPublisherUpdateCommandDTO): Observable<AdminPublisherResponseDTO> {
        return this.httpPost<AdminPublisherResponseDTO>(`/admin/publishers/${id}`, body);
    }

    public delete(id: string): Observable<void> {
        return this.httpDelete(`/admin/publishers/${id}`);
    }
}
