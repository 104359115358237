import { HttpContext } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ByPassAuthorizationContextToken } from '../auth/bearer.interceptor';
import { PublicApplicationSettingsResponseDTO } from '../models/application/responses/PublicApplicationSettingsResponseDTO';

import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class ApplicationSettingsAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    public getPublicSettings(): Observable<PublicApplicationSettingsResponseDTO> {
        return this.httpGet<PublicApplicationSettingsResponseDTO>(`/application/settings/public`, { 
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }
}
