<div class="dialog-content-wrapper pt-5">
    <div mat-dialog-content [ngClass]="{'pt-8 pb-4': data.icon != undefined }">
        @if (data.icon != undefined) {
            <p class="text-center">
                @switch (data.icon) {
                    @case (IconTypes.SUCCESS) {
                        <img class="inline max-w-210px" src="assets/images/icons/ico-success.svg" alt="" />
                    }
                    @case (IconTypes.WAIT) {
                        <img class="inline max-w-210px" src="assets/images/icons/ico-waiting.svg" alt="" />
                    }
                    @case (IconTypes.INFO) {
                        <img class="inline max-w-210px" src="assets/images/icons/ico-info.svg" alt="" />
                    }
                    @case (IconTypes.WARNING) {
                        <img class="inline max-w-210px" src="assets/images/icons/ico-warning.svg" alt="" />
                    }
                    @case (IconTypes.ERROR) {
                        <img class="inline max-w-210px" src="assets/images/icons/ico-error.svg" alt="" />
                    }
                    @case (IconTypes.CUSTOM) {
                        <img class="inline max-w-210px" [src]="data.icon_url" alt="" />
                    }
                }
            </p>
        }

        @if (data.title) {
            <p class="text-xl font-medium" [ngClass]="{'text-center': data.icon != undefined }">{{data.title}}</p>
        }

        @if (data.body) {
            <div class="mb-3" [innerHTML]="data.body" [ngClass]="{'text-center': data.icon != undefined }"></div>
        }
        @if (data.type === MessageTypes.STRONG_CONFIRM) {
            <div class="qo-form">
                <mat-checkbox class="pb-2" color="primary" [(ngModel)]="strongConfirm" name="strong_confirm">{{data.strongConfirmLabel}}</mat-checkbox>
            </div>
        }
    </div>
    <div mat-dialog-actions>
        @if (data.customButtons) {
            @for (customButton of data.customButtons; track customButton) {
                @switch (customButton.type) {
                    @case ('FLAT') {
                        <button mat-flat-button [color]="customButton.color"
                        (click)="customButton.onClick();dialogRef.close();">{{customButton.title}}</button>
                    }
                    @case ('STROKED') {
                        <button mat-stroked-button [color]="customButton.color"
                        (click)="customButton.onClick();dialogRef.close();">{{customButton.title}}</button>
                    }
                    @default {
                        <button mat-button [color]="customButton.color"
                        (click)="customButton.onClick();dialogRef.close();">{{customButton.title}}</button>
                    }
                }
            }
        }
        @if (!data.customButtons) {
            <button mat-flat-button color="primary" (click)="dialogRef.close(true)"
            [disabled]="data.type === MessageTypes.STRONG_CONFIRM && !strongConfirm" i18n>OK</button>
            @if (data.type !== MessageTypes.INFO) {
                <button mat-button (click)="dialogRef.close(false)" i18n>Cancel</button>
            }
        }
    </div>
</div>