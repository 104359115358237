import { Injectable, LOCALE_ID, Inject, Injector } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { AuthService } from 'projects/api-client/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    private supportedLanguages: string[] = [];
    public set SupportedLanguages(value: string[]) {
        this.supportedLanguages = value;
    };

    constructor(@Inject(LOCALE_ID) private locale: string,
        @Inject(APP_BASE_HREF) private baseHref: string,
        private authService: AuthService) {
    }

    public checkUserLanguage() {
        // If not using PWA installed App
        if (!window.matchMedia('(display-mode: standalone)').matches) {
            if (this.authService.currentUserContext != null) {
                let userLanguageCode = this.authService.currentUserContext.language_code as string;
                if (this.supportedLanguages.length > 0 && !this.supportedLanguages.includes(userLanguageCode)) {
                    userLanguageCode = this.supportedLanguages[0]; // User language is not supported, consider it as the default language
                }
                // If the user is not on the appropriate language, switch to it
                if (userLanguageCode !== this.locale) {
                    this.switchToLocale(userLanguageCode);
                }
            } else {
                if (this.supportedLanguages.length > 0 && !this.supportedLanguages.includes(this.locale)) {
                    this.switchToLocale(this.supportedLanguages[0]);
                }
            }
        }
    }

    public switchToLocale(locale: string) {
        if (this.baseHref != "/") {
            document.location.href = document.location.href.replace(this.baseHref, `/${locale}/`);
        } else {
            console.warn(`No baseHref detected, cannot switch to user's language (${document.location.href})`);
        }
    }
}
