import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { PointOfInterestCommandDTO } from '../models/point-of-interest/commands/PointOfInterestCommandDTO';
import { PointOfInterestResponseDTO } from '../models/point-of-interest/responses/PointOfInterestResponseDTO';
import { TeammatePointOfInterestResponseDTO } from '../models/point-of-interest/responses/TeammatePointOfInterestResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class PointOfInterestAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Creates a new point of interest.
     * @param event_id id of the event
     * @param body The command used to initialize the new point of interest.
     */
    public create(event_id: string, body: PointOfInterestCommandDTO): Observable<PointOfInterestResponseDTO> {
        return this.httpPost<PointOfInterestResponseDTO>(`/events/${event_id}/points-of-interest`, body);
    }

    /**
     * Lists all points of interest in the specified event.
     * 
     * @param event_id id of the event
     */
    public list(event_id: string): Observable<PointOfInterestResponseDTO[]> {
        return this.getAllSegments(this.listPointsOfInterestSegment.bind(this), event_id);
    }

    private listPointsOfInterestSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<PointOfInterestResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<PointOfInterestResponseDTO>>(`/events/${event_id}/points-of-interest`, { params });
    }

    /**
     * Gets the specified point of interest.
     * 
     * @param id id of the point of interest 
     * @param event_id id of the event
     */
    public get(id: string, event_id: string): Observable<PointOfInterestResponseDTO> {
        return this.httpGet<PointOfInterestResponseDTO>(`/events/${event_id}/points-of-interest/${id}`);
    }   


    /**
     * Updates an existing point of interest.
     * @param id id of the point of interest
     * @param event_id id of the event
     * @param body The command used to update the point of interest.
     */
    public update(id: string, event_id: string, body: PointOfInterestCommandDTO): Observable<PointOfInterestResponseDTO> {
        return this.httpPut<PointOfInterestResponseDTO>(`/events/${event_id}/points-of-interest/${id}`, body);
    }

    /**
     * Deletes the specified point of interest.
     * @param id id of the point of interest
     * @param event_id id of the event
     */
    public delete(id: string, event_id: string, preserve_locations: boolean = false): Observable<void> {
        const params = new HttpParams().append("preserve_locations", preserve_locations.toString());
        return this.httpDelete(`/events/${event_id}/points-of-interest/${id}`, { params });
    }
    
    /**
     * Lists all points of interest in the specified event.
     * 
     * @param event_id id of the event
     */
    public listTeammate(event_id: string): Observable<TeammatePointOfInterestResponseDTO[]> {
        return this.getAllSegments(this.listTeammatePointsOfInterestSegment.bind(this), event_id);
    }

    private listTeammatePointsOfInterestSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<TeammatePointOfInterestResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<TeammatePointOfInterestResponseDTO>>(`/events/${event_id}/points-of-interest/teammate`, { params });
    }
}
