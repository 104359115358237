import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'image-dialog',
    templateUrl: './image-dialog.component.html',
    styleUrls: ['./image-dialog.component.scss']
})
export class ImageDialogComponent implements OnInit {

    constructor(@Inject(MAT_DIALOG_DATA) public data: ImageDialogData) { }

    ngOnInit(): void {
    }

}

export interface ImageDialogData {
    imageUrl: string;
}