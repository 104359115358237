import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LanguageService } from '../shared/services/language-service/language.service';
import { NavigationCustomOrigin } from '../shared/models/common/navigation-origin';
import { AuthService } from 'projects/api-client/src/public-api';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService,
        private languageService: LanguageService,
        private router: Router) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
        let url: string = state.url;

        try {
            await this.authService.trySignIn();
            this.languageService.checkUserLanguage();
            return true;
        }
        catch (e) {
            let signinUrl = "/auth/signin";
            if (next?.data?.origin === NavigationCustomOrigin.IB) signinUrl = "/auth/ib/signin"; // Use IB navigation origin?

            return this.router.createUrlTree([signinUrl], { queryParams: { returnUrl: url } });
        }
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(next, state);
    }
}