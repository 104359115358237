import { EventTeammateFieldDefinitionTemplateResponseDTO } from 'projects/api-client/src/models/event-teammate-field-definition-template/responses/EventTeammateFieldDefinitionTemplateResponseDTO';
import { EventTeammateFieldType } from 'projects/api-client/src/models/common/EventTeammateFieldType';
import { OrganizationTeammateFieldDefinitionTemplateResponseDTO } from 'projects/api-client/src/models/organization-teammate-field-definition-template/responses/OrganizationTeammateFieldDefinitionTemplateResponseDTO';

export class TeammateFieldDefinitionTemplate {
    
    id!: string;
    type!: EventTeammateFieldType;
    name!: string;
    choices: string[] = [];
    events_count!: number;
    created_at?: Date;
    updated_at?: Date;

    static from(dto: OrganizationTeammateFieldDefinitionTemplateResponseDTO | EventTeammateFieldDefinitionTemplateResponseDTO) : TeammateFieldDefinitionTemplate {
        const teammateFieldDefinitionTemplate = new TeammateFieldDefinitionTemplate();        
        teammateFieldDefinitionTemplate.id = dto.id;
        teammateFieldDefinitionTemplate.type = dto.type;
        teammateFieldDefinitionTemplate.name = dto.name;
        teammateFieldDefinitionTemplate.choices = dto.choices;
        teammateFieldDefinitionTemplate.events_count = dto.events_count;
        teammateFieldDefinitionTemplate.created_at = dto.created_at ? new Date(dto.created_at):undefined;
        teammateFieldDefinitionTemplate.updated_at = dto.updated_at ? new Date(dto.updated_at):undefined;
        return teammateFieldDefinitionTemplate;
    }
}