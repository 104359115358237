enum LocalTimeStringBrand { };
export type LocalTimeString = string & LocalTimeStringBrand;

export class LocalTime {

    private static TIME_REGEX = /^(?<hour>\d+):(?<minute>\d+):(?<second>\d+)(\.?(?<nanosecond>\d{1,9}))?$/i;

    private _hour: number;
    public get hour(): number { return this._hour; }

    private _minute: number;
    public get minute(): number { return this._minute; }

    private _second: number;
    public get second(): number { return this._second; }

    private _nanosecond: number;
    public get nanosecond(): number { return this._nanosecond; }

    private _isValid: boolean;
    public get isValid(): boolean { return this._isValid; }

    public set(hour: number = 0, minute: number = 0, second: number = 0, nanosecond: number = 0) {
        if (hour >= 0 && hour < 24
            && minute >= 0 && minute < 60
            && second >= 0 && second < 60
            && nanosecond >= 0 && nanosecond < 1000000000) {
            this._hour = hour;
            this._minute = minute;
            this._second = second;
            this._nanosecond = nanosecond;
            this._isValid = true;
        } else this.setInvalid();
    }

    static from(hour: number = 0, minute: number = 0, second: number = 0, nanosecond: number = 0) : LocalTime {
        let result = new LocalTime();
        result.set(hour, minute, second, nanosecond);
        return result;
    }

    static isAfterOrSame(t1: LocalTime, t2: LocalTime) : boolean {
        return t1.hour > t2.hour 
            || (t1.hour === t2.hour && t1.minute >= t2.minute);
    }

    constructor(time?: string|LocalTimeString) {
        if (time) {
            this.parse(time)
        } else {
            this._hour = 0;
            this._minute = 0;
            this._second = 0;
            this._nanosecond = 0;
            this._isValid = true;
        }
    }

    toJSON(): string {
        if (this._isValid) {
            return `${this._hour.toString().padStart(2, "0")}:${this._minute.toString().padStart(2, "0")}:${this._second.toString().padStart(2, "0")}.${this._nanosecond.toString().padStart(9, "0")}`;
        } else {
            return "Invalid time";
        }
    }

    public compareTo(time: LocalTime) : number {
        return this.toJSON().localeCompare(time.toJSON());
    }

    private setInvalid() {
        this._hour = 0;
        this._minute = 0;
        this._second = 0;
        this._nanosecond = 0;
        this._isValid = false;
    }

    private parse(time: string|LocalTimeString) {
        const matches = time.match(LocalTime.TIME_REGEX);
        if (matches?.groups) {
            const hour = Number.parseInt(matches.groups.hour);
            const minute = Number.parseInt(matches.groups.minute);
            const second = Number.parseInt(matches.groups.second);
            const nanosecond = matches.groups.nanosecond ? Number.parseInt(matches.groups.nanosecond.padEnd(9, "0")) : 0;
            this.set(hour, minute, second, nanosecond);
        } else {
            this.setInvalid();
        }
    }
}