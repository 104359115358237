import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { EventSettingsPublicationCommandDTO } from '../models/event-settings-publication/commands/EventSettingsPublicationCommandDTO';
import { EventSettingsPublicationResponseDTO } from '../models/event-settings-publication/responses/EventSettingsPublicationResponseDTO';

import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventSettingsPublicationAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the event's publication settings.
     * 
     * @param event_id The event ID.
     */
    public get(event_id: string): Observable<EventSettingsPublicationResponseDTO> {
        return this.httpGet<EventSettingsPublicationResponseDTO>(`/events/${event_id}/settings/publication`);
    }

    /**
     * Update the event's publication settings.
     * 
     * @param event_id The event ID.
     * @param body Command used to update the settings.
     */
    public update(event_id: string, body: EventSettingsPublicationCommandDTO): Observable<EventSettingsPublicationResponseDTO> {
        return this.httpPost<EventSettingsPublicationResponseDTO>(`/events/${event_id}/settings/publication`, body);
    }
}
