import { Component, OnDestroy, OnInit } from "@angular/core";
import { NavigationService, MenuMode } from "src/app/shared/services/navigation-service/navigation.service";
import { OrganizationContextService } from "../../organization/organization-context.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { OrganizationAdmin } from "src/app/shared/models/organization/organization-admin";
import { OrganizationAPIService } from "projects/api-client/src/public-api";
import { ActivatedRoute } from "@angular/router";
import { ClientSettingsService } from "src/app/shared/services/client-settings-service/client-settings.service";

@Component({
    templateUrl: './subscription-expired.component.html'
})
export class SubscriptionExpiredComponent implements OnInit, OnDestroy {
    NavigationService = NavigationService;
    salesEmail: string | undefined;
    subscriptionEndDate: Date | undefined;
    
    constructor(navigationService: NavigationService, 
        private organizationAPIService: OrganizationAPIService,
        clientSettingsService: ClientSettingsService,
        private activatedRoute: ActivatedRoute) {
            this.salesEmail = clientSettingsService.getValueInLocale(clientSettingsService.clientSettings?.sales_email);
            clientSettingsService.clientSettingsChange.subscribe((clientSettings: any) => {
                this.salesEmail = clientSettingsService.getValueInLocale(clientSettings?.sales_email);
            });            
        
            navigationService.setMenu(MenuMode.EMPTY);
            navigationService.showLayout = true;
    }

    async ngOnInit() {
        if(this.activatedRoute.snapshot.queryParams["organizationId"]) {
            const organizationAdmin = OrganizationAdmin.from(await this.organizationAPIService.getAdmin(this.activatedRoute.snapshot.queryParams.organizationId).toPromise());
            this.subscriptionEndDate = organizationAdmin?.subscription_end_date;
        }
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}