import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { EventTeammateFieldDefinitionResponseDTO } from '../models/event-teammate-field-definition/responses/EventTeammateFieldDefinitionResponseDTO';
import { EventTeammateFieldDefinitionUpdateCommandDTO } from '../models/event-teammate-field-definition/commands/EventTeammateFieldDefinitionUpdateCommandDTO';
import { EventTeammateFieldDefinitionCreateCommandDTO } from '../models/event-teammate-field-definition/commands/EventTeammateFieldDefinitionCreateCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class EventTeammateFieldDefinitionAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }    

    /**
     * Update the event's teammate field definition.
     * @param event_id id of the event
     * @param id id of the teammate field definition
     * @param body The command used to update the teammate field definition.
     */
    public update(event_id: string, id: string, body: EventTeammateFieldDefinitionUpdateCommandDTO): Observable<EventTeammateFieldDefinitionResponseDTO> {
        return this.httpPut<EventTeammateFieldDefinitionResponseDTO>(`/events/${event_id}/teammate-field-definitions/${id}`, body);
    }

    public create(event_id: string, body: EventTeammateFieldDefinitionCreateCommandDTO): Observable<EventTeammateFieldDefinitionResponseDTO> {
        return this.httpPost<EventTeammateFieldDefinitionResponseDTO>(`/events/${event_id}/teammate-field-definitions`, body);
    }

    /**
     * Deletes the specified event teammate field definition.
     * @param event_id id of the event
     * @param id id of the teammate field definition
     */
    public delete(event_id: string, id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/teammate-field-definitions/${id}`);
    }

}
