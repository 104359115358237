import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateShiftCommandDTO } from "../models/shift/commands/CreateShiftCommandDTO";
import { UpdateShiftCommandDTO } from '../models/shift/commands/UpdateShiftCommandDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { AssignableShiftResponseDTO } from '../models/shift/responses/AssignableShift/AssignableShiftResponseDTO';
import { ShiftResponseDTO } from '../models/shift/responses/shift/ShiftResponseDTO';
import { ListAssignableCommandDTO } from '../models/shift/commands/ListAssignableCommandDTO';
import { BulkUpdateEventShiftCommandDTO } from '../models/shift/commands/BulkUpdateEventShiftCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class ShiftAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Creates a new shift.
     * @param event_id id of the event
     * @param body The command used to initialize the new shift.
     */
    public create(event_id: string, body: CreateShiftCommandDTO): Observable<ShiftResponseDTO> {
        return this.httpPost<ShiftResponseDTO>(`/events/${event_id}/shifts`, body);
    }

    /**
     * Gets the specified shift.
     * 
     * @param id id of the shift
     * @param event_id id of the event
     */
    public get(id: string, event_id: string): Observable<ShiftResponseDTO> {
        return this.httpGet<ShiftResponseDTO>(`/events/${event_id}/shifts/${id}`);
    }

    /**
     * Updates an existing shift.
     * @param id id of the shift
     * @param event_id id of the event
     * @param body The command used to update the shift.
     */
    public update(id: string, event_id: string, body: UpdateShiftCommandDTO): Observable<ShiftResponseDTO> {
        return this.httpPut<ShiftResponseDTO>(`/events/${event_id}/shifts/${id}`, body);
    }
    
     /**
     * Bulk updates a set of shifts
     * @param event_id id of the event
     * @param body The command used to bulk update shifts.
     */
     public bulkUpdate(event_id: string, body: BulkUpdateEventShiftCommandDTO): Observable<void> {
        return this.httpPost(`/events/${event_id}/shifts/bulk`, body);
    }

    /**
     * Deletes the specified shift.
     * @param id id of the shift
     * @param event_id id of the event
     */
    public delete(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/shifts/${id}`);
    }
    
    private listAssignableSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string, body: ListAssignableCommandDTO): Observable<ResultSegmentDTO<AssignableShiftResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpPost<ResultSegmentDTO<AssignableShiftResponseDTO>>(`/events/${event_id}/shifts/assignable`, body, { params });
    }

    /**
     * Lists all shifts in the specified event that are assignable to all of the specified teammates.
     * 
     * @param event_id id of the event
     * @param body Parameters used to list assignable shifts.
     */
    public listAssignable(event_id: string, body: ListAssignableCommandDTO): Observable<AssignableShiftResponseDTO[]> {
        return this.getAllSegments(this.listAssignableSegment.bind(this), event_id, body);
    }
}
