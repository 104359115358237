import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminLocalizationCommandDTO } from '../models/admin-localization/commands/AdminLocalizationCommandDTO';
import { AdminLocalizationResponseDTO } from '../models/admin-localization/responses/AdminLocalizationResponseDTO';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class AdminLocalizationAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    public list(): Observable<AdminLocalizationResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this));
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<AdminLocalizationResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AdminLocalizationResponseDTO>>(`/admin/localizations`, { params });
    }

    public update(body: AdminLocalizationCommandDTO): Observable<AdminLocalizationResponseDTO> {
        return this.httpPost<AdminLocalizationResponseDTO>(`/admin/localizations`, body);
    }
}
