import { EventTeammateFieldType } from "projects/api-client/src/models/common/EventTeammateFieldType";
import { OrganizationTeammateFieldDefinitionResponseDTO } from "projects/api-client/src/models/organization-teammate-field-definition/responses/OrganizationTeammateFieldDefinitionResponseDTO";

export class OrganizationTeammateFieldDefinition {

    id!: string;
    type!: EventTeammateFieldType;
    name!: string;
    choices: string[] = [];
    position!: number;
    created_at!: Date;
    updated_at!: Date;

    static from(dto: OrganizationTeammateFieldDefinitionResponseDTO) : OrganizationTeammateFieldDefinition {
        const organizationTeammateFieldDefinition = new OrganizationTeammateFieldDefinition();
        organizationTeammateFieldDefinition.id = dto.id;
        organizationTeammateFieldDefinition.type = dto.type;
        organizationTeammateFieldDefinition.name = dto.name;
        organizationTeammateFieldDefinition.choices = dto.choices.slice();
        organizationTeammateFieldDefinition.position = dto.position;
        organizationTeammateFieldDefinition.created_at = new Date(dto.created_at);
        organizationTeammateFieldDefinition.updated_at = new Date(dto.updated_at);
        return organizationTeammateFieldDefinition;
    }
}