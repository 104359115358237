import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { EventTeammateFieldSectionCreateCommandDTO } from '../models/event-teammate-field-section/commands/EventTeammateFieldSectionCreateCommandDTO';
import { EventTeammateFieldSectionUpdateCommandDTO } from '../models/event-teammate-field-section/commands/EventTeammateFieldSectionUpdateCommandDTO';
import { EventTeammateFieldSectionResponseDTO } from '../models/event-teammate-field-section/responses/EventTeammateFieldSectionResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class EventTeammateFieldSectionAPIService extends BaseAPIService {

    constructor(injector: Injector) { 
        super(injector);
    }
  
    public create(eventId: string, body: EventTeammateFieldSectionCreateCommandDTO): Observable<EventTeammateFieldSectionResponseDTO> {
        return this.httpPost<EventTeammateFieldSectionResponseDTO>(`/events/${eventId}/teammate-field-sections`, body);
    }

    public update(eventId: string, id: string, body: EventTeammateFieldSectionUpdateCommandDTO): Observable<EventTeammateFieldSectionResponseDTO> {
        return this.httpPut<EventTeammateFieldSectionResponseDTO>(`/events/${eventId}/teammate-field-sections/${id}`, body);
    }

    public delete(eventId: string, id: string): Observable<void> {
        return this.httpDelete(`/events/${eventId}/teammate-field-sections/${id}`);
    }

    /**
     * List all teammate field sections in the specified event.
     */
     public list(event_id: string): Observable<EventTeammateFieldSectionResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), event_id);
    } 

    private listSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<EventTeammateFieldSectionResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventTeammateFieldSectionResponseDTO>>(`/events/${event_id}/teammate-field-sections`, { params });
    }
}
