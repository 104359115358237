import { CommonModule } from '@angular/common';
import { AfterContentInit, AfterViewChecked, Component, ElementRef, EventEmitter, Inject, Input, LOCALE_ID, Output, QueryList, Renderer2, ViewChildren, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCalendar, MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import * as moment from 'moment';
import { LocalDate, LocalDateString } from 'projects/api-client/src/types/LocalDate';

@Component({
    selector: 'calendar-month-slot',
    templateUrl: './calendar-month-slot.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: CalendarMonthSlotComponent,
            multi: true
        }
    ],
    standalone: true,
    encapsulation: ViewEncapsulation.None,
    imports: [CommonModule, MatCardModule, MatDatepickerModule, MatButtonModule, MatTooltipModule, MatMenuModule]
})
export class CalendarMonthSlotComponent implements ControlValueAccessor, AfterViewChecked, AfterContentInit {
    _selectedDays: LocalDate[] = [];
    get selectedDays(): LocalDate[] {
        return this._selectedDays;
    }
    @Input() set selectedDays(value: LocalDate[]) {
        this._selectedDays = value;
        if (this.calendars) {
            for (let calendar of this.calendars.toArray()) calendar.updateTodaysDate();
        }
    }

    @Input() startDate: moment.Moment = moment();
    @Output() startDateChange = new EventEmitter<moment.Moment>();

    minNumberOfMonth: number = 1;
    maxNumberOfMonth: number = 6;

    _numberOfMonth: number = 1;
    get numberOfMonth(): number {
        return this._numberOfMonth;
    }
    @Input() set numberOfMonth(value: number) {
        if (value >= this.minNumberOfMonth && value <= this.maxNumberOfMonth) {
            if (this.monthSlots.length > 0) {
                this.addMonths(value - this._numberOfMonth);
            }
            this._numberOfMonth = value;
            this.numberOfMonthChange.emit(this._numberOfMonth);
        }
    }
    @Output() numberOfMonthChange = new EventEmitter<number>();

    @Input() readOnly: boolean = false;
    @Input() availableDates?: LocalDate[] = undefined;

    @ViewChildren('calendar') calendars!: QueryList<MatCalendar<moment.Moment>>;
    monthSlots: MonthSlot[] = [];

    @Input() required: boolean = false;
    @Input() invalid: boolean = false;

    constructor(private el: ElementRef, private renderer: Renderer2, @Inject(LOCALE_ID) private locale: string) {
    }

    ngAfterContentInit(): void {
        this.monthSlots = [];
        if (!this.availableDates) {
            this.addMonths(this.numberOfMonth);
        }
        else {
            this.addStaticMonths();
        }
        this.startDate = this.monthSlots[0].minDate.clone();
    }

    addStaticMonths() {

        if (this.availableDates && this.availableDates.length > 0) {
            this.availableDates = this.availableDates.sort((a, b) => moment(a.toJSON()).diff(moment(b.toJSON())));
            for (let d of this.availableDates) {
                const minDate = moment(d.toJSON()).clone().startOf('month');
                const maxDate = moment(d.toJSON()).clone().endOf('month');
                if (this.monthSlots.length == 0 || this.monthSlots.length > 0 && this.monthSlots[this.monthSlots.length - 1].minDate.format('YYYY-MM-DD') != minDate.format('YYYY-MM-DD')) {
                    this.monthSlots.push({
                        monthLabel: minDate.format('MMM YYYY'),
                        minDate: minDate,
                        maxDate: maxDate,
                        enableSelectAll: this.canSelectAll(minDate, maxDate)
                    });
                }
            }
        }
        else {
            this.addMonths(1);
        }
    }

    addMonths(nbOfMonth: number) {
        if (nbOfMonth >= 0) {
            for (let i = 0; i < nbOfMonth; i++) {
                const minDate = this.monthSlots.length > 0 ? this.monthSlots[this.monthSlots.length - 1]?.minDate?.clone().startOf('month').add(1, 'month') : this.startDate.startOf('month');
                const maxDate = minDate.clone().endOf('month');

                this.monthSlots.push({
                    monthLabel: minDate.format('MMM YYYY'),
                    minDate: minDate,
                    maxDate: maxDate,
                    enableSelectAll: this.canSelectAll(minDate, maxDate)
                });
            }
        }
        else {
            for (let i = 0; i > nbOfMonth; i--) {
                this.monthSlots.pop();
            }
        }
    }

    previousMonth() {
        const minDate = this.startDate.clone().startOf('month').add(-1, 'month');
        const maxDate = minDate.clone().add(1, 'month').add(-1, 'day');
        this.monthSlots.unshift({
            monthLabel: minDate.format('MMM YYYY'),
            minDate: minDate,
            maxDate: maxDate,
            enableSelectAll: this.canSelectAll(minDate, maxDate)
        });
        this.addMonths(-1);
        this.startDate = moment(this.monthSlots[0].minDate);
        this.startDateChange.emit(this.startDate);
    }

    nextMonth() {
        this.addMonths(1);
        this.monthSlots.shift();
        this.startDate = this.monthSlots[0].minDate;
        this.startDateChange.emit(this.startDate);
    }

    previousSelectedDatesExist() {
        return this.selectedDays.filter(x => moment(x.toJSON()).isBefore(this.startDate)).length > 0;
    }

    nextSelectedDatesExist() {
        return this.selectedDays.filter(x => moment(x.toJSON()).isAfter(this.monthSlots[this.monthSlots.length - 1]?.maxDate)).length > 0;
    }


    ngAfterViewChecked() {
        let selectedElement = this.el.nativeElement.querySelectorAll('.selected:not(.mat-calendar-body-disabled)');
        for (let element of selectedElement) {
            this.renderer.addClass(element.querySelector('.mat-calendar-body-cell-content'), 'mat-calendar-body-selected');
        }
    }

    isSelected = (event: any) => {
        return this.selectedDays.find(x => x.toJSON() == event.format('YYYY-MM-DD')) ? "selected" : "";
    }

    select(selectedDay: moment.Moment) {
        const index = this.selectedDays.findIndex(x => x.toJSON() == moment(selectedDay).format('YYYY-MM-DD'));
        if (index > -1) {
            this.selectedDays.splice(index, 1);
        }
        else {
            this.selectedDays.push(LocalDate.from(selectedDay.format('YYYY-MM-DD') as LocalDateString));
        }
        for (let monthSlot of this.monthSlots) monthSlot.enableSelectAll = this.canSelectAll(monthSlot.minDate, monthSlot.maxDate);
        for (let calendar of this.calendars.toArray()) calendar.updateTodaysDate();
        this.onChange(this.selectedDays);
    }

    selectAll(monthSlot: MonthSlot) {
        const selectedDaysTemp = [...this.selectedDays];
        const date: moment.Moment = monthSlot.minDate.clone();
        while (date.isSameOrBefore(monthSlot.maxDate)) {
            if (!selectedDaysTemp.find(x => x.toJSON() == LocalDate.from(moment(date).format('YYYY-MM-DD') as LocalDateString).toJSON()) && (!this.availableDates ||
                this.availableDates && this.availableDates.find(x => x.toJSON() == LocalDate.from(moment(date).format('YYYY-MM-DD') as LocalDateString).toJSON()))) {
                const datetoAdd = LocalDate.from(date.format('YYYY-MM-DD') as LocalDateString)
                selectedDaysTemp.push(datetoAdd);
            }
            date.add(1, 'days');
        }
        monthSlot.enableSelectAll = false;
        this.selectedDays = selectedDaysTemp;
        this.onChange(this.selectedDays);
    }

    unselectAll(monthSlot: MonthSlot) {
        const selectedDaysTemp: LocalDate[] = [];
        for (let selectedDay of this.selectedDays) {
            if (moment(selectedDay.toJSON()).isBefore(monthSlot.minDate) || moment(selectedDay.toJSON()).isAfter(monthSlot.maxDate) ||
                this.availableDates && !this.availableDates.find(x => x.toJSON() == selectedDay.toJSON())) {
                selectedDaysTemp.push(selectedDay);
            }
        }
        monthSlot.enableSelectAll = true;
        this.selectedDays = selectedDaysTemp;
        this.onChange(this.selectedDays);
    }

    canSelectAll(minDate: moment.Moment, maxDate: moment.Moment): boolean | undefined {
        if (!this.availableDates) {
            return !(this.selectedDays.filter(x => moment(x.toJSON()).isSameOrAfter(minDate) && moment(x.toJSON()).isSameOrBefore(maxDate)).length == moment(minDate).daysInMonth());
        }
        let availableDatesInRange = this.availableDates.filter(x => moment(x.toJSON()).isSameOrAfter(minDate) && moment(x.toJSON()).isSameOrBefore(maxDate));
        if (availableDatesInRange.length > 0) {
            return !(this.selectedDays.filter(x => moment(x.toJSON()).isSameOrAfter(minDate) && moment(x.toJSON()).isSameOrBefore(maxDate) && availableDatesInRange.find(y => y.toJSON() == x.toJSON())).length == availableDatesInRange.length);
        }
        else {
            return undefined;
        }
    }

    availableDatesFilter = (date: moment.Moment): boolean => {
        return this.availableDates ?
            this.availableDates.find(x => x.toJSON() == date.format('YYYY-MM-DD')) != undefined : true;
    }

    onChange: (_: any) => void = (_: any) => { };
    onTouched: () => void = () => { };

    writeValue(value: LocalDate[]) {
        this.selectedDays = value ? value : [];
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }
}

export interface MonthSlot {
    monthLabel: string,
    minDate: moment.Moment,
    maxDate: moment.Moment,
    enableSelectAll?: boolean
}
