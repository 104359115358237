import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminOrganizationCommandDTO } from '../models/admin-organization/commands/AdminOrganizationCommandDTO';
import { AdminOrganizationResponseDTO } from '../models/admin-organization/responses/AdminOrganizationResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';

@Injectable({
    providedIn: 'root'
})
export class AdminOrganizationAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }
    
    private listOrganizationsSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<AdminOrganizationResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AdminOrganizationResponseDTO>>(`/admin/organizations`, { params });
    }

    /**
     * Lists all organizations on the platform.
     */
    public list(): Observable<AdminOrganizationResponseDTO[]> {
        return this.getAllSegments(this.listOrganizationsSegment.bind(this));
    }

    /**
     * Updates the specified organization.
     * @param organization_id id of the organization
     * @param body The command used to initialize the new teammate.
     */
    public update(organization_id: string, body: AdminOrganizationCommandDTO): Observable<AdminOrganizationResponseDTO> {
        return this.httpPut<AdminOrganizationResponseDTO>(`/admin/organizations/${organization_id}`, body);
    }

    /**
     * Deletes the specified organizations.
     * @param organization_id id of the organization
     */
    public delete(organization_id: string): Observable<void> {
        return this.httpDelete(`/admin/organizations/${organization_id}`);
    }
}
