<footer class="block overflow-hidden px-4 md:px-6 py-1.5 bg-zinc-100 dark:bg-zinc-800" role="contentinfo">
        <div class="text-xs/tight flex flex-wrap gap-x-3 md:gap-x-4 gap-y-1 justify-center">
                <a class="no-underline opacity-60 hover:opacity-100 transition-all" target="_blank" href="https://www.quick-off.com/en/qoezion" i18n-href i18n>Powered by Qoezion</a>
                @if (termsUrl !== undefined) {
                        <a class="no-underline opacity-60 hover:opacity-100 transition-all" target="_blank" [href]="termsUrl" i18n>Terms and conditions</a>
                }
                @if (privacyUrl !== undefined) {
                        <a class="no-underline opacity-60 hover:opacity-100 transition-all" target="_blank" [href]="privacyUrl" i18n>Privacy</a>
                }
        </div>
</footer>