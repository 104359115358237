import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation-service/navigation.service';

@Component({
    selector: 'app-access-denied',
    templateUrl: './access-denied.component.html',
    styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {
    
    NavigationService = NavigationService;
    
    constructor() { }

    ngOnInit(): void {
    }
}
