import { Address } from '../../models/common/address';
import PlacesAutocompleteService = google.maps.places.AutocompleteService;
import PlacesService = google.maps.places.PlacesService;
import PlaceResult = google.maps.places.PlaceResult;
import AutocompletePrediction = google.maps.places.AutocompletePrediction;

export class GeolocationService {

    placesAutocompleteService: PlacesAutocompleteService;
    placeService: PlacesService;
    autocompleteSessionToken: google.maps.places.AutocompleteSessionToken;

    constructor() {
        let map = new google.maps.Map(document.createElement('div')); // Needed to use google places service
        this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
        this.placesAutocompleteService = new google.maps.places.AutocompleteService();
        this.placeService = new google.maps.places.PlacesService(map);
    }

    public refreshPredictions(query: string, searchScopes: PLACE_SEARCH_SCOPE[]): Promise<AutocompletePrediction[]> {
        let promise = new Promise<AutocompletePrediction[]>((resolve) => {
            if (query) {
                this.placesAutocompleteService.getPlacePredictions({ input: query, types: searchScopes, sessionToken: this.autocompleteSessionToken }, (results, status) => {
                    if (status === google.maps.places.PlacesServiceStatus.OK) {
                        resolve(results ?? [])
                    } else { resolve([]) }
                });
            } else resolve([]);
        });
        return promise;
    }

    public async getPredictionDetails(placeId: string): Promise<Address | undefined> {
        let promise = new Promise<Address | undefined>((resolve, reject) => {
            this.placeService.getDetails({
                placeId: placeId,
                fields: ["address_components", "geometry"],
                sessionToken: this.autocompleteSessionToken
            }, (place, status) => {
                this.autocompleteSessionToken = new google.maps.places.AutocompleteSessionToken();
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    let address: Address | undefined = new Address();
                    if (place) {
                        address = this.buildAddress(place);
                    }
                    resolve(address);
                } else {
                    reject(status);
                }
            });
        });

        return promise;
    }

    private buildAddress(place: PlaceResult): Address | undefined {
        if (place && place.address_components) {
            const address = new Address();
            address.line1 = [
                place.address_components?.find(x => x.types.includes("street_number"))?.long_name,
                place.address_components?.find(x => x.types.includes("route"))?.long_name
            ].join(" ");
            address.postal_code = place.address_components?.find(x => x.types.includes("postal_code"))?.short_name;
            address.city = place.address_components?.find(x => x.types.includes("locality"))?.long_name;
            if (!address.city) address.city = place.address_components?.find(x => x.types.includes("political") && !x.types.includes("sublocality"))?.long_name;
            address.state = place.address_components?.find(x => x.types.includes("administrative_area_level_1"))?.long_name;
            address.country = place.address_components?.find(x => x.types.includes("country"))?.short_name;
            address.latitude = place.geometry?.location?.lat();
            address.longitude = place.geometry?.location?.lng();
            return address;
        } else {
            return undefined;
        }
    }

    public async getCurrentLocation(): Promise<Address | undefined> {
        try {
            const position = await this.getCurrentCoords();
            if (position && position.coords) {
                return { line1: $localize`Around me`, latitude: position.coords.latitude, longitude: position.coords.longitude };
            }
            return;
        }
        catch { return undefined }
    }

    // Get current coordinates of user
    private getCurrentCoords(): Promise<GeoLocation> {
        return new Promise<GeoLocation>((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Not supported browser");
            }
            navigator.geolocation.getCurrentPosition((position) => {
                resolve(position);
            }, (error) => { reject(error) });
        });
    }

    public static getDistanceBetweenPoints(lat1: number, lng1: number, lat2: number, lng2: number) {
        var R = 6371.0710; // Radius of the Earth in km
        var rlat1 = lat1 * (Math.PI / 180); // Convert degrees to radians
        var rlat2 = lat2 * (Math.PI / 180); // Convert degrees to radians
        var difflat = rlat2 - rlat1; // Radian difference (latitudes)
        var difflon = (lng2 - lng1) * (Math.PI / 180); // Radian difference (longitudes)

        var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
        return d;
    }
}

export interface GeoLocation {
    coords?: {
        latitude: number;
        longitude: number;
    }
}

export enum PLACE_SEARCH_SCOPE {
    CITIES = "(cities)",
    REGIONS = "(regions)",
    GEOCODE = "geocode",
    FULL_ADDRESS = "address"
}