import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiClientConfiguration } from '../configuration';
import { CouponResponseDTO } from '../models/coupon/responses/CouponResponseDTO';
import { ConsumeCouponCommandDTO } from '../models/coupon/commands/ConsumeCouponCommandDTO';
import { CreateCouponCommandDTO } from '../models/coupon/commands/CreateCouponCommandDTO';
import { UpdateCouponCommandDTO } from '../models/coupon/commands/UpdateCouponCommandDTO';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { BaseAPIService } from './base.service';
import { PublicCouponResponseDTO } from '../models/coupon/responses/PublicCouponResponseDTO';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class CouponAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the coupon's details.
     * 
     * @param code coupon's code
     */
    public get(code: string): Observable<PublicCouponResponseDTO> {
        return this.httpGet<PublicCouponResponseDTO>(`/coupons/read/${code}`);
    }

    /**
     * Creates a new coupon.
     * @param body The command used to initialize the new coupon.
     */
    public create(body: CreateCouponCommandDTO): Observable<CouponResponseDTO> {
        return this.httpPost<CouponResponseDTO>(`/coupons`, body);
    }

    /**
     * Updates the specified coupon.
     * @param id id of the coupon
     * @param body The command used to update the coupon.
     */
    public update(id: string, body: UpdateCouponCommandDTO): Observable<CouponResponseDTO> {
        return this.httpPut<CouponResponseDTO>(`/coupons/${id}`, body);
    }

    /**
     * Deletes the specified coupon.
     * @param id id of the coupon
     */
    public delete(id: string): Observable<void> {
        return this.httpDelete(`/coupons/${id}`);
    }

    private listCouponsByOrganizationSegment(segment_index: number|undefined, query_token: string|undefined, organization_id: string): Observable<ResultSegmentDTO<CouponResponseDTO>> {
        let params = new HttpParams();
        params = params.append('organization_id', organization_id);
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<CouponResponseDTO>>(`/coupons/by-organization`, { params });
    }

    /**
     * Lists all coupons belonging to the specified organization.
     * 
     * @param organization_id id of the organization
     */
    public listByOrganization(organization_id: string): Observable<CouponResponseDTO[]> {
        return this.getAllSegments(this.listCouponsByOrganizationSegment.bind(this), organization_id);
    }
}
