import { AttachmentDTO as ActivityAttachmentDTO } from "projects/api-client/src/models/activity/responses/AttachmentDTO";
import { AttachmentDTO as ShiftAttachmentDTO} from "projects/api-client/src/models/shift/responses/AttachmentDTO";

export class Attachment {

    constructor(
        public id?: string,
        public asset_key?: string,
        public thumbnail_asset_key?: string) { }

    static from(dto: ActivityAttachmentDTO | ShiftAttachmentDTO) {
        const attachment = new Attachment();
        attachment.id = dto.id;
        attachment.asset_key = dto.asset_key;
        attachment.thumbnail_asset_key = dto.thumbnail_asset_key;
        return attachment;
    }

    get filename(): string | undefined {
        if (!this.asset_key) return undefined;
        else {
            const urlSegments = this.asset_key.split("/");
            return urlSegments[urlSegments.length - 1];
        }
    }

    get extension(): string | undefined {
        if (!this.filename) return undefined;
        else {
            const nameParts = this.filename!.split(".");
            return nameParts[nameParts.length - 1].toLowerCase();
        }
    }

    get isImage(): boolean {
        if (!this.extension) return false;
        else return ['jpg', 'jpeg', 'gif', 'png', 'webp'].includes(this.extension);
    }
}