import { Component } from '@angular/core';

@Component({
    templateUrl: './maintenance.component.html',
    styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent {

    constructor() { }

}
