export class SmsCounterService {

    private static readonly GSM7_CHARACTER_SET = Array.from('@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !\"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà');
    private static readonly GSM7_EXTENSION_CHARACTER_SET = Array.from('^{}\\[~]|€');
    private static readonly GSM7_SMS_THRESHOLDS = [160, 306, 459, 612, 765];
    private static readonly UTF16_SMS_THRESHOLDS = [70, 134, 201, 268, 335];

    constructor() { }

    static getSmsCount(text: string): number {
        
        if (!text) {
            return 0;
        }

        let smsThresholds: number[];
        let charactersCount: number;
        if (this.isGSM7String(text)) {
            smsThresholds = SmsCounterService.GSM7_SMS_THRESHOLDS;
            // GSM7 extension characters are encoded using 2 symbols
            charactersCount = text.length + Array.from(text).filter(c => SmsCounterService.GSM7_EXTENSION_CHARACTER_SET.includes(c)).length;
        }
        else {
            smsThresholds = SmsCounterService.UTF16_SMS_THRESHOLDS;
            charactersCount = text.length;
        }

        for (let smsCount = 1; smsCount <= smsThresholds.length; smsCount++) {
            const threshold = smsThresholds[smsCount - 1];
            if (charactersCount <= threshold) {
                return smsCount;
            }
        }

        return -1;
    }

    static isGSM7String(text: string): boolean {
        return Array.from(text).filter(c => !SmsCounterService.GSM7_CHARACTER_SET.includes(c) && !SmsCounterService.GSM7_EXTENSION_CHARACTER_SET.includes(c)).length === 0;
    }
}