import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/shared/services/navigation-service/navigation.service';

@Component({
    template: ""
})
export class HomeRedirectComponent {
    constructor(router: Router,
        navigationService: NavigationService) {
        const settings = navigationService.UserSettings;

        if(settings?.homeRedirectionContext?.eventId) {
            router.navigate(NavigationService.EventRoutes.Home(settings.homeRedirectionContext.eventId), 
            {
                queryParams: { fbk: "true" }, // fallback to profile in case of 403/404
                replaceUrl: true 
            });
        }
        else if(settings?.homeRedirectionContext?.organizationId) {
            router.navigate(NavigationService.OrganizationRoutes.Home(settings.homeRedirectionContext.organizationId), 
            { 
                queryParams: { fbk: "true" }, // fallback to profile in case of 403/404
                replaceUrl: true 
            });
        }
        else router.navigate(NavigationService.AccountRoutes.Profile(), { replaceUrl: true });
    }
}