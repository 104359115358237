import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdminUserResponseDTO } from '../models/admin-user/responses/AdminUserResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { AdminUserSummaryResponseDTO } from '../models/admin-user/responses/AdminUserSummaryResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class AdminUserAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the specified admin user.
     *
     * @param id id of the user
     * @param event_id id of the event
     */
    public get(id: string): Observable<AdminUserResponseDTO> {
        return this.httpGet<AdminUserResponseDTO>(`/admin/users/${id}`);
    }

    private listUsersSegment(segment_index: number | undefined, query_token: string | undefined): Observable<ResultSegmentDTO<AdminUserSummaryResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AdminUserResponseDTO>>(`/admin/users`, { params });
    }

    /**
     * Lists all detailed users on the platform.
     */
    public listUsers(): Observable<AdminUserSummaryResponseDTO[]> {
        return this.getAllSegments(this.listUsersSegment.bind(this));
    }

    public disable2FA(user_id: string): Observable<void> {
        return this.httpPost<void>(`/admin/users/${user_id}/2fa/disable`, undefined);
    }
    
    public deleteUser(user_id: string): Observable<void> {
        return this.httpDelete(`/admin/users/${user_id}`);
    }
}
