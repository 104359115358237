import { HttpClient, HttpContext, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, EMPTY, from, of } from 'rxjs';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { combineAll, expand, map, mergeMap, reduce } from 'rxjs/operators';
import { ApiClientConfiguration, API_CLIENT_CONFIGURATION } from '../configuration';
import { Injector, LOCALE_ID } from '@angular/core';
import { ApiClientHttpClient } from '../http-client';
import { catchOffline } from '@ngx-pwa/offline';

interface BaseAPIHTTPOptions {
    params?: HttpParams;
    withCredentials?: boolean;
    ignoreConnectivityCheck?: boolean;
    context?: HttpContext;
}

export abstract class BaseAPIService {

    private _httpClient: HttpClient;
    private get httpClient(): HttpClient {
        if (!this._httpClient) this._httpClient = this.injector.get(ApiClientHttpClient);
        return this._httpClient;
    }

    private _configuration: ApiClientConfiguration;
    private get configuration(): ApiClientConfiguration {
        if (!this._configuration) this._configuration = this.injector.get(API_CLIENT_CONFIGURATION);
        return this._configuration;
    }

    private _locale: string;

    constructor(private injector: Injector) {
        this._locale = injector.get(LOCALE_ID);
    }

    protected httpGet<T>(relativeUrl: string, options?: BaseAPIHTTPOptions, observe?: 'body'): Observable<T>;
    protected httpGet<T>(relativeUrl: string, options?: BaseAPIHTTPOptions, observe?: 'response'): Observable<HttpResponse<T>>;
    protected httpGet<T>(relativeUrl: string, options?: BaseAPIHTTPOptions, observe: any = 'body'): Observable<any> {

        let headers = new HttpHeaders();
        headers = headers.set('Accept-Language', this._locale);

        const result = this.httpClient.get<T>(`${this.configuration.basePath}${relativeUrl}`, {
            headers,
            params: options?.params,
            context: options?.context,
            withCredentials: options?.withCredentials
        });

        return options?.ignoreConnectivityCheck === true ? result : result.pipe(catchOffline());
    };

    protected httpPost<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe?: 'body'): Observable<T>;
    protected httpPost<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe?: 'response'): Observable<HttpResponse<T>>;
    protected httpPost<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe: any = 'body'): Observable<any> {

        let headers = new HttpHeaders();
        headers = headers.set('Accept-Language', this._locale);
        headers = headers.set('Content-Type', 'application/json');

        const result = this.httpClient.post<T>(`${this.configuration.basePath}${relativeUrl}`, body, {
            headers,
            observe,
            params: options?.params,
            context: options?.context,
            withCredentials: options?.withCredentials
        });

        return options?.ignoreConnectivityCheck === true ? result : result.pipe(catchOffline());
    };

    protected httpPatch<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe?: 'body'): Observable<T>;
    protected httpPatch<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe?: 'response'): Observable<HttpResponse<T>>;
    protected httpPatch<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe: any = 'body'): Observable<any> {

        let headers = new HttpHeaders();
        headers = headers.set('Accept-Language', this._locale);
        headers = headers.set('Content-Type', 'application/json');

        const result = this.httpClient.patch<T>(`${this.configuration.basePath}${relativeUrl}`, body, {
            headers,
            observe,
            params: options?.params,
            context: options?.context,
            withCredentials: options?.withCredentials
        });

        return options?.ignoreConnectivityCheck === true ? result : result.pipe(catchOffline());
    };

    protected httpPut<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe?: 'body'): Observable<T>;
    protected httpPut<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe?: 'response'): Observable<HttpResponse<T>>;
    protected httpPut<T>(relativeUrl: string, body: any, options?: BaseAPIHTTPOptions, observe: any = 'body'): Observable<any> {

        let headers = new HttpHeaders();
        headers = headers.set('Accept-Language', this._locale);
        headers = headers.set('Content-Type', 'application/json');

        const result = this.httpClient.put<T>(`${this.configuration.basePath}${relativeUrl}`, body, {
            headers,
            observe,
            params: options?.params,
            context: options?.context,
            withCredentials: options?.withCredentials
        });

        return options?.ignoreConnectivityCheck === true ? result : result.pipe(catchOffline());
    };

    protected httpDelete<T>(relativeUrl: string, options?: BaseAPIHTTPOptions, observe?: 'body'): Observable<T>;
    protected httpDelete<T>(relativeUrl: string, options?: BaseAPIHTTPOptions, observe?: 'response'): Observable<HttpResponse<T>>;
    protected httpDelete<T>(relativeUrl: string, options?: BaseAPIHTTPOptions, observe: any = 'body'): Observable<any> {

        let headers = new HttpHeaders();
        headers = headers.set('Accept-Language', this._locale);
        headers = headers.set('Content-Type', 'application/json');

        const result = this.httpClient.delete<T>(`${this.configuration.basePath}${relativeUrl}`, {
            headers,
            observe,
            params: options?.params,
            context: options?.context,
            withCredentials: options?.withCredentials
        })

        return options?.ignoreConnectivityCheck === true ? result : result.pipe(catchOffline());
    };

    protected getAllSegments<T>(listItems: (segment_index: number | undefined, query_token: string | undefined, ...args: any[]) => Observable<ResultSegmentDTO<T>>, ...args: any[]): Observable<T[]> {        
        return listItems(0, undefined, ...args)
            .pipe(
                expand(segment => {
                    if((segment.segment_index + 1) >= segment.segments_count) return EMPTY;
                    else {
                        let listNextSegments: Observable<ResultSegmentDTO<T>>[] = [];
                        for(let i=1;i<segment.segments_count;i++) {
                            listNextSegments.push(listItems(i, segment.query_token, ...args));
                        }
                        return from(listNextSegments).pipe(
                            mergeMap(x => x, 3),
                            reduce((aggregatedResult, currentSegment) => {
                                aggregatedResult.items.push(...currentSegment.items);
                                return aggregatedResult;
                            }, { 
                                items: segment.items,
                                segment_index: 0,
                                segments_count: 0 
                            } as ResultSegmentDTO<T>)
                        );
                    }
                }),
                map(x => x.items)
            )
    }
}