import { EventAPIService } from 'projects/api-client/src/public-api';
import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { Event } from 'src/app/shared/models/event/event';
import { EventAdmin } from 'src/app/shared/models/event/event-admin';
import { EventPermission } from 'projects/api-client/src/models/common/EventPermission';
import { EventTeammateFieldSectionAPIService } from 'projects/api-client/src/api/event-teammate-field-section.service';
import { EventTeammateFieldSection } from 'src/app/shared/models/event-teammate-field-section/event-teammate-field-section';

@Injectable({
    providedIn: 'root'
})
export class EventContextService {

    public get event(): Event | undefined { return this.eventContextBS.getValue().event; }
    public get eventAdmin(): EventAdmin | undefined { return this.eventContextBS.getValue().eventAdmin; }
    public get eventAdminTeammateFieldSections(): EventTeammateFieldSection[] | undefined { return this.eventContextBS.getValue().eventAdminTeammateFieldSections; }
    
    protected eventContextBS = new BehaviorSubject<{
        event?: Event,
        eventAdmin?: EventAdmin,
        eventAdminTeammateFieldSections?: EventTeammateFieldSection[]
    }>({});
    public onEventContextChanged = this.eventContextBS.asObservable();

    constructor(
        private eventAPIService: EventAPIService, 
        private eventTeammateFieldSectionAPIService: EventTeammateFieldSectionAPIService
    ) { }

    async loadEvent(eventId: string) {
        const event = Event.from(await this.eventAPIService.get(eventId).toPromise());
        let eventAdmin: EventAdmin | undefined = undefined;
        let eventAdminTeammateFieldSections: EventTeammateFieldSection[] | undefined = undefined;
        if (event.user_event_permissions.includes(EventPermission.ADMIN_ACCESS)) {
            const [eventAdminResponse, eventAdminTeammateFieldSectionsResponse] = await Promise.all([
                this.eventAPIService.getAdmin(event.id).toPromise(),
                this.eventTeammateFieldSectionAPIService.list(event.id).toPromise()
            ])
            eventAdmin = EventAdmin.from(eventAdminResponse);
            eventAdminTeammateFieldSections = eventAdminTeammateFieldSectionsResponse.map(etfs => EventTeammateFieldSection.from(etfs));
        }
        this.eventContextBS.next({ event, eventAdmin, eventAdminTeammateFieldSections });
    }

    async refresh() {
        if (this.event) {
            await this.loadEvent(this.event.id);
        }
    }

    public get applicationFormUrl(): string | undefined {
        if (this.event) {
            return `${location.origin}/go/events/${this.event.id}`;
        } else {
            return undefined;
        }
    }
}