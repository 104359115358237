import { Injectable } from "@angular/core";
import { StringListEditorIntl } from "@qo/commons";

@Injectable()
export class StringListEditorIntlService extends StringListEditorIntl {
    constructor() {
        super();

        this.add = $localize`Add choice`;
        this.removeAll = $localize`Remove all`;
        this.removeTooltip = $localize`Remove`;
        this.getTooManyItemsPasted = (maxItems: number) : string => $localize`You cannot paste more than ${maxItems} items in a row`;
        this.getChoiceText = (index: number) : string => $localize`Option ${index+1}`;

        this.changes.next();
    }
}
