import { Injectable, Injector } from '@angular/core';
import { HttpContext, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { EventResponseDTO } from '../models/event/responses/EventResponseDTO';
import { EventMainSettingsCommandDTO } from '../models/event/commands/EventMainSettingsCommandDTO';
import { EventAdminResponseDTO } from '../models/event/responses/EventAdminResponseDTO';
import { ByPassAuthorizationContextToken } from '../auth/bearer.interceptor';
import { EventCreateCommandDTO } from '../models/event/commands/EventCreateCommandDTO';
import { EventOrganizationResponseDTO } from '../models/event/responses/EventOrganizationResponseDTO';
import { EventPublicResponseDTO } from '../models/event/responses/EventPublicResponseDTO';
import { EventDuplicateCommandDTO } from '../models/event/commands/EventDuplicateCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class EventAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    public duplicate(event_id: string, body: EventDuplicateCommandDTO): Observable<EventAdminResponseDTO> {
        return this.httpPost<EventAdminResponseDTO>(`/events/${event_id}/duplicate`, body);
    }
    
    public create(body: EventCreateCommandDTO): Observable<EventAdminResponseDTO> {
        return this.httpPost<EventAdminResponseDTO>(`/events`, body);
    }

    /**
     * Gets the event's relationship to the current user.
     * 
     * @param event_id The event ID.
     */
    public get(event_id: string, anonymous = false): Observable<EventResponseDTO> {
        return this.httpGet<EventResponseDTO>(`/events/${event_id}`, {
            context: new HttpContext().set(ByPassAuthorizationContextToken, anonymous)
        });
    }

    /**
     * Deletes the specified event and all associated entities.
     * @param id The event ID.
     */
    public delete(id: string): Observable<void> {
        return this.httpDelete(`/events/${id}`);
    }

    /**
     * Gets the event's admin dashboard infos.
     * 
     * @param event_id The event ID.
     */
    public getAdmin(event_id: string): Observable<EventAdminResponseDTO> {
        return this.httpGet<EventAdminResponseDTO>(`/events/${event_id}/settings/admin`);
    }

    /**
     * Update the event's main settings.
     * @param event_id The event ID.
     * @param body Command used to update the settings.
     */
    public updateMainSettings(event_id: string, body: EventMainSettingsCommandDTO): Observable<EventAdminResponseDTO> {
        return this.httpPost<EventAdminResponseDTO>(`/events/${event_id}/settings/main`, body);
    }

    /**
     * Lists all events associated to the current user.
     */
    public listEventsByUser(): Observable<EventResponseDTO[]> {
        return this.getAllSegments(this.listEventsByUserSegment.bind(this));
    }

    private listEventsByUserSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<EventResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventResponseDTO>>(`/events/mine`, { params });
    }
    
    /**
     * List all public events.
     */
     public listPublicEvents(): Observable<EventPublicResponseDTO[]> {
        return this.getAllSegments(this.listPublicEventsSegment.bind(this));
    }

    private listPublicEventsSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<EventPublicResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventPublicResponseDTO>>(`/events/public`, { params });
    }

    /**
     * Lists all events associated to the specified organization.
     * @param organization_id The event ID.
     */
    public listEventsByOrganization(organization_id: string): Observable<EventOrganizationResponseDTO[]> {
        return this.getAllSegments(this.listEventsByOrganizationSegment.bind(this), organization_id);
    }

    private listEventsByOrganizationSegment(segment_index: number|undefined, query_token: string|undefined, organization_id: string): Observable<ResultSegmentDTO<EventOrganizationResponseDTO>> {
        let params = new HttpParams();
        if (organization_id) params = params.append('organization_id', organization_id);
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventOrganizationResponseDTO>>(`/events/by-organization`, { params });
    }
}
