import { Injectable, Injector } from '@angular/core';
import { HttpContext, HttpResponse } from '@angular/common/http';
import { SignInCommandDTO } from '../models/account/commands/SignInCommandDTO';
import { Observable } from 'rxjs';
import { SignInResponseDTO } from '../models/account/responses/SignInResponseDTO';
import { UpdatePasswordCommandDTO } from '../models/account/commands/UpdatePasswordCommandDTO';
import { ResetPasswordCommandDTO } from '../models/account/commands/ResetPasswordCommandDTO';
import { UpdateEmailCommandDTO } from '../models/account/commands/UpdateEmailCommandDTO';
import { ProfileResponseDTO } from '../models/account/responses/ProfileResponseDTO';
import { UpdateProfileCommandDTO } from '../models/account/commands/UpdateProfileCommandDTO';
import { FacebookSignInCommandDTO } from '../models/account/commands/FacebookSignInCommandDTO';
import { GoogleSignInCommandDTO } from '../models/account/commands/GoogleSignInCommandDTO';
import { SignUpCommandDTO } from '../models/account/commands/SignUpCommandDTO';
import { FacebookSignUpCommandDTO } from '../models/account/commands/FacebookSignUpCommandDTO';
import { GoogleSignUpCommandDTO } from '../models/account/commands/GoogleSignUpCommandDTO';
import { BaseAPIService } from './base.service';
import { DeleteProfileCommandDTO } from '../models/account/commands/DeleteProfileCommandDTO';
import { ByPassAuthorizationContextToken } from '../auth/bearer.interceptor';
import { SigninModesCommandDTO } from '../models/account/commands/SigninModesCommandDTO';
import { SigninModesResponseDTO } from '../models/account/responses/SigninModesResponseDTO';
import { GenerateEmailVerificationCodeCommandDTO } from '../models/account/commands/GenerateEmailVerificationCodeCommandDTO';
import { TotpSettingsResponseDTO } from '../models/account/responses/TotpSettingsResponseDTO';
import { Enable2faCommandDTO } from '../models/account/commands/Enable2faCommandDTO';
import { Disable2faCommandDTO } from '../models/account/commands/Disable2faCommandDTO copy';
import { CreatePasswordCommandDTO } from '../models/account/commands/CreatePasswordCommandDTO';
import { EmailVerificationCodeType } from '../models/common/EmailVerificationCodeType';
import { FacebookSignUpRequestCommandDTO } from '../models/account/commands/FacebookSignUpRequestCommandDTO';
import { GoogleSignUpRequestCommandDTO } from '../models/account/commands/GoogleSignUpRequestCommandDTO';
import { SocialProfileResponseDTO } from '../models/account/responses/SocialProfileResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class AccountAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    public updatePassword(body: UpdatePasswordCommandDTO): Observable<void> {
        return this.httpPost<any>(`/account/password/update`, body);
    }

    public createPassword(body: CreatePasswordCommandDTO): Observable<void> {
        return this.httpPost<any>(`/account/password/create`, body);
    }

    public resetPassword(body: ResetPasswordCommandDTO): Observable<void> {
        return this.httpPost<any>(`/account/password/reset`, body,
        {
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }

    public deleteProfile(body: DeleteProfileCommandDTO): Observable<void> {
        return this.httpPost<void>(`/account/profile/delete`, body);
    }

    public updateProfileEmail(body: UpdateEmailCommandDTO): Observable<ProfileResponseDTO> {
        return this.httpPost<ProfileResponseDTO>(`/account/profile/email`, body);
    }

    public profileGet(): Observable<ProfileResponseDTO> {
        return this.httpGet<ProfileResponseDTO>(`/account/profile`);
    }

    public updateProfile(body: UpdateProfileCommandDTO): Observable<ProfileResponseDTO> {
        return this.httpPut<ProfileResponseDTO>(`/account/profile`, body);
    }

    public getSigninModes(body: SigninModesCommandDTO): Observable<SigninModesResponseDTO> {
      return this.httpPost<SigninModesResponseDTO>('/account/signin/modes', body,
      {
          withCredentials: true,
          context: new HttpContext().set(ByPassAuthorizationContextToken, true)
      });
    }

    public facebookSignin(body: FacebookSignInCommandDTO): Observable<SignInResponseDTO> {
        return this.httpPost<SignInResponseDTO>(`/account/facebook/signin`, body,
        {
            withCredentials: true,
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }

    public googleSignin(body: GoogleSignInCommandDTO): Observable<SignInResponseDTO> {
        return this.httpPost<SignInResponseDTO>(`/account/google/signin`, body,
        {
            withCredentials: true,
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }

    public signin(body: SignInCommandDTO): Observable<SignInResponseDTO> {
        return this.httpPost<SignInResponseDTO>(`/account/signin`, body,
        {
            withCredentials: true,
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }

    public signout(): Observable<void> {
        return this.httpPost<any>(`/account/signout`, null,
        {
            withCredentials: true,
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }

    public renewAccessToken(): Observable<SignInResponseDTO> {
        return this.httpPost<SignInResponseDTO>(`/account/signin/renew`, null,
        {
            withCredentials: true,
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }

    public facebookSignup(body: FacebookSignUpCommandDTO): Observable<HttpResponse<SignInResponseDTO>> {
        return this.httpPost<SignInResponseDTO>(`/account/facebook/signup`, body,
        {
            withCredentials: true,
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        }, "response");
    }

    public googleSignup(body: GoogleSignUpCommandDTO): Observable<HttpResponse<SignInResponseDTO>> {
        return this.httpPost<SignInResponseDTO>(`/account/google/signup`, body,
        {
            withCredentials: true,
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        }, "response");
    }

    public signup(body: SignUpCommandDTO): Observable<SignInResponseDTO> {
        return this.httpPost<SignInResponseDTO>(`/account/signup`, body,
        {
            withCredentials: true,
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }
    
    public facebookSignupRequest(body: FacebookSignUpRequestCommandDTO): Observable<SocialProfileResponseDTO> {
        return this.httpPost<SocialProfileResponseDTO>(`/account/facebook/signup/request`, body,
        {
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }

    public googleSignupRequest(body: GoogleSignUpRequestCommandDTO): Observable<SocialProfileResponseDTO> {
        return this.httpPost<SocialProfileResponseDTO>(`/account/google/signup/request`, body,
        {
            context: new HttpContext().set(ByPassAuthorizationContextToken, true)
        });
    }

    public requestVerificationCode(body: GenerateEmailVerificationCodeCommandDTO): Observable<void> {
        // Depending on the type of verification code, bypass authorization 
        const byPassAuthorization = [EmailVerificationCodeType.RESET_PASSWORD, EmailVerificationCodeType.SIGNUP].includes(body.code_type);
        return this.httpPost<any>(`/account/email-verification`, body,
            { context: new HttpContext().set(ByPassAuthorizationContextToken, byPassAuthorization ) });
    }

    public generateTotpSettings(): Observable<TotpSettingsResponseDTO> {
        return this.httpPost<TotpSettingsResponseDTO>(`/account/2fa/totp-settings`, undefined);
    }

    public enable2FA(body: Enable2faCommandDTO): Observable<void> {
        return this.httpPost<void>(`/account/2fa`, body);
    }

    public disable2FA(body: Disable2faCommandDTO): Observable<void> {
        return this.httpPost<void>(`/account/2fa/disable`, body);
    }
}
