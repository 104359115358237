import { HttpInterceptor, HttpRequest, HttpHandler, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class ServiceWorkerBypassInterceptor implements HttpInterceptor {

    constructor() { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {

        let headers = request.headers;
        headers = headers.set('ngsw-bypass', 'true'); // value is ignored when using ngsw-bypass, can be empty, use of "true" for lisibility purpose
      
        const requestClone = request.clone({ headers });
        return next.handle(requestClone);
    }
}