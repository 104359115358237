import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventMembershipUpdateCommandDTO } from '../models/event-membership/commands/EventMembershipUpdateCommandDTO';
import { EventMembershipInvitationCreateCommandDTO } from '../models/event-membership/commands/EventMembershipInvitationCreateCommandDTO';
import { EventMembershipInvitationConfirmCommandDTO } from '../models/event-membership/commands/EventMembershipInvitationConfirmCommandDTO';
import { EventMembershipResponseDTO } from '../models/event-membership/responses/EventMembershipResponseDTO';
import { EventMembershipCreateCommandDTO } from '../models/event-membership/commands/EventMembershipCreateCommandDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';

@Injectable({
    providedIn: 'root'
})
export class EventMembershipAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Add a new event membership
     * @param body The command used to initialize the new  event membership.
     */
    public create(event_id: string, body: EventMembershipCreateCommandDTO): Observable<EventMembershipResponseDTO> {
        return this.httpPost<EventMembershipResponseDTO>(`/events/${event_id}/memberships`, body);
    }

    /**
     * Update an existing event membership.
     * @param event_id id of the event
     * @param id id of the membership
     * @param body The command used to update existing membership.
     */
    public update(event_id: string, id: string, body: EventMembershipUpdateCommandDTO): Observable<EventMembershipResponseDTO> {
        return this.httpPut<EventMembershipResponseDTO>(`/events/${event_id}/memberships/${id}`, body);
    }

    /**
     * Add a new event membership invitation request
     * @param body The command used to initialize the new event membership invitation.
     */
    public createInvitation(event_id: string, body: EventMembershipInvitationCreateCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/memberships/invitation`, body);
    }

     /**
     * Confirm event membership invitation request
     * @param body The command used to confirm event membership invitation request
     */
    public confirmInvitation(event_id: string, body: EventMembershipInvitationConfirmCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/memberships/invitation/confirmation`, body);
    }
    
    /**
     * Deletes the specified event membership.
     * @param id id of the event membership
     * @param event_id id of the event
     */
    public delete(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/memberships/${id}`);
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<EventMembershipResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventMembershipResponseDTO>>(`/events/${event_id}/memberships`, { params });
    }

    /**
     * Lists all event memberships.
     * @param event_id id of the event
     */
    public list(event_id: string): Observable<EventMembershipResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), event_id);
    }
}