import { Inject, LOCALE_ID, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'localDateTime' })
export class LocalDateTimePipe implements PipeTransform {
    formats: Map<string, any> = new Map();

    constructor(@Inject(LOCALE_ID) private locale: string) { 
        this.formats.set("time",  { hour: '2-digit', minute: '2-digit' });
        this.formats.set("date", { day: '2-digit', month: 'long', year: 'numeric' });
        this.formats.set("dateTimeShort", { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' });
        this.formats.set("dateTime", { day: 'numeric', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' });
    }

    transform(value: Date, mode: "time" | "date" | "dateTime" | "dateTimeShort" = "time") {
        if (value) {
            const format = this.formats.get(mode) || this.formats.get("default");
            return new Intl.DateTimeFormat(this.locale, format).format(value);
        } else {
            return null;
        }
    }
}