import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { OrganizationAdminResponseDTO } from '../models/organization/responses/OrganizationAdminResponseDTO';
import { OrganizationCommandDTO } from '../models/organization/commands/OrganizationCommandDTO';
import { BaseAPIService } from './base.service';
import { OrganizationResponseDTO } from '../models/organization/responses/OrganizationResponseDTO';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OrganizationAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the organization's infos.
     * 
     * @param id id of the organization
     */
    public get(id: string): Observable<OrganizationResponseDTO> {
        return this.httpGet<OrganizationResponseDTO>(`/organizations/${id}`);
    }

    /**
     * Gets the organization's infos by affiliation code.
     * 
     * @param code affiliation code of the organization
     */
         public getByAffiliationCode(code: string): Observable<OrganizationResponseDTO> {
            let params = new HttpParams();
            if (code !== undefined) params = params.append('code', code);
            return this.httpGet<OrganizationResponseDTO>(`/organizations/by-affiliation`, { params });
        }
    
     /**
     * Gets the organization's admin-related infos.
     * 
     * @param id id of the organization
     */
      public getAdmin(id: string): Observable<OrganizationAdminResponseDTO> {
        return this.httpGet<OrganizationAdminResponseDTO>(`/organizations/${id}/settings/admin`);
    }

    /**
     * Creates a new organization and set the current user as owner.
     * @param body The command used to initialize the new teammate.
     */
    public create(body: OrganizationCommandDTO): Observable<OrganizationAdminResponseDTO> {
        return this.httpPost<OrganizationAdminResponseDTO>(`/organizations`, body);
    }

    /**
     * Deletes the specified organization.
     * @param id id of the organization
     */
    public delete(id: string): Observable<void> {
        return this.httpDelete(`/organizations/${id}`);
    }
    
    /**
     * Updates the specified organization.
     * @param id The organization ID
     * @param body Informations needed to update the organization.
     */
     public update(id: string, body: OrganizationCommandDTO): Observable<OrganizationAdminResponseDTO> {
        return this.httpPut<OrganizationAdminResponseDTO>(`/organizations/${id}/settings/main`, body);
    }

    /**
     * Lists all organizations the current user is a teammate of.
     */
    public listByTeammate(): Observable<OrganizationResponseDTO[]> {
        return this.getAllSegments(this.listByTeammateSegment.bind(this));
    }
    
    private listByTeammateSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<OrganizationResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<OrganizationResponseDTO>>(`/organizations/by-teammate`, { params });
    }

    /**
     * Lists all organizations the current user is a membership of.
     */
         public listByMembership(): Observable<OrganizationResponseDTO[]> {
            return this.getAllSegments(this.listByMembershipSegment.bind(this));
        }
        
        private listByMembershipSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<OrganizationResponseDTO>> {
            let params = new HttpParams();
            if (segment_index !== undefined) params = params.append('segment_index', segment_index);
            if (query_token) params = params.append('query_token', query_token);
            return this.httpGet<ResultSegmentDTO<OrganizationResponseDTO>>(`/organizations/by-membership`, { params });
        }
}