import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiClientConfiguration } from '../configuration';
import { UploadTokenResponseDTO } from '../models/asset/responses/UploadTokenResponseDTO';
import { BaseAPIService } from './base.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AssetAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Generates a temporary set of credentials that can be used to upload files to Amazon S3.
     */
    public createUploadToken(): Observable<UploadTokenResponseDTO> {
        return this.httpPost<UploadTokenResponseDTO>(`/assets/upload/token`, null);
    }
}
