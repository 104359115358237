import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class EncryptDecryptService {

    constructor() { }

    generateUrlSignature(message: string, secretKey: string) {
        const subject = CryptoJS.enc.Utf8.parse(message);
        let hash = CryptoJS.HmacSHA256(subject, CryptoJS.enc.Base64.parse(secretKey));
        return CryptoJS.enc.Base64url.stringify(hash);
    }
    
    b64DecodeUnicode(str: string) {
        return decodeURIComponent(Array.prototype.map.call(atob(str), (c: any) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }
    
    b64EncodeUnicode(str: string) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
            return String.fromCharCode(parseInt(p1, 16))
        }))
    }
}
