import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { WeezeventAccessTokenCommandDTO } from '../models/weezevent/commands/WeezeventAccessTokenCommandDTO';
import { WeezeventAccessTokenResponseDTO } from '../models/weezevent/responses/WeezeventAccessTokenResponseDTO';
import { WeezeventEventResponseDTO } from '../models/weezevent/responses/WeezeventEventResponseDTO';
import { WeezeventFormResponseDTO } from '../models/weezevent/responses/WeezeventFormResponseDTO';
import { WeezeventParticipantResponseDTO } from '../models/weezevent/responses/WeezeventParticipantResponseDTO';
import { WeezeventTicketResponseDTO } from '../models/weezevent/responses/WeezeventTicketResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class WeezeventAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Generates a Weezevent access token.
     * 
     */
    public generateAccessToken(body: WeezeventAccessTokenCommandDTO): Observable<WeezeventAccessTokenResponseDTO> {
        return this.httpPost<WeezeventAccessTokenResponseDTO>(`/weezevent/auth/token`, body);
    }

    /**
     * Lists available events from the Weezevent API.
     * 
     */
    public listEvents(apiKey: string, accessToken: string): Observable<WeezeventEventResponseDTO[]> {
        return this.getAllSegments(this.listEventsSegment.bind(this), apiKey, accessToken);
    }

    private listEventsSegment(segment_index: number|undefined, query_token: string|undefined, apiKey: string, accessToken: string): Observable<ResultSegmentDTO<WeezeventEventResponseDTO>> {
        let params = new HttpParams();
        params = params.append('api_key', apiKey);
        params = params.append('access_token', accessToken);
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<WeezeventEventResponseDTO>>(`/weezevent/events`, { params });
    }

    /**
     * Lists available tickets from the Weezevent API.
     * 
     */
     public listTickets(apiKey: string, accessToken: string, eventId: number): Observable<WeezeventTicketResponseDTO[]> {
        return this.getAllSegments(this.listTicketsSegment.bind(this), apiKey, accessToken, eventId);
    }

    private listTicketsSegment(segment_index: number|undefined, query_token: string|undefined, apiKey: string, accessToken: string, eventId: number): Observable<ResultSegmentDTO<WeezeventTicketResponseDTO>> {
        let params = new HttpParams();
        params = params.append('api_key', apiKey);
        params = params.append('access_token', accessToken);
        params = params.append('event_id', eventId);
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<WeezeventEventResponseDTO>>(`/weezevent/tickets`, { params });
    }

    /**
     * Lists available forms the Weezevent API.
     * 
     */
     public listForms(apiKey: string, accessToken: string, eventId: number, ticketId: number): Observable<WeezeventFormResponseDTO[]> {
        return this.getAllSegments(this.listFormsSegment.bind(this), apiKey, accessToken, eventId, ticketId);
    }

    private listFormsSegment(segment_index: number|undefined, query_token: string|undefined, apiKey: string, accessToken: string, eventId: number, ticketId: number): Observable<ResultSegmentDTO<WeezeventFormResponseDTO>> {
        let params = new HttpParams();
        params = params.append('api_key', apiKey);
        params = params.append('access_token', accessToken);
        params = params.append('event_id', eventId);
        params = params.append('ticket_id', ticketId);
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<WeezeventFormResponseDTO>>(`/weezevent/forms`, { params });
    }    

    /**
     * Lists available events from the Weezevent API.
     * 
     */
     public listParticipants(apiKey: string, accessToken: string, event_id: string, ticket_id: string): Observable<WeezeventParticipantResponseDTO[]> {
        return this.getAllSegments(this.listParticipantsSegment.bind(this), apiKey, accessToken, event_id, ticket_id);
    }

    private listParticipantsSegment(segment_index: number|undefined, query_token: string|undefined, apiKey: string, accessToken: string, event_id: string, ticket_id: string): Observable<ResultSegmentDTO<WeezeventParticipantResponseDTO>> {
        let params = new HttpParams();
        params = params.append('api_key', apiKey);
        params = params.append('access_token', accessToken);
        params = params.append('event_id', event_id);
        params = params.append('ticket_id', ticket_id);
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<WeezeventParticipantResponseDTO>>(`/weezevent/participants`, { params });
    }
}
