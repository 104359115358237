import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpResponse, HttpEvent, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiClientConfiguration } from '../configuration';
import { CreateStaffEventMessageCommandDTO } from '../models/event-message/commands/CreateStaffEventMessageCommandDTO';
import { ReadStaffEventMessagesCommandDTO } from '../models/event-message/commands/ReadStaffEventMessagesCommandDTO';
import { StaffEventMessageResponseDTO } from '../models/event-message/responses/StaffEventMessageResponseDTO';
import { CreateTeammateEventMessageCommandDTO } from '../models/event-message/commands/CreateTeammateEventMessageCommandDTO';
import { TeammateEventMessageResponseDTO } from '../models/event-message/responses/TeammateEventMessageResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { AuthService } from '../auth/auth.service';
import { UnreadStaffEventMessagesCommandDTO } from '../models/event-message/commands/UnreadStaffEventMessagesCommandDTO';
import { StaffMessagingStatusResponseDTO } from '../models/common/StaffMessagingStatusResponseDTO';

@Injectable({
    providedIn: 'root'
})
export class EventMessageAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Sends an event message to one or more teammates, as an event staff member.
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public sendMessageToTeammates(event_id: string, body: CreateStaffEventMessageCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/messages/staff`, body);
    }

    /**
     * Marks all event messages sent by the specified teammate as read.
     * @param body The command used to initialize the new teammate.
     */
    public markTeammateMessagesAsRead(event_id: string, body: ReadStaffEventMessagesCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/messages/staff/read`, body);
    }

    /**
     * Deletes the specified event message.
     * @param id id of the message
     * @param event_id id of the event
     */
    public deleteStaffMessage(id: string, event_id: string): Observable<void> {
        return this.httpDelete(`/events/${event_id}/messages/staff/${id}`);
    }

    private listMessagesByTeammateSegment(segment_index: number | undefined, query_token: string | undefined, event_id: string, teammate_id: string): Observable<ResultSegmentDTO<StaffEventMessageResponseDTO>> {
        let params = new HttpParams();
        params = params.append("teammate_id", teammate_id);
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<StaffEventMessageResponseDTO>>(`/events/${event_id}/messages/staff/by-teammate`, { params });
    }

    /**
     * Lists all event messages for the specified teammate.
     * 
     * @param event_id id of the event
     * @param teammate_id id of the teammate
     */
    public listMessagesByTeammate(event_id: string, teammate_id: string): Observable<StaffEventMessageResponseDTO[]> {
        return this.getAllSegments(this.listMessagesByTeammateSegment.bind(this), event_id, teammate_id);
    }

    /**
     * Sends an event message to the event staff members, as a teammate.
     * @param event_id id of the event
     * @param body The command used to initialize the new teammate.
     */
    public sendMessageToStaff(event_id: string, body: CreateTeammateEventMessageCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/messages/teammate`, body);
    }

    /**
     * Marks all event messages sent to the current teammate user by the event staff members as read.
     * @param body The command used to initialize the new teammate.
     */
    public markEventMessagesAsRead(event_id: string): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/messages/teammate/read`, null);
    }

    private listMyMessagesSegment(segment_index: number | undefined, query_token: string | undefined, event_id: string): Observable<ResultSegmentDTO<TeammateEventMessageResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<TeammateEventMessageResponseDTO>>(`/events/${event_id}/messages/teammate/mine`, { params });
    }

    /**
     * Lists all event messages for the current teammate.
     * 
     * @param event_id id of the event
     */
    public listMyMessages(event_id: string): Observable<TeammateEventMessageResponseDTO[]> {
        return this.getAllSegments(this.listMyMessagesSegment.bind(this), event_id);
    }

    /**
     * Marks the specified event message sent by the specified teammate as unread, as well as all messages sent by him after that.
     * @param event_id id of the event
     * @param body The command used to update the event messages..
     */
    public markEventMessagesAsUnread(event_id: string, body: UnreadStaffEventMessagesCommandDTO): Observable<void> {
        return this.httpPost<void>(`/events/${event_id}/messages/staff/unread`, body);
    }

    /**
    * Gets the event messaging status.
    * 
    * @param event_id id of the event
    */
    public getMesagingStatus(event_id: string): Observable<StaffMessagingStatusResponseDTO> {
        return this.httpGet<StaffMessagingStatusResponseDTO>(`/events/${event_id}/messages/staff/status`);
    }

}
