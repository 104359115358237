import { Directive, Input, HostListener } from '@angular/core';
import { saveAs } from 'file-saver';

@Directive({
    selector: '[saveAs]'
})
export class SaveAsDirective {

    @Input('saveAs') url!: string;

    @HostListener("click")
    onClick() {
        saveAs(this.url, this.url.substring(this.url.lastIndexOf("/")+1));
    }
}