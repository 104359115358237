import { Directive, OnDestroy } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[responsive]',
    exportAs: 'responsive'
})
export class ResponsiveDirective implements OnDestroy {

    public XSmall: boolean = false;
    public Small: boolean = false;
    public Medium: boolean = false;
    public Large: boolean = false;
    public XLarge: boolean = false;

    constructor(breakpointObserver: BreakpointObserver) {
        breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small,Breakpoints.Medium,Breakpoints.Large,Breakpoints.XLarge])
            .pipe(takeUntil(this.$destroy))
            .subscribe(state => {
                this.XSmall = state.breakpoints[Breakpoints.XSmall];
                this.Small = state.breakpoints[Breakpoints.Small];
                this.Medium = state.breakpoints[Breakpoints.Medium];
                this.Large = state.breakpoints[Breakpoints.Large];
                this.XLarge = state.breakpoints[Breakpoints.XLarge];
            });
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}