import { Pipe, PipeTransform } from "@angular/core";
import { AssetFileService } from "../services/asset-file-service/asset-file.service";

@Pipe({
    name: 'toAssetUrl'
})
export class ToAssetUrlPipe implements PipeTransform {
    constructor(private assetFileService: AssetFileService) { }
    transform(value: string|undefined|null) {
        return this.assetFileService.getAssetUrlFromKey(value);
    }
}