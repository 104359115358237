import { Component, Input, OnDestroy, Output, EventEmitter, TemplateRef, ViewChild, OnInit, OnChanges, SimpleChanges, ElementRef, AfterViewInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Attachment } from '../../models/common/attachment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { IconTypes, MessageDialogComponent, MessageTypes } from '../message-dialog/message-dialog.component';
import { Message } from '../../models/message/message';
import { SmsStatus } from 'projects/api-client/src/models/common/SmsStatus';
import { AuthService, UserContext } from 'projects/api-client/src/public-api';
import { OrganizationAdmin } from '../../models/organization/organization-admin';
import { SortService } from '../../services/sort-service/sort.service';
import { MessageInputComponent, MessageInputContent, Recipient, SmsSettings } from '../message-input/message-input.component';
import { NavigationService } from '../../services/navigation-service/navigation.service';
import { MessagingContact } from '../../models/common/messaging-contact';
import { ContactType } from 'projects/api-client/src/models/common/ContactType';
import { CONSTANTS } from 'src/environments/constants';
import { MessageType } from 'projects/api-client/src/models/common/MessageType';
import { StaffEventMessageType } from 'projects/api-client/src/models/event-message/commands/Staff/StaffEventMessageType';
import { StaffOrganizationMessageType } from 'projects/api-client/src/models/organization-message/commands/Staff/StaffOrganizationMessageType';
import * as moment from 'moment';
import * as _ from 'lodash';
import { EventFormDistributionMine } from '../../models/event-form-distribution/event-form-distribution-mine';
import { EventForm } from '../../models/event-form/event-form';
import { AssetFileService } from '../../services/asset-file-service/asset-file.service';

@Component({
    selector: 'messaging',
    templateUrl: './messaging.component.html',
    styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent implements OnChanges, OnDestroy, AfterViewInit {

    @ViewChild("messageInput") messageInputRef!: MessageInputComponent;
    @ViewChild('messageListBottomAnchor') messageListBottomAnchor!: ElementRef;

    menuOpen!: boolean;
    lastResolutionMessageId: string | undefined;
    isPendingMessage!: boolean;
    isMobileMenuVisible: boolean = true;
    today = new Date();
    sharedDocuments: {
        attachment: Attachment,
        sourceMessage: Message
    }[] = [];
    sharedImages: {
        attachment: Attachment,
        sourceMessage: Message
    }[] = [];
    messagesVM: IMessageViewModel[] = [];
    //
    // Utils
    //
    ContactType = ContactType;
    MessageType = MessageType;
    CONSTANTS = CONSTANTS;
    ReadBy = ReadBy;
    SmsStatus = SmsStatus;
    NavigationService = NavigationService;
    userContext!: UserContext;
    Views = Views;
    //
    // Input(s)
    //
    @Input() contextTemplate!: TemplateRef<any>;
    @Input() messages: Message[] = [];
    @Input() recipient!: Recipient;
    @Input() label!: string;
    @Input() readBy: ReadBy = ReadBy.TEAMMATE;
    @Input() canWriteMessages: boolean = true;
    @Input() canSendPrivateNotes: boolean = false;
    @Input() currentMessageContent!: MessageInputContent;
    @Input() organization?: OrganizationAdmin;
    @Input() contact: MessagingContact | undefined;
    @Input() enableSms: boolean = false;
    @Input() smsSettings?: SmsSettings;
    @Input() currentContactId!: string;
    @Input() scrollToMessageId: string | undefined;
    @Input() canSendForms: boolean = false;
    @Input() forms: EventForm[] = [];
    @Input() distributions: EventFormDistributionMine[] = [];
    @Input() currentView: Views = Views.ALL;
    @Input() lastNewMessageId: string | undefined;
    //
    // Output(s)
    //
    @Output() messageSubmitted = new EventEmitter<MessageSubmittedEvent>();
    @Output() messageDeleted = new EventEmitter<Message>();
    @Output() messageMarkedAsUnread = new EventEmitter<Message>();
    @Output() conversationStatusChanged = new EventEmitter<boolean>();
    @Output() scrolledToMessage = new EventEmitter<void>();
    @Output() currentMessageChange = new EventEmitter<string>();

    constructor(
        private dialog: MatDialog,
        private authService: AuthService,
        breakpointObserver: BreakpointObserver,
        public assetFileService: AssetFileService,
        private navigationService: NavigationService) {

        this.authService.currentUserContextChanged
            .pipe(takeUntil(this.$destroy))
            .subscribe(userContext => {
                this.userContext = userContext!;
            });
        breakpointObserver.observe([Breakpoints.Large, Breakpoints.XLarge])
            .pipe(takeUntil(this.$destroy))
            .subscribe(state => {
                if (state.matches) this.currentView = Views.ALL;
            });
        this.messageSubmitted
            .subscribe(() => {
                this.isPendingMessage = false;
            });
    }

    scrollToBottom() {
        // Clear message input
        this.messageInputRef?.clear();
        // Scroll to the end of the conversation
        this.messageListBottomAnchor?.nativeElement.scrollIntoView({ behavior: 'instant', block: 'end' });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const setMessagesVM = (messages: Message[], distributions: EventFormDistributionMine[]): IMessageViewModel[] => {
            let messagesVM = [];
            this.sharedDocuments = [];
            this.sharedImages = [];

            const messagesSorted = messages.sort(SortService.getDateComparator('send_date'));
            for (let sourceMessage of messagesSorted) {
                for (let attachment of sourceMessage.attachments) {
                    const entry = { attachment, sourceMessage };
                    if (attachment.isImage) this.sharedImages.push(entry);
                    else this.sharedDocuments.push(entry);
                }
                messagesVM.push({
                    value: sourceMessage,
                    attachedDistributions: sourceMessage.data?.form_ids ? sourceMessage.data.form_ids.map((formId: string) => {
                        return distributions.find((d: EventFormDistributionMine) => d.form_id === formId)
                    }) : []
                }) 
            }
            return messagesVM;
        }

        if (changes['currentMessageContent']) {
            const newValue = changes['currentMessageContent'].currentValue;
            const previousValue = changes['currentMessageContent'].previousValue;
            if (newValue && (!previousValue || newValue.message !== previousValue.message)) {
                this.currentMessageChange.emit(newValue.message);
            }
        }
        if(changes['messages']) {
            this.messagesVM = setMessagesVM(changes['messages'].currentValue, this.distributions);
            this.lastResolutionMessageId = this.getLastResolutionMessageId();
        }
        if(changes['distributions']) {
            this.messagesVM = setMessagesVM(this.messages, changes['distributions'].currentValue);
        }
    }

    ngAfterViewInit() {
        if (this.scrollToMessageId) {
            // Scroll to a specific message
            let messageEl = document.getElementById(this.scrollToMessageId);
            if (messageEl) {
                messageEl.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
                this.scrolledToMessage.emit();
            }
        }
        else {
            this.scrollToBottom();
        }
    }

    contentChanged(content: MessageInputContent) {
        this.currentMessageContent = { message: content.message, attachments: content.attachments, sendSms: content.sendSms, forms: content.forms };
    }

    // Message was successfully sent, reset messageInput component
    notifyMessageSent() {
        this.messageInputRef.clear();
    }

    // Post message
    async sendMessage(messageToSend: MessageSubmittedEvent) {
        this.messageSubmitted.emit({
            content: messageToSend.content,
            type: messageToSend.type,
            attachment_asset_keys: messageToSend.attachment_asset_keys,
            send_sms: messageToSend.send_sms ?? false,
            attachment_form_ids: messageToSend.attachment_form_ids
        });
    }

    delete(message: Message) {
        this.messageDeleted.emit(message);
    }

    displayErrorMessage(body: string) {
        this.dialog.open(MessageDialogComponent, {
            data: {
                icon: IconTypes.ERROR,
                body,
                type: MessageTypes.INFO
            },
            panelClass: 'message-dialog'
        });
    }

    markAsUnread(message: Message) {
        this.messageMarkedAsUnread.emit(message);
    }

    markAsResolved() {
        this.conversationStatusChanged.emit(true);
    }

    markAsUnresolved() {
        this.conversationStatusChanged.emit(false);
    }

    getMessageContentClass(message: Message) {
        if ((this.readBy == ReadBy.TEAMMATE && message.is_sent_by_teammate) || (this.readBy == ReadBy.STAFF && !message.is_sent_by_teammate)) {
            if (message.type === MessageType.CONVERSATION_RESOLUTION) {
                return "";
            }
            else if (message.type === MessageType.PRIVATE_NOTE) {
                return "bg-qo-yellow/30 py-3 px-4 rounded-bl-3xl";
            }
            return "bg-qo-messages text-white py-3 px-4 rounded-bl-3xl";
        }
        else {
            return "bg-gray-200 dark:bg-neutral-700 py-3 px-4 rounded-br-3xl"
        }
    }

    getAvatarTooltip(message: Message) {
        if (this.readBy === ReadBy.STAFF) return message.sender_full_name;
        if (this.readBy === ReadBy.TEAMMATE && message.is_sent_by_teammate) return $localize`Me`;
        return message.sender_label;
    }

    getLastResolutionMessageId(): string | undefined {
        const sentResolutionMessages = this.messages.filter(
            message => !message.is_sent_by_teammate && message.type === MessageType.CONVERSATION_RESOLUTION);

        if (sentResolutionMessages.length > 0) {
            return sentResolutionMessages[sentResolutionMessages.length - 1].id;
        }
        return undefined;
    }

    messagesInputFocusChanged(isMessagingFocused: boolean) {
        this.isMobileMenuVisible = !isMessagingFocused;
        this.navigationService.showMobileMenu = !isMessagingFocused;
    }

    formIsClosed(formClosingDate: Date): boolean {
        return moment(formClosingDate).isBefore(moment());
    }

    private $destroy = new Subject();
    ngOnDestroy() {
        // When browser back button is used to unselect current contact in mobile view
        // We need to make mobile menu visible again when destroying component
        // making mobile menu visible is usually made when using navigation Service
        this.navigationService.showMobileMenu = true;
        // Unsubscribe from all subscriptions
        this.$destroy.next();
        this.$destroy.complete();
    }
}

export enum Views {
    ALL,
    MESSAGES,
    SHARED_FILES
}

export interface MessageSubmittedEvent {
    content: string,
    type?: StaffEventMessageType | StaffOrganizationMessageType,
    attachment_asset_keys: string[],
    send_sms?: boolean,
    attachment_form_ids?: string[]
}

export enum ReadBy {
    TEAMMATE,
    STAFF
}

interface IMessageViewModel {
    value: Message,
    attachedDistributions?: EventFormDistributionMine[]
}