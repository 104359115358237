import * as moment from "moment";
import { ApplicationState } from "projects/api-client/src/models/common/ApplicationState";
import { EventPermission } from "projects/api-client/src/models/common/EventPermission";
import { EventRole } from "projects/api-client/src/models/common/EventRole";
import { LocalizedKeyString } from "projects/api-client/src/models/common/LocalizedKeyString";
import { OrganizationPermission } from "projects/api-client/src/models/common/OrganizationPermission";
import { AssetResponseDTO } from "projects/api-client/src/models/event/responses/Event/AssetResponseDTO";
import { EventResponseDTO } from "projects/api-client/src/models/event/responses/EventResponseDTO";
import { LocalDate } from "projects/api-client/src/types/LocalDate";
import { Address } from "../common/address";

export class Event {

    id!: string;
    name!: LocalizedKeyString;
    start_date: LocalDate = new LocalDate();
    end_date: LocalDate = new LocalDate();
    time_zone!: string;
    address?: Address;
    poster_asset_key?: string;
    logo_asset_key?: string;
    carousel_images: AssetResponseDTO[] = [];
    applications_open!: boolean;
    recruitment_started!: boolean;
    is_archived!: boolean;
    has_teammate_services!: boolean;
    user_event_roles: EventRole[] = [];
    user_event_permissions: EventPermission[] = [];
    require_2fa_for_members!: boolean;
    user_organization_permissions: OrganizationPermission[] = [];
    user_application_state!: ApplicationState;
    user_is_pre_registered!: boolean;
    user_activity_access_groups: string[] = [];
    user_teammate_access_groups: string[] = [];
    organization_id!: string;

    public get duration(): moment.Duration {
        return moment.duration(moment(this.end_date.toJSON()).diff(moment(this.start_date.toJSON()))).add(1, "day");
    }

    public get isActiveForCurrentUser(): boolean {
        
        // Archived events are not active
        if (this.is_archived) return false;
        
        // Event with a role (admin access) are always active (if not archived)
        if (this.user_event_roles.length > 0) return true;

        // Event is considered active for all teammates:
        // * until the end date of the event for all application status PENDING and WAITING_LIST
        // * until the end date of the event + 1 month, for the ACCEPTED teammates
        if (this.user_application_state !== null) {
            if([ApplicationState.PENDING, ApplicationState.WAITING_LIST].includes(this.user_application_state)
                && moment().isSameOrBefore(this.end_date.toJSON())) {
                return true;
            }
            if(this.user_application_state === ApplicationState.ACCEPTED 
                && moment().isSameOrBefore(moment(this.end_date.toJSON()).add(1, "month"))) {
                return true;
            }
        }

        // Event is active to preregistered users, until the end date of the event
        if (this.user_is_pre_registered && moment().isSameOrBefore(this.end_date.toJSON())) {
            return true;
        }

        return false;    
    }

    static from(dto: EventResponseDTO) {
        const event = new Event();

        event.id = dto.id;
        event.name = dto.name;
        event.poster_asset_key = dto.poster_asset_key;
        event.applications_open = dto.applications_open;
        event.logo_asset_key = dto.logo_asset_key;
        event.start_date = LocalDate.from(dto.start_date);
        event.end_date = LocalDate.from(dto.end_date);
        event.is_archived = dto.is_archived;
        event.has_teammate_services = dto.has_teammate_services;
        event.time_zone = dto.time_zone;
        event.address = new Address(dto.address);
        event.recruitment_started = dto.recruitment_started;
        event.user_event_roles = dto.user_event_roles;
        event.user_event_permissions = dto.user_event_permissions;
        event.require_2fa_for_members = dto.require_2fa_for_members;
        event.user_organization_permissions = dto.user_organization_permissions;
        event.user_application_state = dto.user_application_state;
        event.user_is_pre_registered = dto.user_is_pre_registered;
        event.user_activity_access_groups = dto.user_activity_access_groups;
        event.user_teammate_access_groups = dto.user_teammate_access_groups;
        event.carousel_images = dto.carousel_images;
        event.organization_id = dto.organization_id;
        return event;
    }
}