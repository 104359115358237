import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

    backgroundColors = ["color1", "color2", "color3", "color4", "color5", "color6", "color7", "color8", "color9", "color10"];
    backgroundColor: string = this.backgroundColors[0];

    user: AvatarUser = {
        id: '',
        initials: ''
    };

    @Input("user") set setUser(data: AvatarUser) {
        this.user = data;
        if(data.id) {
            this.backgroundColor = this.backgroundColors[data.id.charCodeAt(1) % this.backgroundColors.length];
        } else {
            this.backgroundColor = this.backgroundColors[Math.floor(Math.random()*this.backgroundColors.length)];
        }
    }

    constructor() { }

    ngOnInit(): void {
    }

}

interface AvatarUser {
    id: string;
    initials?: string;
    photo_url?: string;
}