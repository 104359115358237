import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationError, NavigationCancel } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IdleMonitoringService } from './shared/services/idle-monitoring-service/idle-monitoring.service';
import { IsLoadingService, LoadingKeys } from './shared/services/is-loading/is-loading.service';
import { MatomoService } from './shared/services/matomo-service/matomo-service';
import { UiCustomizationService } from './shared/services/ui-customization/ui-customization.service';
import { LanguageService } from './shared/services/language-service/language.service';
import { Subject } from 'rxjs';
import { Event } from 'src/app/shared/models/event/event';
import { ClientSettings, ClientSettingsService } from './shared/services/client-settings-service/client-settings.service';
import { AuthService } from 'projects/api-client/src/public-api';
import { ApplicationPermission } from 'projects/api-client/src/models/common/ApplicationPermission';
import { PwaUpdateService } from './shared/services/pwa-update-service/pwa-update.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    displayInformationMessage: boolean;
    isInformationMessageEnabled?: boolean;

    clientSettings: ClientSettings;

    informationMessage?: string;
    informationLink?: string;

    uiCustomSettingsId: string = 'informationMessageUiCustomSettings';
    informationMessageUiCustomSettings?: InformationMessageUiCustomSettings;

    hideNotification: boolean = false;
    LoadingKeys = LoadingKeys;

    event: Event;

    constructor(
        private uiCustomizationService: UiCustomizationService,
        private clientSettingsService: ClientSettingsService,
        private authService: AuthService,
        public isLoadingService: IsLoadingService,
        private matomoService: MatomoService,
        private languageService: LanguageService,
        private idleMonitorService: IdleMonitoringService,
        private pwaUpdateService: PwaUpdateService,
        private router: Router
    ) {
        this.informationMessageUiCustomSettings = this.uiCustomizationService.get<InformationMessageUiCustomSettings>(this.uiCustomSettingsId);
        if (this.informationMessageUiCustomSettings) {
            this.isInformationMessageEnabled = false;
        }
    }

    ngOnInit() {
        this.authService.currentUserContextChanged
        .subscribe(userContext => {
            if (userContext) {
                if (this.isInformationMessageEnabled === true && this.clientSettings.admin?.information_message?.enable_admin_only === true) {
                    this.displayInformationMessage = userContext!.permissions.includes(ApplicationPermission.ADMIN_ACCESS);
                }
                else {
                    this.displayInformationMessage = this.isInformationMessageEnabled ?? false;
                }
            }
        });

        this.clientSettingsService.clientSettingsChange.subscribe((clientSettings: any) => {
            this.clientSettings = clientSettings;
            // Make sure the current language is supported
            this.languageService.SupportedLanguages = clientSettings.supported_languages;
            this.languageService.checkUserLanguage();

            if (clientSettings && clientSettings.admin?.information_message?.enable) {

                if (this.informationMessageUiCustomSettings && new Date(this.informationMessageUiCustomSettings.date) < new Date(clientSettings.admin.information_message.date)) {
                    this.uiCustomizationService.remove(this.uiCustomSettingsId);
                    this.informationMessageUiCustomSettings = undefined;
                    this.isInformationMessageEnabled = undefined;
                }

                if (this.isInformationMessageEnabled !== false) {
                    if (new Date() >= new Date(clientSettings.admin.information_message.date)) {
                        this.isInformationMessageEnabled = true;
                        this.informationMessage = this.clientSettingsService.getValueInLocale(JSON.parse(JSON.stringify(clientSettings.admin.information_message.message)));
                        this.informationLink = this.clientSettingsService.getValueInLocale(JSON.parse(JSON.stringify(clientSettings.admin.information_message.link)));
                    }
                }
            }
            else {
                this.isInformationMessageEnabled = undefined;
            }
        });
        this.clientSettingsService.startMonitoring();

        // Start dedicated service to catch idle users
        this.idleMonitorService.init();

        this.router.events
            .pipe(filter((event) => event instanceof NavigationStart || event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel))
            .subscribe(async event => {
                if (event instanceof NavigationStart) this.isLoadingService.add(LoadingKeys.DEFAULT);
                else this.isLoadingService.remove(LoadingKeys.DEFAULT);

                if (event instanceof NavigationEnd) this.matomoService.trackPageView();
            });
    }

    closeInformationMessage() {
        this.displayInformationMessage = false;
        this.isInformationMessageEnabled = false;

        this.informationMessageUiCustomSettings = { date: (new Date()).toJSON() };
        this.uiCustomizationService.set<InformationMessageUiCustomSettings>(this.uiCustomSettingsId, this.informationMessageUiCustomSettings);
    }

    private $destroy = new Subject<boolean>();
    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }
}

interface InformationMessageUiCustomSettings {
    date: string;
}
