import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { AdminServiceCreateCommandDTO } from '../models/admin-service/commands/AdminServiceCreateCommandDTO';
import { AdminServiceResponseDTO } from '../models/admin-service/responses/AdminServiceResponseDTO';
import { AdminServiceUpdateCommandDTO } from '../models/admin-service/commands/AdminServiceUpdateCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class AdminServiceAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    public create(body: AdminServiceCreateCommandDTO): Observable<AdminServiceResponseDTO> {
        return this.httpPost<AdminServiceResponseDTO>(`/admin/services`, body);
    }

    public list(): Observable<AdminServiceResponseDTO[]> {
        return this.getAllSegments(this.listSegments.bind(this));
    }

    private listSegments(segment_index: number | undefined, query_token: string | undefined): Observable<ResultSegmentDTO<AdminServiceResponseDTO>> {
        let params = new HttpParams();
        if (segment_index !== undefined) params = params.append('segment_index', segment_index);
        if (query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<AdminServiceResponseDTO>>(`/admin/services`, { params });
    }

    public get(code: string): Observable<AdminServiceResponseDTO> {
        return this.httpGet<AdminServiceResponseDTO>(`/admin/services/${code}`);
    }

    public update(code: string, body: AdminServiceUpdateCommandDTO): Observable<AdminServiceResponseDTO> {
        return this.httpPost<AdminServiceResponseDTO>(`/admin/services/${code}`, body);
    }

    public delete(code: string): Observable<void> {
        return this.httpDelete(`/admin/services/${code}`);
    }
}
