import { HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { EventLocalizationCommandDTO } from '../models/event-localization/commands/EventLocalizationCommandDTO';
import { EventLocalizationResponseDTO } from '../models/event-localization/responses/EventLocalizationResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventLocalizationAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    public list(event_id: string): Observable<EventLocalizationResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), event_id);
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined, event_id: string): Observable<ResultSegmentDTO<EventLocalizationResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<EventLocalizationResponseDTO>>(`/events/${event_id}/localizations`, { params });
    }

    public update(event_id: string, body: EventLocalizationCommandDTO): Observable<EventLocalizationResponseDTO> {
        return this.httpPost<EventLocalizationResponseDTO>(`/events/${event_id}/localizations`, body);
    }
}
