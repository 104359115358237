import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { EventSettingsMealsCommandDTO } from '../models/event-settings-meals/commands/EventSettingsMealsCommandDTO';
import { EventSettingsMealsResponseDTO } from '../models/event-settings-meals/responses/EventSettingsMealsResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventSettingsMealsAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    /**
     * Gets the event's meals-specific settings.
     * 
     * @param event_id The event ID.
     */
    public get(event_id: string): Observable<EventSettingsMealsResponseDTO> {
        return this.httpGet<EventSettingsMealsResponseDTO>(`/events/${event_id}/settings/meals`);
    }

    /**
     * Update the event's meals-specific settings.
     * 
     * @param event_id The event ID.
     * @param body Command used to update the settings.
     */
    public update(event_id: string, body: EventSettingsMealsCommandDTO): Observable<EventSettingsMealsResponseDTO> {
        return this.httpPost<EventSettingsMealsResponseDTO>(`/events/${event_id}/settings/meals`, body);
    }
}
