import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NotificationResponseDTO } from '../models/notification/responses/NotificationResponseDTO';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { ApiClientConfiguration } from '../configuration';
import { AuthService } from '../auth/auth.service';
import { ReadNotificationsCommandDTO } from '../models/notification/commands/ReadNotificationsCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class NotificationAPIService extends BaseAPIService {

constructor(injector: Injector) {
        super(injector);
    }

    private listSegment(segment_index: number|undefined, query_token: string|undefined): Observable<ResultSegmentDTO<NotificationResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<NotificationResponseDTO>>(`/notifications`, { ignoreConnectivityCheck: true, params });
    }

    /**
     * Lists all notifications for the current user.
     */
    public list(): Observable<NotificationResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this));
    }

    /**
     * Marks all unread notifications of the current user as read.
     * @param body The command used to initialize the new teammate.
     */
    public markAsRead(body: ReadNotificationsCommandDTO): Observable<void> {      
        return this.httpPost<void>(`/notifications/read`, body);
    }
}
