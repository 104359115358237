import * as moment from "moment";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'moment' })
export class MomentPipe implements PipeTransform {
    constructor() { }
    transform(value:any, format = "ll") {
        if (value) {
            return moment(value).format(format);
        } else {
            return null;
        }
    }
}