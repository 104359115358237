import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { EventApplicationSettingsCommandDTO } from '../models/event-settings-application/commands/EventApplicationSettingsCommandDTO';
import { EventApplicationSettingsResponseDTO } from '../models/event-settings-application/responses/EventApplicationSettingsResponseDTO';
import { EventPublicApplicationSettingsResponseDTO } from '../models/event-settings-application/responses/EventPublicApplicationSettingsResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventSettingsApplicationAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }


    /**
     * Update the event's application settings.
     * @param event_id The event ID.
     * @param body Command used to update the settings.
     */
    public update(event_id: string, body: EventApplicationSettingsCommandDTO): Observable<EventApplicationSettingsResponseDTO> {
        return this.httpPost<EventApplicationSettingsResponseDTO>(`/events/${event_id}/settings/application`, body);
    }

    /**
     * Gets the event's public application settings
     * 
     * @param event_id The event ID.
     */
    public getPublicSettings(event_id: string): Observable<EventPublicApplicationSettingsResponseDTO> {
        return this.httpGet<EventPublicApplicationSettingsResponseDTO>(`/events/${event_id}/settings/application/public`);
    }
}
