import { Component, OnInit } from '@angular/core';
import { NavigationService } from 'src/app/shared/services/navigation-service/navigation.service';

@Component({
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

    NavigationService = NavigationService;

    constructor() { }

    ngOnInit(): void {
    }
}
