import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPIService } from './base.service';
import { ResultSegmentDTO } from '../models/common/ResultSegmentDTO';
import { OrganizationTeammateResponseDTO } from '../models/organization-teammate/responses/OrganizationTeammateResponseDTO';
import { OrganizationTeammateCommandDTO } from '../models/organization-teammate/commands/OrganizationTeammateCommandDTO';

@Injectable({
    providedIn: 'root'
})
export class OrganizationTeammateAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }
    
    /**
     * Gets the specified organization teammate.
     * 
     * @param organizatinId The ID of the organization associated with the organization teammate.
     * @param id The organization teammate ID.
     */
     public get(organizationId: string, id: string): Observable<OrganizationTeammateResponseDTO> {
        return this.httpGet<OrganizationTeammateResponseDTO>(`/organizations/${organizationId}/teammates/${id}`);
    }

    /**
     * List all users having at least one teammate in an event of the organization.
     * 
     * @param organization_id id of the organization
     */
     public list(organizationId: string): Observable<OrganizationTeammateResponseDTO[]> {
        return this.getAllSegments(this.listSegment.bind(this), organizationId);
    }
    
    private listSegment(segment_index: number|undefined, query_token: string|undefined, organizationId: string,): Observable<ResultSegmentDTO<OrganizationTeammateResponseDTO>> {
        let params = new HttpParams();
        if(segment_index !== undefined) params = params.append('segment_index', segment_index);
        if(query_token) params = params.append('query_token', query_token);
        return this.httpGet<ResultSegmentDTO<OrganizationTeammateResponseDTO>>(`/organizations/${organizationId}/teammates`, { params });
    }

    /**
     * Deletes the specified organization teammate.
     * @param organizatinId The ID of the organization associated with the organization teammate.
     * @param id The organization teammate ID.
     */
         public delete(organizationId: string, id: string): Observable<void> {
            return this.httpDelete(`/organizations/${organizationId}/teammates/${id}`);
        }
        
    /**
     * Updates the specified organization teammate.
     * @param organizationId The ID of the organization associated with the organization teammate.
     * @param id The organization teammate ID.
     * @param body Informations needed to update the organization teammate.
     */
        public update(organizationId: string, id: string, body: OrganizationTeammateCommandDTO): Observable<OrganizationTeammateResponseDTO> {
        return this.httpPut<OrganizationTeammateResponseDTO>(`/organizations/${organizationId}/teammates/${id}`, body);
    }

    /**
     * Lets the current user join the organization community.
     * @param organizationId The organization's ID.
     */
    public joinCommunity(organizationId: string): Observable<void> {
        return this.httpPost(`/organizations/${organizationId}/teammates/join`, null);
    }

    /**
     * Lets the current user leave the organization community.
     * @param organizationId The organization's ID.
     */
    public leaveCommunity(organizationId: string): Observable<void> {
        return this.httpPost(`/organizations/${organizationId}/teammates/leave`, null);
    }
}