import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";

@Injectable()
export class PaginatorTranslationService extends MatPaginatorIntl {
  constructor() {
    super();
    
    // Initialize the translations once at construction time
    this.translateLabels();
  }

  override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    const of = $localize`of`;
    if (length === 0 || pageSize === 0) {
      return "0 " + of + " " + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize > length ? (Math.ceil(length / pageSize) - 1) * pageSize : page * pageSize;

    const endIndex = Math.min(startIndex + pageSize, length);
    return startIndex + 1 + " - " + endIndex + " " + of + " " + length;
  };

  translateLabels(): void {
    this.firstPageLabel = $localize`first page`
    this.itemsPerPageLabel = $localize`items per page`
    this.lastPageLabel = $localize`last page`
    this.nextPageLabel = $localize`next page`
    this.previousPageLabel = $localize`previous page`
    this.changes.next(); // Fire a change event to make sure that the labels are refreshed
  }
}