import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { EventLocalizationSettingsCommandDTO } from '../models/event-settings-localization/commands/EventLocalizationSettingsCommandDTO';
import { EventLocalizationSettingsResponseDTO } from '../models/event-settings-localization/responses/EventLocalizationSettingsResponseDTO';
import { BaseAPIService } from './base.service';

@Injectable({
    providedIn: 'root'
})
export class EventSettingsLocalizationAPIService extends BaseAPIService {

    constructor(injector: Injector) {
        super(injector);
    }

    public get(event_id: string): Observable<EventLocalizationSettingsResponseDTO> {
        return this.httpGet<EventLocalizationSettingsResponseDTO>(`/events/${event_id}/settings/localization`);
    }

    public update(event_id: string, body: EventLocalizationSettingsCommandDTO): Observable<EventLocalizationSettingsResponseDTO> {
        return this.httpPost<EventLocalizationSettingsResponseDTO>(`/events/${event_id}/settings/localization`, body);
    }
}
